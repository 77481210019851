import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Empty, Space, Table, Tag, Image } from "antd";

import { getAllCategory } from "../Requests";
import CategoryViewModal from "../Modal/ShowCategoryDetails";
import ManageCategoryModal from "../Modal/ManageCategory";
import { getAccessAuthrorization } from "../../../components/access";
import { s3Path } from "../../../utils/s3Config/s3upload";
import CreateSubMenuModal from "../../SubMenu/Modal/CreateSubMenu";
import SubMenuViewModal from "../../SubMenu/Modal/ShowSubMenuDetails";
import { initial } from "lodash";
import CreateCategoryModal from "../Modal/CreateCategory";
// import CreateServiceMenu from "../../ServiceMenu/Form/CreateServiceMenuForm";
// import CreateServiceMenuModal from "../../ServiceMenu/Modal/CreateServiceMenu";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const CategoryList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const [itemList, setItemList] = React.useState<Object | any>(undefined);

  let accessAuth = getAccessAuthrorization("category");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["category"], () => getAllCategory(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const ArrangeParentChildService = (arr: any) => {
    var parentChildArray;

    arr?.reduce((acc: any, val: any, ind: any, array: any) => {
      const childs: any = [];
      array.forEach((el: any, i: any) => {
        if (childs.includes(el.parentId) || el?.parentId?._id === val.id) {
          childs.push(el);
        }
      });
      let temp = acc.concat({ ...val, childs });
      parentChildArray = temp.filter((item: any) => !item.parentId);
      return acc.concat({ ...val, childs });
    }, []);
    return parentChildArray;
  };

  let list: Object | any = ArrangeParentChildService(response);
  // let FinalList = ;
  // console.log(FinalList);
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any) => <Tag color="gold">{text}</Tag>,
    },
    // {
    //   title: "Parent",
    //   dataIndex: "parentId",
    //   key: "parentId",
    //   render: (text: any) => <p>{text?.title}</p>,
    // },
    // {
    //   title: "SubMenu",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       {/* <CategoryViewModal id={id} /> */}
    //       {/* <CreateSubMenuModal id={id} /> */}
    //       <SubMenuViewModal id={id} />
    //       {/* {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />} */}
    //     </Space>
    //   ),
    // },
    {
      title: "Custom Url",
      dataIndex: "customUrl",
      key: "customUrl",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: "Add Service",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space size={10}>
          <CreateCategoryModal type={i.type} parentId={i.id} />
          {/* <ManageCategoryModal id={id} /> */}
          {/* {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />} */}
        </Space>
      ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CategoryViewModal id={id} /> */}
          {/* <ManageCategoryModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />}
        </Space>
      ),
    },
  ];

  const childColumn: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text: any) => <Tag color="gold">{text}</Tag>,
    },
    {
      title: "Parent",
      dataIndex: "parentId",
      key: "parentId",
      render: (text: any) => <p>{text?.title}</p>,
    },
    {
      title: "SubMenu",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CategoryViewModal id={id} /> */}
          {/* <CreateSubMenuModal id={id} /> */}
          <SubMenuViewModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />} */}
        </Space>
      ),
    },
    {
      title: "Custom Url",
      dataIndex: "customUrl",
      key: "customUrl",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CategoryViewModal id={id} /> */}
          {/* <ManageCategoryModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />}
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={list}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Table
              size="small"
              columns={childColumn}
              dataSource={record.childs}
            />
          </>
        ),
        // rowExpandable: (record: any) => record?.parentId !== "Not Expandable",
      }}
    />
  );
};

export default CategoryList;
