import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Checkbox,
  Col,
  Upload,
  Typography,
  Divider,
  Space,
  Image,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  AuditOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import { useQuery } from "react-query";

import LocalStorageService from "../../../utils/local-storage";

import { products } from "../../../constants/products";
import { ManagePriceListModalContext } from "../Modal/ManagePriceList";
import { getPartyById } from "../Requests";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { getAllActiveStandard } from "../../Standard/Requests";
import CreateStandardModal from "../../Standard/Modal/CreateStandard";
// import { getAllLocality } from "src/modules/Locality/Requests";
const { Text } = Typography;
const ManagePriceListForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManagePriceListModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const ManagePartyMutation = useMutation(
    (values: any) => request("admin.party.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("party");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: party,
  } = useQuery(["party", props.id], async () => getPartyById(props.id), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  const {
    //  isLoading,
    //  isError,
    data: standard,
  } = useQuery(["standard"], async () => getAllActiveStandard(), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    setFormLoading(true);
    // const response = await uploadFileToAWS(selectedFile, "Profile");

    ManagePartyMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The party has been successfully Managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={party}
    >
      <h3>
        <Image width={50} height={50} src={s3Path + party.imageUrl} />

        {party.companyName}
      </h3>
      <Form.List
        name="priceList"
        // rules={[
        //   {
        //     validator: async (_, names) => {
        //       if (!names || names.length < 2) {
        //         return Promise.reject(new Error("At least 2 passengers"));
        //       }
        //     },
        //   },
        // ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Space.Compact direction="horizontal">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area ||
                    prevValues.sights !== curValues.sights
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label={index === 0 && "SNo"}
                      initialValue={index + 1}
                      style={{
                        marginBottom: 0,
                        width: "5%",
                        fontWeight: "bold",
                      }}
                      name={[field.name, "sno"]}
                    >
                      <Input placeholder="Sno. " disabled />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area ||
                    prevValues.sights !== curValues.sights
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label={index === 0 && "Title"}
                      name={[field.name, "title"]}
                      style={{
                        // marginBottom: 0,
                        width: "25%",
                        fontWeight: "bold",
                      }}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input title or delete this field.",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Title" />
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area ||
                    prevValues.sights !== curValues.sights
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label={index === 0 && "Standard"}
                      name={[field.name, "standardId"]}
                      style={{
                        // marginBottom: 0,
                        width: "40%",
                        fontWeight: "bold",
                      }}
                    >
                      <Select
                        //onChange={onPartySelect}
                        showSearch
                        placeholder="Select Standard"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option!.children as unknown as string)
                            .toString()
                            .toLowerCase()
                            .includes(input)
                        }
                        dropdownRender={(menu) => (
                          <>
                            <Row justify={"end"}>
                              <CreateStandardModal />
                            </Row>
                            {menu}
                          </>
                        )}
                      >
                        {standard &&
                          standard?.map((item: any, i: number) => {
                            return (
                              <Select.Option value={item.id} key={i}>
                                {item?.ISNumber}-{item?.ISName}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area ||
                    prevValues.sights !== curValues.sights
                  }
                >
                  {() => (
                    <Form.Item
                      {...field}
                      label={index === 0 && "Price"}
                      name={[field.name, "price"]}
                      style={{
                        // marginBottom: 0,
                        width: "25%",
                        fontWeight: "bold",
                      }}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please input price or delete this field.",
                        },
                      ]}
                    >
                      <Input addonBefore="₹" placeholder="Enter Price" />
                    </Form.Item>
                  )}
                </Form.Item>
                {/* {fields.length > 1 ? ( */}
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => remove(field.name)}
                />
                {/* ) : null} */}
              </Space.Compact>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
              >
                Add Items
              </Button>
              {/* <Button
                type="dashed"
                onClick={() => {
                  add("The head item", 0);
                }}
                style={{ width: "60%", marginTop: "20px" }}
                icon={<PlusOutlined />}
              >
                Add field at head
              </Button> */}
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Price List
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManagePriceListForm;
