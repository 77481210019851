import * as React from "react";

import { Modal, Button, Tooltip } from "antd";

import { EyeOutlined } from "@ant-design/icons";
import ShowQuotationDetailsForm from "../Form/ShowQuotationForm";
import { usePDF } from "react-to-pdf";
export const ShowQuotationModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const QuotationViewModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowQuotationModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EyeOutlined />}
        />
      </Tooltip>
      <Modal
        width={900}
        title=" View Quotation Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ShowQuotationDetailsForm id={id} />
        {/* <div>
          <button onClick={() => toPDF()}>Download PDF</button>
          <div ref={targetRef}>
            <ShowQuotationDetailsForm id={id} />{" "}
          </div>
        </div> */}
      </Modal>
    </ShowQuotationModalContext.Provider>
  );
};

export default QuotationViewModal;
