import * as React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Switch,
  Select,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Card,
  Tag,
  Typography,
  Divider,
  Space,
  Avatar,
  Collapse,
  Radio,
  Segmented,
  RadioChangeEvent,
} from "antd";
import parse from "html-react-parser";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  ProfileOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { CreateWorkFollowupModalContext } from "../Modal/CreateWorkFollowup";
import LocalStorageService from "../../../utils/local-storage";

import TextArea from "antd/es/input/TextArea";
import { values } from "lodash";
import { getWorkByIdDetail } from "../Requests";
import FollowUpListByWork from "../Component/FollowupListByWorkId";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { imLogoIcon } from "../../../constants/logo";
import FollowUpListByLead from "../../Leads/Home/FollowupListByLeadId";
import CreateConcernedPersonModal from "../../Party/Modal/CreateConcernedPerson";

const CreateWorkFollowupForm: React.FC<{ workId: string }> = (props) => {
  const modalContext = React.useContext(CreateWorkFollowupModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [toVendor, SetToVendor] = React.useState(false);

  const createWorkFollowupMutation = useMutation(
    (values: any) => request("admin.followup.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("followup");
        queryClient.invalidateQueries("work");
      },
    }
  );

  const { data: work } = useQuery(
    ["work", props.workId],
    async () => getWorkByIdDetail(props.workId),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  const [partyId, setPartyId] = React.useState(work[0]?.partyId[0]?._id);
  const [concernedpersons, setConcernedpersons] = React.useState(
    work[0]?.partyId[0]?.concernedpersons
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;

    values.workId = props.workId;
    values.content = `${values.followupPurpose} to ${values.concernedPersonId}`;
    values.description = values.clientRemarks;
    createWorkFollowupMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The followup has been successfully created.");
        // modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const workStatus = [
    {
      value: "In Progress",
      label: "In Progress",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "In Testing",
      label: "In Testing",
    },

    {
      value: "Testing Hold",
      label: "Testing Hold",
    },

    {
      value: "Testing Done",
      label: "Testing Done",
    },
    {
      value: "Application Preparation",
      label: "Application Preparation",
    },
    {
      value: "Application Submitted",
      label: "Application Submitted",
    },
    {
      value: "Queried",
      label: "Queried",
    },

    {
      value: "Queried Resolved",
      label: "Queried Resolved",
    },
    {
      value: "Approval Granted",
      label: "Approval Granted",
    },
    {
      value: "Approval Cancelled",
      label: "Approval Cancelled",
    },

    {
      value: "Completed",
      label: "Completed",
    },

    {
      value: "Cancelled",
      label: "Cancelled",
    },
    {
      value: "Ticket Open",
      label: "Ticket Open",
    },
    {
      value: "Ticket Closed",
      label: "Ticket Closed",
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    //  console.log("radio checked", e.target.value);
    setPartyId(e.target.value);
    e.target.value === work[0]?.partyId[0]?._id
      ? setConcernedpersons(work[0]?.partyId[0]?.concernedpersons)
      : e.target.value === work[0]?.laboratoryId[0]?._id
      ? setConcernedpersons(work[0]?.laboratoryId[0]?.concernedpersons)
      : setConcernedpersons(work[0]?.authorityId[0]?.concernedpersons);
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Card>
        <Collapse
          items={[
            {
              label: (
                <Row justify={"space-between"}>
                  <Space.Compact direction="horizontal">
                    <Tag>
                      {/* {moment(work[0].enquiryDate).format("DD-MMM-YYYY")} */}
                      {"WWC/work/" + work[0]?.seq}
                    </Tag>

                    <Typography.Text strong>{work[0]?.title}</Typography.Text>
                    <Tag color="lime">{work[0]?.workStatus}</Tag>
                  </Space.Compact>
                  <Row align={"middle"}>
                    <Typography.Text strong type="success">
                      Client:-
                    </Typography.Text>
                    <Avatar
                      src={
                        <img
                          src={
                            work[0].partyId[0].imageUrl
                              ? s3Path + work[0].partyId[0].imageUrl
                              : imLogoIcon
                          }
                          alt={work[0].partyId[0]?.personName}
                        />
                      }
                    />
                    <Typography.Text strong>
                      {work[0].partyId[0]?.companyName}
                    </Typography.Text>
                  </Row>
                  {work[0]?.laboratoryId?.length > 0 && (
                    <Row align={"middle"}>
                      <Typography.Text strong type="danger">
                        Lab:-
                      </Typography.Text>
                      <Avatar
                        src={
                          <img
                            src={
                              work[0].laboratoryId[0].imageUrl
                                ? s3Path + work[0].laboratoryId[0].imageUrl
                                : imLogoIcon
                            }
                            alt={work[0].laboratoryId[0]?.personName}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {work[0].laboratoryId[0]?.companyName}
                      </Typography.Text>
                    </Row>
                  )}
                  {work[0]?.authorityId?.length > 0 && (
                    <Row align={"middle"}>
                      <Typography.Text strong type="secondary">
                        Authority:-
                      </Typography.Text>
                      <Avatar
                        src={
                          <img
                            src={
                              work[0].authorityId[0].imageUrl
                                ? s3Path + work[0].authorityId[0].imageUrl
                                : imLogoIcon
                            }
                            alt={work[0].authorityId[0]?.personName}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {work[0].authorityId[0]?.companyName}
                      </Typography.Text>
                    </Row>
                  )}
                </Row>
              ),
              children: <> </>,
            },
          ]}
          expandIconPosition="end"
        />
        {/* <PartyCard data={work[0].partyId[0]} /> */}
        <Row gutter={20}>
          <Col flex={5} span={12}>
            <Typography.Title level={5}>Followups List</Typography.Title>
            <div style={{ height: "60vh", overflow: "auto" }}>
              {work[0].leadId && (
                <>
                  <Divider>Lead Followups</Divider>
                  <FollowUpListByLead id={work[0].leadId} />
                </>
              )}
              <Divider>Work Followups</Divider>
              <FollowUpListByWork id={props.workId} />
            </div>
          </Col>
          <Col flex={5} span={12}>
            <Row justify={"space-between"} align={"middle"}>
              <Typography.Title level={5}>Add Followups </Typography.Title>

              <Radio.Group
                onChange={onChange}
                value={partyId}
                defaultValue={partyId}
                buttonStyle="solid"
                optionType="button"
                options={[
                  {
                    label: "Client",
                    value: work[0].partyId[0]._id,
                  },
                  {
                    label: "Laboratory",
                    value: work[0]?.laboratoryId[0]?._id,
                    disabled: work[0].laboratoryId.length > 0 ? false : true,
                  },
                  {
                    label: "Authority",
                    value: work[0]?.authorityId[0]?._id,
                    disabled: work[0]?.authorityId?.length > 0 ? false : true,
                  },
                ]}
              />
            </Row>

            <Row justify={"space-between"} gutter={10}>
              {" "}
              <Col span={6}>
                <Form.Item
                  label=" Followup Date"
                  name="followupDate"
                  style={{ fontWeight: "bold" }}
                  tooltip={{
                    title: "followupDate",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date",
                    },
                  ]}
                >
                  <DatePicker showToday format={"DD-MM-YYYY"} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Followup Purpose"
                  style={{ fontWeight: "bold" }}
                  name="followupPurpose"
                  rules={[
                    {
                      required: true,
                      message: "Please Select FollowUp Purpose",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Select
                    placeholder="Select Purpose"
                    options={[
                      { value: "Introduction", label: "Introduction" },
                      { value: "Requirement", label: "Requirement" },
                      // { value: "Proposal", label: "Proposal" },
                      { value: "Discussion", label: "Discussion" },
                      // { value: "Negotiation", label: "Negotiation" },
                      { value: "Meeting", label: "Meeting" },
                      { value: "Finalisation", label: "Finalisation" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Type"
                  style={{ fontWeight: "bold" }}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select type",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Select
                    placeholder="Select Type"
                    options={[
                      { value: "Voice Call", label: "Voice Call" },
                      { value: "Video Call", label: "Video Call" },
                      { value: "Messaging", label: "Messaging" },
                      { value: "In Person", label: "In Person" },
                      { value: "Email", label: "Email" },
                      { value: "Other", label: "Other" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Concerned Person"
              name="concernedPersonId"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "concernedPerson",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Select Concern Person",
                },
              ]}
            >
              <Select
                size="large"
                // showSearch
                placeholder="Select Concerned Person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
                dropdownRender={(menu: any) => {
                  console.log(work[0]);
                  return (
                    <>
                      <Row justify={"end"}>
                        <CreateConcernedPersonModal partyId={partyId} />
                      </Row>
                      {menu}
                    </>
                  );
                }}
              >
                {concernedpersons?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item._id} key={i}>
                      <Row justify={"space-between"}>
                        <Col>
                          {item.name}
                          <Tag>{item.designation}</Tag>
                        </Col>
                        <Space.Compact direction="vertical">
                          <Typography.Text type="success">
                            <PhoneOutlined /> +91-{item.mobile}
                          </Typography.Text>
                          <Typography.Text type="warning">
                            {" "}
                            <MailOutlined /> {item.email}
                          </Typography.Text>
                        </Space.Compact>
                      </Row>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* </Row> */}
            <Form.Item
              label="Client Remarks"
              name="clientRemarks"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "clientRemarks",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Remarks",
                },
              ]}
            >
              <TextArea placeholder="Enter Client Remarks" />
            </Form.Item>
            <Row justify={"space-between"} gutter={10}>
              {" "}
              <Col span={12}>
                <Form.Item
                  label="Next Followup Date"
                  name="nextFollowupDate"
                  style={{ fontWeight: "bold" }}
                  tooltip={{
                    title: "nextFollowupDate",
                    icon: <InfoCircleOutlined />,
                  }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Select Date",
                  //   },
                  // ]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Work Status"
                  style={{ fontWeight: "bold" }}
                  name="workStatus"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Status",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Select placeholder="Select Status" options={workStatus} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="primary"
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <br />
      {/* <FollowUpListByLead id={props.leadId} /> */}
    </Form>
  );
};

export default CreateWorkFollowupForm;
