import {
  Avatar,
  Result,
  Tag,
  Image,
  Empty,
  Tabs,
  Row,
  Col,
  Popover,
} from "antd";

import * as React from "react";

import { useQuery } from "react-query";

import PartyManageModal from "../../Modal/ManageParty";

import { Button, Tooltip, Space, Input, Table, Typography } from "antd";
import PartyViewModal from "../../Modal/ShowPartyDetails";

import { getAllParty, getPartyByType } from "../../Requests";
import { s3Path } from "../../../../utils/s3Config/s3upload";
import CreateConcernedPersonModal from "../../Modal/CreateConcernedPerson";
import ManageBankDetailsModal from "../../Modal/ManageBankDetails";
import ManageUserDetailsModal from "../../Modal/ManageUserDetails";
import LocalStorageService from "../../../../utils/local-storage";
import { getAccessAuthrorization } from "../../../../components/access";
import { isMobile } from "react-device-detect";
import { CapsuleTabs, Card, List } from "antd-mobile";

import {
  ColumnType,
  FilterConfirmProps,
  ColumnsType,
} from "antd/lib/table/interface";
import WhatsappMessage from "../../../../components/whatsapp";
import PhoneCall from "../../../../components/call";
import CreateCredentialModal from "../../../Credential/Modal/CreateCredential";
import CreateDocumentModal from "../../../Document/Modal/CreateDocument";
import { SearchOutlined, PhoneOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import PriceListManageModal from "../../Modal/ManagePriceList";
const { Text } = Typography;
interface DataType {
  id: number;
  name: string;
  person: string;
  phoneNo: number;
  status: any;
  gstInNo: string;
  companyName: string;
}
type IUser = {
  id: string;
  avatar: string;
  name: string;
  description: string;
};
type DataIndex = keyof DataType;
const PartyList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<any>(null);
  const [access, setAccess] = React.useState(LocalStorageService.getAccess());
  let accessAuth = getAccessAuthrorization("party");
  const {
    isLoading,
    isError,
    data: party,
  } = useQuery(["party"], () => getAllParty(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const column: ColumnsType<DataType> = [
    {
      title: "Profile/Logo ",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",
      ...getColumnSearchProps("companyName"),

      width: 300,
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Typography.Text code>
              {i.seq ? "WWC/Party/" + i.seq : ""}
            </Typography.Text>

            <Text
              style={{
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              {i?.companyName}
            </Text>
            <Text
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontStyle: "italic",
              }}
            >
              {i?.personName}
              <Tag color="magenta">{i?.partyType[0]}</Tag>
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Contacts",
      dataIndex: "phoneNo",
      key: "phoneNo",
      ...getColumnSearchProps("phoneNo"),

      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Row>
              <Text type="success" code>
                <PhoneOutlined href={"tel:+91-" + i?.phoneNo} />
                {i?.countryCode ? i?.countryCode + "-" : "+91-"}
                {i?.phoneNo}
              </Text>
              <Button
                type="link"
                href={"tel:+91-" + i?.phoneNo}
                icon={
                  <PhoneOutlined
                    rotate={90}
                    style={{ fontSize: "22px", color: "blue" }}
                  />
                }
              />
              <WhatsappMessage
                phoneNumber={i?.phoneNo}
                message={`Hey  ${i?.personName}!`}
              />
            </Row>
            <Text type="warning" code>
              {i?.email}
            </Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "GST/Website",
      dataIndex: "gstInNo",
      key: "gstInNo",
      ...getColumnSearchProps("gstInNo"),

      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            {i?.gstInNo && <Text code>{i?.gstInNo}</Text>}
            {i?.website && (
              <Text type="danger" code>
                {i?.website}
              </Text>
            )}
          </Space.Compact>
        </Space>
      ),
    },

    // {
    //   title: "Concerned Person",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       <CreateConcernedPersonModal partyId={id} />
    //     </Space>
    //   ),
    // },
    {
      title: "Add Detail",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <CreateConcernedPersonModal partyId={i.id} />

            {i.partyType[0] === "Laboratory" ? (
              <PriceListManageModal id={i.id} />
            ) : (
              <CreateCredentialModal id={i.id} />
            )}
            <CreateDocumentModal partyId={i.id} />
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Other Details",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <Space>
          <Space.Compact direction="vertical">
            <ManageBankDetailsModal id={id} />
            <ManageUserDetailsModal id={id} />
          </Space.Compact>
        </Space>
      ),
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <PartyViewModal id={id} />
          {/* <PartyManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <PartyManageModal id={id} />}
        </Space>
      ),
    },
  ];

  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let laboratoryList = party.filter(
    (element: any) => element.partyType[0] === "Laboratory"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );
  let inhouseEmpList = party.filter(
    (element: any) => element.partyType[0] === "Inhouse Employee"
  );
  let outSourceEmpList = party.filter(
    (element: any) => element.partyType[0] === "Outsource Employee"
  );
  let partnerList = party.filter(
    (element: any) => element.partyType[0] === "Partner"
  );
  let authorityList = party.filter(
    (element: any) => element.partyType[0] === "Authority"
  );
  const capsuleTabItems = [
    {
      label: "Client",
      key: "1",
      children: <MobilePartyList data={clientList} />,
    },
    {
      label: "Laboratory",
      key: "2",
      children: <MobilePartyList data={laboratoryList} />,

      // disabled: true,
    },
    {
      label: "Lead Provider",
      key: "3",
      children: <MobilePartyList data={leadList} />,
    },

    {
      label: "Inhouse Employee",
      key: "4",
      children: <MobilePartyList data={inhouseEmpList} />,
    },
    {
      label: "Outsource Employee",
      key: "5",
      children: <MobilePartyList data={outSourceEmpList} />,
    },
    {
      label: "Partner",
      key: "6",
      children: <MobilePartyList data={partnerList} />,
    },
    {
      label: "Authority",
      key: "7",
      children: <MobilePartyList data={authorityList} />,
    },
    {
      label: "All Party",
      key: "8",
      children: <MobilePartyList data={party} />,
    },
  ];
  const items = [
    {
      label: "Client",
      key: "1",
      children: <Table size="small" columns={column} dataSource={clientList} />,
    },
    {
      label: "Laboratory",
      key: "2",
      children: (
        <Table size="small" columns={column} dataSource={laboratoryList} />
      ),

      // disabled: true,
    },
    {
      label: "Lead Provider",
      key: "3",
      children: <Table size="small" columns={column} dataSource={leadList} />,
    },

    {
      label: "Inhouse Employee",
      key: "4",
      children: (
        <Table size="small" columns={column} dataSource={inhouseEmpList} />
      ),
    },
    {
      label: "Outsource Employee",
      key: "5",
      children: (
        <Table size="small" columns={column} dataSource={outSourceEmpList} />
      ),
    },
    {
      label: "Partner",
      key: "6",
      children: (
        <Table size="small" columns={column} dataSource={partnerList} />
      ),
    },
    {
      label: "Authority",
      key: "7",
      children: (
        <Table size="small" columns={column} dataSource={authorityList} />
      ),
    },
    {
      label: "All Party",
      key: "8",
      children: <Table size="small" columns={column} dataSource={party} />,
    },
  ];

  return (
    <>
      {!isError ? (
        <>
          {!isMobile && <Tabs defaultActiveKey="1" items={items} />}
          {isMobile && (
            <CapsuleTabs defaultActiveKey="1">
              {capsuleTabItems.map((item) => (
                <CapsuleTabs.Tab
                  title={item.label}
                  key={item.key}
                  style={{ padding: 2 }}
                >
                  {item.children}
                </CapsuleTabs.Tab>
              ))}
              {/* {items} */}
              {/* <CapsuleTabs.Tab title={title}>{items}</CapsuleTabs.Tab> */}
            </CapsuleTabs>
          )}
        </>
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

export default PartyList;

const MobilePartyList: React.FC<{ data: any }> = ({ data }) => {
  console.log(data);
  return (
    <>
      {/* {data &&
        data.map((element: any, i: any) => {
          return (
            <>
              <Card>
                <Row>
                  <Avatar size="large" src={s3Path + element.imageUrl} />
                </Row>
              </Card>
            </>
          );

        })} */}
      <List style={{ padding: 0 }}>
        {/* <AutoSizer disableHeight> */}
        {data &&
          data.map((element: any, i: any) => (
            <List.Item
              style={{ padding: 0 }}
              key={element.personName}
              prefix={<Avatar size="large" src={s3Path + element.imageUrl} />}
              description={
                <>
                  <Space.Compact direction="vertical">
                    <Popover
                      content={
                        <Row>
                          {" "}
                          <PhoneCall phoneNumber={element.phoneNo} />
                          <WhatsappMessage
                            phoneNumber={element.phoneNo}
                            message={`Hey  ${element?.personName}!`}
                          />
                        </Row>
                      }
                      trigger="click"
                      placement="right"
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}
                        type="danger"
                        code
                      >
                        +91-{element?.phoneNo}
                      </Text>
                    </Popover>

                    <Text>{element?.email}</Text>

                    <Text type="secondary">{element?.companyName}</Text>
                  </Space.Compact>
                </>
              }
            >
              <Row justify={"space-between"}>
                <Col span={18}>
                  <Text strong>{element.personName}</Text>
                </Col>
                <Col span={6}>
                  <Space direction="horizontal">
                    <PartyViewModal id={element.id} />
                    <PartyManageModal id={element.id} />
                  </Space>
                </Col>
              </Row>
            </List.Item>
          ))}
        {/* </AutoSizer> */}
      </List>
    </>
  );
};
