import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManageUserForm from "../Form/ManageUserForm";
import { EditOutlined } from "@ant-design/icons";

export const ManageUserModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const UserManageModal: React.FC<{ id: string }> = ({ id }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManageUserModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type="primary"
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title="Manage User"
        visible={isModalVisible}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        <ManageUserForm id={id} />
      </Modal>
    </ManageUserModalContext.Provider>
  );
};

export default UserManageModal;
