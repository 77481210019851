import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Typography,
  Upload,
  Avatar,
  Col,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreateTaskModalContext } from "../Modal/CreateTask";
import { getAllActiveTask } from "../Requests";
import { getAllPersonnel } from "../../HR/Personnel/Requests";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "../../../utils/s3Config/s3upload";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import LocalStorageService from "../../../utils/local-storage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";
import { quillModules, tinyMCE } from "../../../utils/keys";
const CreateTaskForm: React.FC<{ workId?: string }> = (props) => {
  const modalContext = React.useContext(CreateTaskModalContext);
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const [formLoading, setFormLoading] = React.useState(false);

  const createTaskMutation = useMutation(
    (values: any) => request("admin.task.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
        queryClient.invalidateQueries("notification");
        queryClient.invalidateQueries("workDetails");
      },
    }
  );
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = user.id;
    // values.remarks = remarks;
    values.workId = props.workId && props.workId;
    // values.attachment = imageUrl;
    values.status = "To Do";

    //Notification Values
    values.notificationTitle = "Voila! New Task Assigned";
    values.notificationBody =
      "Task: " + values.title + "!  Click to view this task";
    values.receiverId = [values.assignedPersonId];
    values.senderId = values.assignedBy;

    createTaskMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The task has been successfully created.");
        modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  props.workId && form.setFieldsValue({ type: "Work" });
  form.setFieldsValue({ assignedBy: user.personnel.id });
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Due Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Enquiry Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="Title "
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Task Title",
              },
              { message: "Please enter Task Title" },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Remarks" name="remarks" style={{ fontWeight: "bold" }}>
        <ReactQuill theme="snow" modules={quillModules} />
      </Form.Item>
      <Row gutter={10}>
        <Col span={8}>
          {" "}
          <Form.Item
            label="Type "
            name="type"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Type",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Type Name",
              },
              { message: "Please enter Type Name" },
            ]}
            hasFeedback
          >
            <Select placeholder="Select Task Type">
              <Select.Option value="Lead">Lead</Select.Option>
              <Select.Option value="Work">Work</Select.Option>
              <Select.Option value="Utilities">Utilities</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          {" "}
          <Form.Item
            label="Value"
            name="value"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "value",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter Type Name",
              // },
              { message: "Please enter Type Name" },
            ]}
            hasFeedback
          >
            <Select placeholder="Allot Value">
              <Select.Option value="Penny">Penny</Select.Option>
              <Select.Option value="Silver">Silver</Select.Option>
              <Select.Option value="Gold">Gold</Select.Option>
              <Select.Option value="Platinum">Platinum</Select.Option>
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={8}>
          {" "}
          <Form.Item
            label="Assigned Person"
            style={{ fontWeight: "bold" }}
            name="assignedPersonId"
            tooltip={{
              title: "Person ",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              // onSelect={OnPersonSelect}
              showSearch
              size="large"
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {person &&
                person?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                      // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName[0]}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          {" "}
          <Form.Item
            label="Assigned By"
            style={{ fontWeight: "bold" }}
            name="assignedBy"
            tooltip={{
              title: "Person ",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              // onSelect={OnPersonSelect}
              showSearch
              size="large"
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              disabled={true}
            >
              {person &&
                person?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                      // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName[0]}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* <Row justify={"space-between"}>
        <Form.Item
          label="Attachment"
          style={{ fontWeight: "bold" }}
          name="attachment"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(file, "task").catch(
                (err: any) => message.error(err?.toString())
              );
              setImageUrl([...imageUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = imageUrl.filter((item) => item !== e.error.url);
                setImageUrl(file);
              }
            }}
          >
            {imageUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Attachment</Button>
            )}
          </Upload>
        </Form.Item> 
          </Row>*/}
      <Form.Item
        label="Status"
        name="isActive"
        style={{ fontWeight: "bold" }}
        valuePropName="checked"
        required
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateTaskForm;
