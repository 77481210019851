import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";
import CreatePaymentOutModal from "../Modal/CreatePaymentOut";
import PaymentOutList from "../Component/List";
import { getAccessAuthrorization } from "../../../components/access";

const PaymentOutPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("paymentOut");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>PaymentOut List</h2>
              {/* <CreatePaymentOutModal /> */}
              {accessAuth[0].includes("Create") && <CreatePaymentOutModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <PaymentOutList /> */}
          {accessAuth[0].includes("View") ? (
            <PaymentOutList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(PaymentOutPageHome);
