import { request } from "../../../utils/services";

export const getAllActiveUser = async () => {
  const { data } = await request("admin.user.list.active");
  return data;
};

export const getAllUser = async () => {
  const { data } = await request("admin.user.list");
  return data;
};

export const getUserById = async (id: string) => {
  const { data } = await request("admin.user.id", { id });
  return data;
};

export const getUserByPartyId = async (id: string) => {
  const { data } = await request("admin.user.partyId", { id });
  return data;
};
