import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Card,
  Tabs,
  Space,
  Typography,
  Tag,
  Badge,
  Avatar,
} from "antd";

import { useQuery } from "react-query";
import { getWorkByIdDetail } from "../Requests";
import parse from "html-react-parser";
import CreateFollowupForm from "../../Leads/Form/CreateFollowupForm";
import CreateDocument from "../../Document/Form/CreateDocumentForm";
import DocumentListByParty from "../../Document/Component/ListByPartyId";
import CreateCredential from "../../Credential/Form/CreateCredentialForm";

import { TaskListByWorkId } from "../Component/TaskListByWorkId";
import CreateTaskForm from "../../Task/Form/CreateTaskForm";
import CreateWorkFollowupForm from "./CreateWorkFollowupForm";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { imLogoIcon } from "../../../constants/logo";
import moment from "moment";

const ShowWorkDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: res } = useQuery(
    ["workDetails", props.id],
    async () => getWorkByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return (
    <>
      <Tabs
        centered
        // tabPosition="left"
        defaultActiveKey="2"
        // type="card"
        items={[
          {
            key: "1",
            label: <>Description/Details</>,
            children: (
              <Card style={{ width: "100%" }}>
                <WorkDescription data={res[0]} />
              </Card>
            ),
          },
          {
            key: "2",
            label: <>Documents</>,
            children: (
              <Card style={{ width: "100%" }}>
                <Row>
                  <Col flex={2}>
                    <CreateDocument
                      partyId={res[0].partyId[0]._id}
                      workId={props.id}
                      // leadId={leadId}
                    />
                    <DocumentListByParty id={res[0].partyId[0]._id} />
                  </Col>
                </Row>
              </Card>
            ),
          },
          {
            key: "3",
            label: <>Credentials</>,
            children: (
              <Card style={{ width: "100%" }}>
                <CreateCredential id={res[0].partyId[0]._id} />
              </Card>
            ),
          },
          {
            key: "4",
            label: <>FollowUp List</>,
            children: (
              <Card style={{ width: "100%" }}>
                <CreateWorkFollowupForm workId={props.id} />
              </Card>
            ),
          },
          {
            key: "5",
            label: <>Task List</>,
            children: (
              <Card style={{ width: "100%" }}>
                <Row>
                  <Col span={14}>
                    <TaskListByWorkId data={res[0].tasks} />
                  </Col>
                  <Col span={10}>
                    <CreateTaskForm workId={props.id} />
                  </Col>
                </Row>
              </Card>
            ),
          },
        ]}
      />
    </>
  );
};

export default ShowWorkDetailsForm;

const WorkDescription: React.FC<{ data: any }> = ({ data }) => {
  return (
    <>
      <Badge.Ribbon text={data.workType}>
        <Row justify={"space-between"} style={{ marginRight: 75 }}>
          <Space.Compact direction="horizontal">
            <Tag>
              {/* {moment(data.enquiryDate).format("DD-MMM-YYYY")} */}
              {"WWC/work/" + data.seq}
            </Tag>

            <Typography.Text strong>{data?.title}</Typography.Text>
            <Tag color="lime">{data?.workStatus}</Tag>
          </Space.Compact>
          <Row align={"middle"}>
            <Typography.Text strong type="success">
              Client:-
            </Typography.Text>
            <Avatar
              src={
                <img
                  src={
                    data?.partyId[0]?.imageUrl
                      ? s3Path + data?.partyId[0]?.imageUrl
                      : imLogoIcon
                  }
                  alt={data?.partyId[0]?.personName}
                />
              }
            />
            <Typography.Text strong>
              {data?.partyId[0]?.companyName}
            </Typography.Text>
          </Row>
          {data?.vendorId?.length > 0 && (
            <Row align={"middle"}>
              <Typography.Text strong type="danger">
                Vendor:-
              </Typography.Text>
              <Avatar
                src={
                  <img
                    src={
                      data?.vendorId[0]?.imageUrl
                        ? s3Path + data?.vendorId[0]?.imageUrl
                        : imLogoIcon
                    }
                    alt={data?.vendorId[0]?.personName}
                  />
                }
              />
              <Typography.Text strong>
                {data?.vendorId[0]?.companyName}
              </Typography.Text>
            </Row>
          )}
        </Row>{" "}
      </Badge.Ribbon>
      <Divider />
      <Row justify={"space-between"}>
        <Space.Compact direction="horizontal">
          Start Date:
          <Typography.Text code>
            {moment(data?.startDate).format("DD-MMM-YYYY")}
          </Typography.Text>
          to End Date:
          <Typography.Text code>
            {moment(data?.endDate).format("DD-MMM-YYYY")}
          </Typography.Text>
        </Space.Compact>
        <Avatar.Group>
          Team:
          {data?.teamId.map((item: any) => (
            <>
              {" "}
              <Avatar src={<img src={s3Path + item?.imageUrl} alt="T" />} />
            </>
          ))}
        </Avatar.Group>
      </Row>
      <Divider>Services</Divider>
      {data?.serviceId && <Tag>{data?.serviceId[0]?.title}</Tag>}
      <Divider>Descriptions</Divider>
      {data?.description && parse(data?.description)}
    </>
  );
};
