import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Radio,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { Editor as TinyMCEEditor } from "tinymce";
import LocalStorageSubMenu from "../../../utils/local-storage";
import { CreateSubMenuModalContext } from "../Modal/CreateSubMenu";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "../../../utils/keys";

// const { Text } = Typography;
const CreateSubMenu: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(CreateSubMenuModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();

  const [loading, setLoading] = React.useState(false);

  const [editorkey, seteditorkey] = React.useState(4);

  const createSubMenuMutation = useMutation(
    (values: any) => request("admin.submenu.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("submenu");
        // queryClient.invalidateQueries("submenubycategory");
      },
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageSubMenu.getAccount().id;
    values.description = remarks;
    values.categoryId = props.id;
    // values.fileUrl = galley;

    setFormLoading(true);

    createSubMenuMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The SubMenu has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const editorRef = React.useRef<TinyMCEEditor | null>(null);
  const cleareditor = () => {
    if (editorRef.current) {
      editorRef?.current?.setContent("");
    }
    //   const newkey = editorkey * 43;
    //   seteditorkey(newkey);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter your  title",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input
          placeholder="Please Enter Title"
          // onChange={(e) =>
          //   form.setFieldsValue({
          //     customUrl: e.target.value.toLowerCase().replace(/ /g, "-"),
          //   })
          // }
        />
      </Form.Item>
      {/* <Button onClick={cleareditor}>Clear</Button> */}
      <Editor
        key={editorkey}
        apiKey={tinyMCE.apiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />

      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateSubMenu;
