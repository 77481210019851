import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Descriptions,
  Result,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";

import { getAllWork } from "../Requests";
import WorkViewModal from "../Modal/ShowWorkDetails";
import ManageWorkModal from "../Modal/ManageWork";
import { getAccessAuthrorization } from "../../../components/access";
import CreateCredentialModal from "../../Credential/Modal/CreateCredential";
import CreateTaskModal from "../../Task/Modal/CreateTask";
import { s3Path } from "../../../utils/s3Config/s3upload";
import CreateWorkFollowupModal from "../Modal/CreateWorkFollowup";
import CreateDocumentModal from "../../Document/Modal/CreateDocument";
import PartyCard from "../../Party/Component/Home/PartyCard";
import { imLogoIcon } from "../../../constants/logo";
import LeadsViewModal from "../../Leads/Modal/ShowLeadsDetails";
import PurchaseOrderViewModal from "../../PurchaseOrder/Modal/ShowPurchaseOrderDetails";
import CreatePurchaseOrderModal from "../../PurchaseOrder/Modal/CreatePurchaseOrder";
import PerfomaInvoiceViewModal from "../../PerfomaInvoice/Modal/ShowPerfomaInvoiceDetails";
import CreatePerfomaInvoiceModal from "../../PerfomaInvoice/Modal/CreatePerfomaInvoice";
import CreateTaxInvoiceModal from "../../TaxInvoice/Modal/CreateTaxInvoice";
import QuotationViewModal from "../../Quotation/Modal/ShowQuotationDetails";
import DocumentViewModal from "../../Document/Modal/ShowDocumentDetails";
import CreatePurchaseInvoiceModal from "../../Purchase Invoice/Modal/CreatePurchaseInvoice";
import { Collapse } from "antd-mobile";
// import ProjectList from "src/modules/Project Mangement/Project/Component/List";

interface DataType {
  key: React.Key;
  id: number;
  name: any;
  title: any;
  description: any;
  attachment: any;
  teamId: any;
  technologyId: any;
  workStatus: any;
}
type DataIndex = keyof DataType;
const { Text } = Typography;

const WorkList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("work");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["work"], () => getAllWork(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  let New = response.filter((element: any) => element.workStatus === "New");
  let InProgress = response.filter(
    (element: any) => element.workStatus === "In Progress"
  );
  let OnHold = response.filter(
    (element: any) => element.workStatus === "On Hold"
  );
  let InTesting = response.filter(
    (element: any) => element.workStatus === "In Testing"
  );
  let TestingHold = response.filter(
    (element: any) => element.workStatus === "TestingHold"
  );
  let TestingDone = response.filter(
    (element: any) => element.workStatus === "Testing Done"
  );
  let ApplicationPreparation = response.filter(
    (element: any) => element.workStatus === "Application Preparation"
  );
  let ApplicationSubmitted = response.filter(
    (element: any) => element.workStatus === "Application Submitted"
  );
  let Queried = response.filter(
    (element: any) => element.workStatus === "Queried"
  );
  let QueriedResolved = response.filter(
    (element: any) => element.workStatus === "Queried Resolved"
  );
  let ApprovalGranted = response.filter(
    (element: any) => element.workStatus === "ApprovalGranted"
  );
  let ApprovalCancelled = response.filter(
    (element: any) => element.workStatus === "Approval Cancelled"
  );
  let Completed = response.filter(
    (element: any) => element.workStatus === "Completed"
  );
  let Cancelled = response.filter(
    (element: any) => element.workStatus === "Cancelled"
  );
  let TicketOpen = response.filter(
    (element: any) => element.workStatus === "Ticket Open"
  );
  let TicketClosed = response.filter(
    (element: any) => element.workStatus === "Ticket Closed"
  );

  const items = [
    {
      label: (
        <Badge count={New?.length} color="green">
          New
        </Badge>
      ),
      key: "0",
      children: <WorkTable data={New} />,
    },
    {
      label: (
        <Badge count={InProgress?.length} color="blue">
          In Progress
        </Badge>
      ),
      key: "1",
      children: <WorkTable data={InProgress} />,
    },
    {
      label: (
        <Badge count={OnHold?.length} color="orange">
          On Hold
        </Badge>
      ),
      key: "2",
      children: <WorkTable data={OnHold} />,
    },
    {
      label: (
        <Badge count={InTesting?.length} color="purple">
          In Testing
        </Badge>
      ),
      key: "3",
      children: <WorkTable data={InTesting} />,
    },
    {
      label: (
        <Badge count={TestingHold?.length} color="gold">
          Testing Hold
        </Badge>
      ),
      key: "4",
      children: <WorkTable data={TestingHold} />,
    },
    {
      label: (
        <Badge count={TestingDone?.length} color="lime">
          Testing Done
        </Badge>
      ),
      key: "5",
      children: <WorkTable data={TestingDone} />,
    },
    {
      label: (
        <Badge count={ApplicationPreparation?.length} color="blue">
          Application Preparation
        </Badge>
      ),
      key: "6",
      children: <WorkTable data={ApplicationPreparation} />,
    },
    {
      label: (
        <Badge count={ApplicationSubmitted?.length} color="green">
          Application Submitted
        </Badge>
      ),
      key: "7",
      children: <WorkTable data={ApplicationSubmitted} />,
    },
    {
      label: (
        <Badge count={Queried?.length} color="blue">
          Queried
        </Badge>
      ),
      key: "8",
      children: <WorkTable data={Queried} />,
    },
    {
      label: (
        <Badge count={QueriedResolved?.length} color="blue">
          Queried Resolved
        </Badge>
      ),
      key: "9",
      children: <WorkTable data={QueriedResolved} />,
    },
    {
      label: (
        <Badge count={ApprovalGranted?.length} color="green">
          Approval Granted
        </Badge>
      ),
      key: "10",
      children: <WorkTable data={ApprovalGranted} />,
    },
    {
      label: (
        <Badge count={ApprovalCancelled?.length} color="red">
          Approval Cancelled
        </Badge>
      ),
      key: "11",
      children: <WorkTable data={ApprovalCancelled} />,
    },
    {
      label: (
        <Badge count={Completed?.length} color="green">
          Completed
        </Badge>
      ),
      key: "12",
      children: <WorkTable data={Completed} />,
    },
    {
      label: (
        <Badge count={Cancelled?.length} color="red">
          Cancelled
        </Badge>
      ),
      key: "13",
      children: <WorkTable data={Cancelled} />,
    },
    {
      label: (
        <Badge count={TicketOpen?.length} color="blue">
          Ticket Open
        </Badge>
      ),
      key: "14",
      children: <WorkTable data={TicketOpen} />,
    },
    {
      label: (
        <Badge count={TicketClosed?.length} color="blue">
          Ticket Closed
        </Badge>
      ),
      key: "15",
      children: <WorkTable data={TicketClosed} />,
    },

    {
      label: "All Work",
      key: "16",
      children: <WorkTable data={response} />,
    },
  ];

  return !isError ? (
    <Tabs defaultActiveKey="1" items={items} />
  ) : (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default WorkList;

const WorkTable: React.FC<{ data: any }> = ({ data }) => {
  let accessAuth = getAccessAuthrorization("work");

  const column: ColumnsType<DataType> = [
    {
      title: " Work Title/Type",
      dataIndex: "title",
      key: "title",
      width: "20%",
      render: (_: any, i: any) => (
        <Space direction="vertical">
          <Text code>{"WWC/Work/" + i?.seq}</Text>
          <Text strong>{i?.title}</Text>
          <Text>Service:- {i?.serviceId?.title}</Text>
          <Space>
            <Text code>{moment(i?.startDate).format("DD-MMM-YYYY")}</Text>to
            <Text code>{moment(i?.endDate).format("DD-MMM-YYYY")}</Text>
          </Space>
          <Tag color="red">{i?.workStatus}</Tag>
          {i.isActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "Party Detail",
      dataIndex: "partyId",
      key: "partyId",
      width: "25%",
      render: (_: any, i: any) => <PartyCard data={i.partyId[0]} />,
    },

    {
      title: "Laboratoy/Authority/Team",
      dataIndex: "id",
      key: "id",
      render: (id: any, i: any) => (
        <Space direction="vertical">
          <Typography.Text strong style={{ fontSize: 12 }}>Product: {i?.productId[0]?.title}</Typography.Text>

          <Typography.Text strong style={{ fontSize: 12 }}>Standard : {i?.standardId[0]?.ISNumber}</Typography.Text>
          <Typography.Text strong style={{ fontSize: 12 }}>Service: {i?.serviceId[0]?.title}</Typography.Text>
          {i?.laboratoryId.length !== 0 && (
            <Row>
              <Avatar
                src={
                  <img
                    src={
                      i?.laboratoryId[0]?.imageUrl
                        ? s3Path + i.laboratoryId[0].imageUrl
                        : imLogoIcon
                    }
                    alt={i.laboratoryId[0]?.personName}
                  />
                }
              />
              <Typography.Text strong>
                {i.laboratoryId[0]?.companyName}
              </Typography.Text>
            </Row>
          )}
          {i?.authorityId.length !== 0 && (
            <Row>
              <Avatar
                src={
                  <img
                    src={
                      i.authorityId[0].imageUrl
                        ? s3Path + i.authorityId[0].imageUrl
                        : imLogoIcon
                    }
                    alt={i.authorityId[0]?.personName}
                  />
                }
              />
              <Typography.Text strong>
                {i.authorityId[0]?.companyName}
              </Typography.Text>
            </Row>
          )}
          <Avatar.Group>
            {i?.teamId?.map((item: any) => (
              <>
                <Avatar src={<img src={s3Path + item.imageUrl} alt="T" />} />
              </>
            ))}
          </Avatar.Group>
        </Space>
      ),
    },
    // {
    //   title: "Team",
    //   dataIndex: "teamId",
    //   key: "teamId",
    //   render: (data) => (
    //     <Avatar.Group>
    //       {data?.map((item: any) => (
    //         <>
    //           <Avatar src={<img src={s3Path + item.imageUrl} alt="T" />} />
    //         </>
    //       ))}
    //     </Avatar.Group>
    //   ),
    // },

    // {
    //   title: "Status",
    //   dataIndex: "isActive",
    //   key: "isActive",
    //   render: (_: any, i: any) => (
    //     <Space.Compact direction="vertical">
    //       <Tag color="red">{i?.workStatus}</Tag>
    //       {i.isActive ? (
    //         <Tag color="green">Active</Tag>
    //       ) : (
    //         <Tag color="red">Inactive</Tag>
    //       )}
    //     </Space.Compact>
    //   ),
    // },
    {
      title: "Add Detail",
      dataIndex: "_id",
      key: "_id",
      render: (id: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <CreateWorkFollowupModal workId={id} />

            <CreateCredentialModal id={i.partyId[0]._id} />
            <CreateDocumentModal partyId={i.partyId[0]._id} workId={id} />
            <CreateTaskModal workId={id} />

            {/* {i.deploymentId ? (
              <DeploymentViewModal id={i.id} />
            ) : (
              <CreateDeploymentModal id={i.id} />
            )} */}
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Purchase",
      dataIndex: "isActive",
      key: "isActive",
      render: (_: any, i: any) => (
        <Space.Compact direction="vertical">
          {i?.purchaseOrderId?.length !== 0 ? (
            <Space.Compact direction="horizontal">
              <Tag>
                <Row>
                  <Col>
                    PO: WWC/PO/{i?.purchaseOrderId[0]?.seq}
                    {i?.purchaseOrderId[0]?.fileUrl && <DocumentViewModal path={i?.purchaseOrderId[0]?.fileUrl} />
                    }
                  </Col>
                  <Col> TaxAmt:-   ₹ {i?.purchaseOrderId[0]?.totalTaxable}/-</Col>
                </Row>
              </Tag>
              <PurchaseOrderViewModal id={i?.purchaseOrderId[0]?._id} />
            </Space.Compact>
          ) : (
            <CreatePurchaseOrderModal />
          )}
          {i?.purchaseInvoiceId?.length !== 0 ? (
            <Space.Compact direction="horizontal">
              <Tag>
                <Row>
                  <Col>
                    PUR: WWC/Pur/{i?.purchaseInvoiceId[0]?.seq}
                    {i?.purchaseInvoiceId[0]?.fileUrl && <DocumentViewModal path={i?.purchaseInvoiceId[0]?.fileUrl} />}

                  </Col>
                  <Col> TaxAmt:-   ₹ {i?.purchaseInvoiceId[0]?.totalTaxable}/-</Col>
                </Row>
              </Tag>
              {/* <PurchaseInvoiceViewModal id={i?.purchaseOrderId[0]?._id} /> */}
            </Space.Compact>
          ) : (

            <CreatePurchaseInvoiceModal />
          )}
        </Space.Compact>
      ),
    },
    {
      title: "Sale",
      dataIndex: "isActive",
      key: "isActive",
      render: (_: any, i: any) => (
        <Space.Compact direction="vertical">
          {i?.perfomaInvoiceId?.length !== 0 ? (
            <Space.Compact direction="horizontal">
              <Tag>
                <Row>
                  <Col>PI: WWC/PI/{i?.perfomaInvoiceId[0]?.seq}
                    {i?.perfomaInvoiceId[0]?.fileUrl && <DocumentViewModal path={i?.perfomaInvoiceId[0]?.fileUrl} />}

                  </Col>
                  <Col> TaxAmt:-   ₹ {i?.perfomaInvoiceId[0]?.totalTaxable}/-</Col>
                </Row>
              </Tag>
              <PerfomaInvoiceViewModal id={i?.perfomaInvoiceId[0]?._id} />
            </Space.Compact>
          ) : (
            <CreatePerfomaInvoiceModal />
          )}
          {i?.taxInvoiceId?.length !== 0 ? (
            <Space.Compact direction="horizontal">
              <Tag>
                <Row>
                  <Col> TI: WWC/TI/{i?.taxInvoiceId[0]?.seq}
                    {i?.taxInvoiceId[0]?.fileUrl && <DocumentViewModal path={i?.taxInvoiceId[0]?.fileUrl} />}

                  </Col>
                  <Col> TaxAmt:-   ₹ {i?.taxInvoiceId[0]?.totalTaxable}/-</Col>
                </Row>
              </Tag>
              {/* <PurchaseInvoiceViewModal id={i?.purchaseOrderId[0]?._id} /> */}
            </Space.Compact>
          ) : (
            <CreateTaxInvoiceModal />
          )}
        </Space.Compact>
      ),
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (id: any, i: any) => (
        <Space direction="vertical">
          <Space size={10}>
            <WorkViewModal id={id} />
            {/* <ManageWorkModal id={id} /> */}
            {accessAuth[0].includes("Edit") && <ManageWorkModal id={id} />}
          </Space>
          {i.leadId.length !== 0 && (
            <Space.Compact direction="horizontal">
              <Tag>WWC/Leads/{i.leadId[0].seq}</Tag>
              <LeadsViewModal id={i.leadId[0]._id} />
            </Space.Compact>
          )}
          {i.leadId.length !== 0 && i.leadId[0].quotationId.length !== 0 && (
            <Space.Compact direction="horizontal">
              <Tag>Quote: WWC/Q/{i.leadId[0].quotationId[0].seq}</Tag>
              <QuotationViewModal id={i.leadId[0].quotationId[0]._id} />
            </Space.Compact>
          )}
        </Space>
      ),
    },
  ];
  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={data}
    />
  );
};
