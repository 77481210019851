import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const PerfomaInvoiceRouter: TRoute[] = [
  {
    path: "/perfomaInvoice",
    name: "perfomaInvoice",
    title: "perfomaInvoice",
    component: DepartmentPageHome,
  },
];

export default PerfomaInvoiceRouter;
