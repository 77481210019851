import moment from "moment";
import * as React from "react";

import { useMutation, useQuery } from "react-query";

import LeadsManageModal from "../Modal/ManageLeads";

import {
  Avatar,
  Badge,
  Button,
  Col,
  Input,
  Popover,
  Result,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import LeadsViewModal from "../Modal/ShowLeadsDetails";
import {
  ColumnType,
  FilterConfirmProps,
  ColumnsType,
} from "antd/lib/table/interface";

import { getAllLead } from "../Requests";

import CreateFollowupModal from "../Modal/CreateFollowup";

import LeadTimelineDrawer from "./ShowLeadTimelineDrawer";
import AssignedPersonManageModal from "../Modal/assignedPerson";
import { getAccessAuthrorization } from "../../../components/access";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { List } from "antd-mobile";
import { isMobile } from "react-device-detect";
import PhoneCall from "../../../components/call";
import WhatsappMessage from "../../../components/whatsapp";
import CreateWorkModal from "../../Work/Modal/CreateWork";
import CreateDocumentModal from "../../Document/Modal/CreateDocument";
import PartyCard from "../../Party/Component/Home/PartyCard";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { log } from "console";
import CreateQuotationModal from "../../Quotation/Modal/CreateQuotation";
import WorkViewModal from "../../Work/Modal/ShowWorkDetails";
import QuotationViewModal from "../../Quotation/Modal/ShowQuotationDetails";
import { getAllConcernedPersonByPartyId } from "../../Party/Requests";
interface DataType {
  id: number;
  name: any;
  seq: number;
  partyId: { companyName: string };
}
type DataIndex = keyof DataType;
const LeadsList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);

  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  const searchInput = React.useRef<any>(null);
  let accessAuth = getAccessAuthrorization("leads");

  const {
    isLoading,
    isError,
    data: lead,
  } = useQuery(["lead"], () => getAllLead(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  // const [lead, setLead] = React.useState<any>(response);
  
  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  console.log(lead);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const getCompanySearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].companyName
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const column: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "enquiryDate",
      key: "enquiryDate",
      render: (text: any) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Lead No.",
      dataIndex: "seq",
      key: "seq",
      ...getColumnSearchProps("seq"),
      render: (seq: any, i: any) => (
        <>
          <Tag>{"WWC/Leads/" + seq}</Tag>
          <Tag color="magenta">{i.leadStatus}</Tag>
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Party",
      dataIndex: "partyId",
      key: "partyId",
      width: "20%",
      ...getCompanySearchProps("partyId"),
      render: (_: any, id: any) => (
        <>
          <PartyCard data={id.partyId[0]} />
        </>
      ),
    },
    {
      title: "Service",
      dataIndex: "services",
      key: "services",
      render: (text: any) => (
        <>
          {text.map((ele: any) => {
            return <Tag>{ele}</Tag>;
          })}{" "}
        </>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },

    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
    },
    // {
    //   title: "Status",
    //   dataIndex: "leadStatus",
    //   key: "leadStatus",
    //   render: (text: any) => <Tag color="magenta">{text}</Tag>,
    // },
    {
      title: "Add Details",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space.Compact direction="vertical">
          <CreateFollowupModal leadId={i._id} />
          <CreateDocumentModal partyId={i.partyId[0]._id} leadId={i._id} />
        </Space.Compact>
      ),
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (id: any, i: any) => (
        <Space direction="vertical">
          <Space size={10}>
            <LeadsViewModal id={id} />
            {/* <LeadsManageModal id={id} /> */}
            {accessAuth[0].includes("Edit") && <LeadsManageModal id={id} />}

            <LeadTimelineDrawer id={id} />
          </Space>
          {i.quotationId.length !== 0 ? (
            <Space.Compact direction="horizontal">
              <Tag>Quote: WWC/Q/{i.quotationId[0].seq}</Tag>
              <QuotationViewModal id={i.quotationId[0]._id} />
            </Space.Compact>
          ) : (
            <CreateQuotationModal leadId={id} partyId={i.partyId[0]._id} />
          )}
          {i.workId.length !== 0 ? (
            <Space.Compact direction="horizontal">
              {" "}
              <Tag>Work: WWC/Works/{i.workId[0].seq}</Tag>
              <WorkViewModal id={i.workId[0]._id} />
            </Space.Compact>
          ) : (
            <CreateWorkModal leadId={id} partyId={i.partyId[0]._id} />
          )}
        </Space>
      ),
    },
    {
      title: "Assigned Person",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space direction="vertical">
          <Avatar
            src={
              <img
                src={
                  i?.assignedPersonId[0]?.partyId[0]?.imageUrl &&
                  s3Path + i?.assignedPersonId[0]?.partyId[0]?.imageUrl
                }
                alt={"W"}
              />
            }
          />
          {i?.assignedPersonId ? (
            <Tag>{i?.assignedPersonId[0]?.partyId[0]?.personName}</Tag>
          ) : (
            <AssignedPersonManageModal id={i._id} />
          )}
          {/* <CreateQuotationModal /> */}
          {/* <FollowupViewModal id={id} /> */}
        </Space>
      ),
    },
  ];
  let NewList = lead.filter(
    (element: any) => element.leadStatus === "New Lead"
  );
  let InterestedList = lead.filter(
    (element: any) => element.leadStatus === "Interested"
  );
  let OpenList = lead.filter((element: any) => element.leadStatus === "Open");
  let QuotedList = lead.filter(
    (element: any) => element.leadStatus === "Quoted"
  );
  let ConvertedList = lead.filter(
    (element: any) => element.leadStatus === "Converted"
  );
  let CancelledList = lead.filter(
    (element: any) => element.leadStatus === "Cancelled"
  );
  let OnHoldList = lead.filter(
    (element: any) => element.leadStatus === "On Hold"
  );
  let NotInterestedList = lead.filter(
    (element: any) => element.leadStatus === "Not Interested"
  );
  const items = [
    {
      label: (
        <Badge count={NewList?.length} offset={[12, 0]} color="yellow">
          New
        </Badge>
      ),

      key: "0",
      children: <Table size="small" columns={column} dataSource={NewList} />,
    },

    {
      label: (
        <Badge count={InterestedList?.length} offset={[12, 0]} color="pink">
          Interested
        </Badge>
      ),
      key: "1",
      children: (
        <Table size="small" columns={column} dataSource={InterestedList} />
      ),
    },
    {
      label: (
        <Badge count={QuotedList?.length} offset={[12, 0]} color="green">
          Quoted
        </Badge>
      ),
      key: "2",
      children: <Table size="small" columns={column} dataSource={QuotedList} />,
    },
    {
      label: "Open",
      key: "3",
      children: <Table size="small" columns={column} dataSource={OpenList} />,

      // disabled: true,
    },

    {
      label: "Converted",
      key: "4",
      children: (
        <Table size="small" columns={column} dataSource={ConvertedList} />
      ),
    },
    {
      label: "Cancelled",
      key: "5",
      children: (
        <Table size="small" columns={column} dataSource={CancelledList} />
      ),
    },
    {
      label: "On Hold",
      key: "6",
      children: <Table size="small" columns={column} dataSource={OnHoldList} />,
    },
    {
      label: "Not Interested",
      key: "7",
      children: (
        <Table size="small" columns={column} dataSource={NotInterestedList} />
      ),
    },
    {
      label: "All Leads",
      key: "8",
      children: <Table size="small" columns={column} dataSource={lead} />,
    },
  ];
  // setLead(lead);
  return (
    <>
      {!isError ? (
        <>
          {!isMobile && (
            <Tabs
              // type="card"
              defaultActiveKey="1"
              items={items}
              // tabBarExtraContent={
              //   <Space.Compact>
              //     <Input onChange={onSearch} suffix={<SearchOutlined />} />
              //     <Button onClick={() => setLead(response)}>Reset</Button>
              //   </Space.Compact>
              // }
            />
          )}
          {isMobile && <MobileLeadList data={lead} />}
        </>
      ) : (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )}
    </>
  );
};

export default LeadsList;
const { Text } = Typography;
const MobileLeadList: React.FC<{ data: any }> = ({ data }) => {
  return (
    <>
      <List style={{ padding: 0 }}>
        {/* <AutoSizer disableHeight> */}
        {data &&
          data.map((element: any, i: any) => (
            <List.Item
              style={{ padding: 0 }}
              key={element?.partyId?.personName}
              // prefix={<Avatar size="large" src={s3Path + element.imageUrl} />}
              description={
                <>
                  <Space.Compact direction="vertical">
                    <Popover
                      content={
                        <Row>
                          {" "}
                          <PhoneCall phoneNumber={element?.partyId?.phoneNo} />
                          <WhatsappMessage
                            phoneNumber={element?.partyId?.phoneNo}
                            message={`Hey  ${element?.partyId?.personName}!`}
                          />
                        </Row>
                      }
                      trigger="click"
                      placement="right"
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                        }}
                        type="danger"
                        code
                      >
                        +91-{element?.partyId?.phoneNo}
                      </Text>
                    </Popover>
                    <Text>{element?.partyId?.email}</Text>

                    <Text type="secondary">
                      {element?.partyId?.companyName}
                    </Text>
                  </Space.Compact>
                </>
              }
            >
              <>
                <Row justify={"space-between"}>
                  <Col span={14}>
                    <Text style={{ fontSize: 20 }} strong>
                      {element?.partyId?.personName}
                    </Text>
                  </Col>
                  <Col span={10}>
                    <Space direction="horizontal">
                      {/* <LeadsViewModal id={element.id} /> */}
                      <CreateFollowupModal leadId={element.id} />

                      <LeadsManageModal id={element.id} />

                      {/* <LeadTimelineDrawer id={element.id} /> */}
                    </Space>
                  </Col>
                </Row>
              </>
            </List.Item>
          ))}
        {/* </AutoSizer> */}
      </List>
    </>
  );
};
