import { path } from "./path";

export const standardRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveStandard",
    module: "standard",
    alias: "admin.standard.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateStandard/:id",
    module: "standard",
    alias: "admin.standard.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveStandard",
    module: "standard",
    alias: "admin.standard.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllStandard",
    module: "standard",
    alias: "admin.standard.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneStandard/:id",
    module: "standard",
    alias: "admin.standard.id",
    isSubDomain: true,
  },
];
