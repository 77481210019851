import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Empty, Row, Space, Table, Tabs, TabsProps, Tag } from "antd";

import {
  getAllSubMenu,
  getSubMenuByCategoryId,
  getSubMenuByServiceId,
} from "../Requests";

import { getAccessAuthrorization } from "../../../components/access";
import parse from "html-react-parser";
import { Tab } from "rc-tabs/lib/interface";
import { JSX } from "react/jsx-runtime";
import ManageSubMenuModal from "../Modal/ManageSubMenu";
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const SubMenuByServiceList: React.FC<{ id: string }> = ({ id }) => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [items, setItems] = React.useState<TabsProps["items"]>([
    { label: "No Data", key: "1", children: <Empty /> },
  ]);
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("submenu");

  const {
    isLoading,
    isError,
    data: submenu,
  } = useQuery(["submenuByService", { id }], () => getSubMenuByServiceId(id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  React.useEffect(() => {
    const tabItem = () => {
      var tmpItems: { label: any; key: string; children: JSX.Element }[] = [];
      submenu.forEach((element: any, i: any) => {
        const id = String(i + 1);
        tmpItems.push({
          label: element?.title,
          key: id,
          children: (
            <Row justify={"space-between"}>
              {element?.description && parse(element?.description)}
              <ManageSubMenuModal id={element.id} />
            </Row>
          ),
        });
      });
      setItems(tmpItems);
    };
    tabItem();
  }, [submenu]);
  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  // const items: TabsProps["items"] = submenu
  //   ? [
  //       submenu.map((element: any, i: any) => {
  //         const id = String(i + 1);
  //         return {
  //           label: element?.title,
  //           key: id,
  //           children: "hello",
  //           // <>{element?.description && parse(element?.description)}</>
  //         };
  //       }),
  //     ]
  //   : [{ label: "No Data", key: "1", children: <Empty /> }];
  console.log(items);

  return (
    <>
      <Tabs
        tabPosition="left"
        size="small"
        defaultActiveKey="2"
        type="card"
        items={items}
      />
    </>
  );
};

export default SubMenuByServiceList;
