import { request } from "../../../utils/services";

export const getAllActiveStandard = async () => {
  const { data } = await request("admin.standard.list.active");
  return data;
};

export const getAllStandard = async () => {
  const { data } = await request("admin.standard.list");
  return data;
};

export const getStandardById = async (id: string) => {
  const { data } = await request("admin.standard.id", { id });
  return data;
};
