import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Avatar,
  DatePicker,
  InputNumber,
  Space,
  Card,
  Tag,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreateQuotationModalContext } from "../Modal/CreateQuotation";
import { s3Path } from "../../../utils/s3Config/s3upload";
import CreatePartyModal from "../../../modules/Party/Modal/CreateParty";
import {
  getAllActiveParty,
  getAllConcernedPersonByPartyId,
} from "../../../modules/Party/Requests";
import { imLogoIcon } from "../../../constants/logo";

import CreateLeadsModal from "../../../modules/Leads/Modal/CreateLeads";
import { getAllLead } from "../../../modules/Leads/Requests";
import { tinyMCE } from "../../../utils/keys";
import CreateTaskTemplateModal from "../../TaskTemplate/Modal/CreateTaskTemplate";
import { getAllTaskTemplate } from "../../TaskTemplate/Requests";
import CreateConcernedPersonModal from "../../Party/Modal/CreateConcernedPerson";

const CreateQuotation: React.FC<{ leadId?: string; partyId?: string }> = (
  props
) => {
  const modalContext = React.useContext(CreateQuotationModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [attention, setAttention] = React.useState<any>([]);
  const [partyId, setPartyId] = React.useState<any>();
  const [remarks, setRemarks] = React.useState<string>();

  const editorRef = React.useRef<TinyMCEEditor | null>(null);
  const createQuotationMutation = useMutation(
    (values: any) => request("admin.quotation.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("quotation");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });

  const { data: lead } = useQuery(["lead"], async () => getAllLead(), {
    refetchOnWindowFocus: false,
  });
  const { data: taskTemplate } = useQuery(
    ["taskTemplate"],
    async () => getAllTaskTemplate(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.description = remarks;

    setFormLoading(true);

    createQuotationMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Quotation has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
        editorRef?.current?.setContent("");
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const handleEditorChange = (content: any, editor: any) => {
    setRemarks(content);
  };
  const mutateAttention = useMutation((id: string) =>
    getAllConcernedPersonByPartyId(id)
  );
  const getAttention = async (id: string) => {
    setPartyId(id);
    mutateAttention
      .mutateAsync(id)
      .then((payload) => {
        setAttention(payload);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };

  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );

  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...leadList];
  props.leadId &&
    form.setFieldsValue({ leadId: props.leadId, partyId: props.partyId });
  React.useEffect(() => {
  props.partyId && getAttention(props.partyId)
  },[])
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // onValuesChange={handleTotal}
    >
      <Space.Compact block>
        <Card style={{ width: "30%" }}>
          <Form.Item
            label="Proposal Date"
            style={{ fontWeight: "bold" }}
            name="date"
            tooltip={{
              title: "date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker format={"DD-MMM-YYYY"} />
          </Form.Item>
          <Form.Item
            label="Select Lead"
            style={{ fontWeight: "bold" }}
            name="leadId"
            tooltip={{
              title: "Select Lead",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              // onChange={(element, i) => console.log(i)}
              onSelect={(id) => {
                const selected = lead.find((e: any) => e._id === id);
                // console.log(selected);
                form.setFieldsValue({ partyId: selected.partyId[0]._id });
              }}
              showSearch
              placeholder="Select lead"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateLeadsModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
              disabled={props.leadId ? true : false}
            >
              {lead &&
                lead?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item._id} key={i}>
                      {item?.seq}. {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Card>
        <Card style={{ width: "70%" }}>
          <Form.Item
            label=" Select Party"
            name="partyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "party",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              onSelect={getAttention}
              //onChange={onPartySelect}
              showSearch
              placeholder="Select party"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (
                  option!.props?.children[1]?.props
                    ?.children as unknown as string
                )
                  .toString()
                  .toLowerCase()
                  .includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
              disabled={props.partyId ? true : false}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Kind Attention"
            name="attention"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "attention",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              size="large"
              showSearch
              placeholder="Select attention"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  {partyId && (
                    <Row justify={"end"} align={"middle"}>
                      <CreateConcernedPersonModal partyId={partyId} />
                      <Button
                        type="text"
                        onClick={() => getAttention(attention[0].partyId)}
                        icon={<ReloadOutlined />}
                      />
                    </Row>
                  )}
                  {menu}
                </>
              )}
            >
              {attention &&
                attention?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Row justify={"space-between"}>
                        <Col>
                          {item.name}
                          <Tag>{item.designation}</Tag>
                        </Col>
                        <Space.Compact direction="vertical">
                          <Typography.Text type="success">
                            <PhoneOutlined /> +91-{item.mobile}
                          </Typography.Text>
                          <Typography.Text type="warning">
                            {" "}
                            <MailOutlined /> {item.email}
                          </Typography.Text>
                        </Space.Compact>
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Card>
      </Space.Compact>
      <Editor
        // key={editorkey}
        apiKey={tinyMCE.apiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />

      <Space.Compact block>
        <Card style={{ width: "50%" }}>
          <Form.Item
            label="Terms & Condition"
            style={{ fontWeight: "bold" }}
            name="terms"
            tooltip={{
              title: "terms",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <TextArea placeholder="Enter Terms & Condition" maxLength={150} />
          </Form.Item>
          <Form.Item
            label="Select Template"
            name="template"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "template",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              onSelect={(value) => {
                if (editorRef.current) {
                  editorRef?.current?.setContent(value);
                }
              }}
              placeholder="Select A Template"
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateTaskTemplateModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {taskTemplate &&
                taskTemplate?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.template} key={i}>
                      <Typography.Text strong>{item?.title}</Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Card>
      </Space.Compact>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateQuotation;
