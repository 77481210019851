import { TRoute } from "../../utils/routes";
import SubMenuPageHome from "./Page/Home";

const SubMenuRouter: TRoute[] = [
  {
    path: "/submenu",
    name: "submenu",
    title: "submenu",
    component: SubMenuPageHome,
  },
];

export default SubMenuRouter;
