import { Tag } from "antd";

import * as React from "react";

import { useQuery } from "react-query";

import DesignationManageModal from "../../Modal/ManageDesignation";

import { Space, Table } from "antd";
import DesignationViewModal from "../../Modal/ShowDesignationDetails";

import { getAllDesignation } from "../../Requests";
import { getAccessAuthrorization } from "../../../../../components/access";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const DesignationList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("designation");

  const {
    isLoading,
    isError,
    data: designation,
  } = useQuery(["designation"], () => getAllDesignation(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const column = [
    {
      title: "Designation ID.",
      dataIndex: "id",
      key: "id",
      render: (text: any) => <p>IM/Desig/{text}</p>,
    },
    {
      title: "Designation",
      dataIndex: "designationName",
      key: "designationName",
    },
    {
      title: "Department",
      dataIndex: "departmentId",
      key: "departmentId",
      render: (_: any, i: any) => <p>{i.departmentId.departmentName}</p>,
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <DesignationViewModal id={id} /> */}
          {/* <DesignationManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <DesignationManageModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={designation} />;
};

export default DesignationList;
