import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Radio,
  Select,
  DatePicker,
  Tag,
  Avatar,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";

import LocalStorageService from "../../../utils/local-storage";
import { CreateWorkModalContext } from "../Modal/CreateWork";

import { getAllActiveParty } from "../../Party/Requests";

import {
  getAllActivePersonnel,
  getAllPersonnel,
} from "../../HR/Personnel/Requests";
import ReactQuill from "react-quill";
import { Editor } from "@tinymce/tinymce-react";
import { quillModules, tinyMCE } from "../../../utils/keys";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "../../../utils/s3Config/s3upload";
import CreatePartyModal from "../../Party/Modal/CreateParty";
import { imLogoIcon } from "../../../constants/logo";
import { getAllLead } from "../../Leads/Requests";
import CreateLeadsModal from "../../Leads/Modal/CreateLeads";
import { getCategoryByType } from "../../Category/Requests";
import { getAllActiveService } from "../../Service/Requests";
import { getAllActiveProduct } from "../../Product/Requests";
import { getAllActiveStandard } from "../../Standard/Requests";
import CreateServiceModal from "../../Service/Modal/CreateService";
import CreateProductModal from "../../Product/Modal/CreateProduct";
import CreateStandardModal from "../../Standard/Modal/CreateStandard";

const CreateWork: React.FC<{ leadId?: string; partyId?: string }> = (props) => {
  const modalContext = React.useContext(CreateWorkModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [remarks, setRemarks] = React.useState<string>();
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);
  // const [editorkey, seteditorkey] = React.useState(4);
  const [serviceList, setServiceList] = React.useState<Object | any>(undefined);
  const [partyList, setPartyList] = React.useState<Object | any>(undefined);

  const createWorkMutation = useMutation(
    (values: any) => request("admin.work.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("work");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });

  const { data: lead } = useQuery(["lead"], async () => getAllLead(), {
    refetchOnWindowFocus: false,
  });
  const { data: service } = useQuery(
    ["service"],
    async () => getAllActiveService(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: product } = useQuery(
    ["product"],
    async () => getAllActiveProduct(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: standard } = useQuery(
    ["standard"],
    async () => getAllActiveStandard(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: personnel } = useQuery(
    ["personnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    // values.description = remarks;
    values.attachment = imageUrl;
    //Notification Values
    values.notificationTitle = "Wake up! You are added to team in New Work";
    values.notificationBody =
      "Be Prepare , Responsibility is at your Shoulder!  Click to view work details";
    values.receiverId = values.teamId;
    values.senderId = LocalStorageService.getAccount().personnel.id;
    setFormLoading(true);

    createWorkMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Work has been successfully created.");
        form.resetFields();
        // cleareditor();

        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const options = [
    { label: "Project", value: "Project" },

    { label: "Other", value: "Other" },
  ];
  const statusOptions = [
    {
      value: "New",
      label: "New",
    },
    {
      value: "In Progress",
      label: "In Progress",
    },
    {
      value: "In Testing",
      label: "In Testing",
    },
    {
      value: "On Hold",
      label: "On Hold",
    },
    {
      value: "Completed",
      label: "Completed",
    },

    {
      value: "Cancelled",
      label: "Cancelled",
    },
    {
      value: "Ticket Open",
      label: "Ticket Open",
    },
    {
      value: "Ticket Closed",
      label: "Ticket Closed",
    },
  ];
  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let laboratoryList = party.filter(
    (element: any) => element.partyType[0] === "Laboratory"
  );
  let authorityList = party.filter(
    (element: any) => element.partyType[0] === "Authority"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  // let partyList = [...clientList, ...leadList];
  const ArrangeParentChildService = (arr: any) => {
    var parentChildArray;

    arr?.reduce((acc: any, val: any, ind: any, array: any) => {
      const childs: any = [];
      array.forEach((el: any, i: any) => {
        if (childs.includes(el.parentId) || el?.parentId === val.id) {
          childs.push(el);
        }
      });
      parentChildArray = acc.concat({ ...val, childs });
      return acc.concat({ ...val, childs });
    }, []);
    return parentChildArray;
  };

  // let serviceList = ArrangeParentChildService(service);

  React.useEffect(() => {
    setPartyList([...clientList, ...leadList]);
    // let serviceList = ArrangeParentChildService(service);
    // setServiceList(serviceList);
    // form.setFieldsValue({ workType: "Project" });
    props.leadId &&
      form.setFieldsValue({ leadId: props.leadId, partyId: props.partyId });
  }, []);


  // const mutateDesignation = useMutation((id: string) =>
  //   getAllStandardByProductId(id)
  // );

  // const getDesignation = async (id: string) => {
  //   console.log(id, "parameters");

  //   mutateDesignation
  //     .mutateAsync(id)
  //     .then((payload) => {
  //       setDesignation(payload);
  //     })
  //     .catch((e: any) => {
  //       console.log("error", e);
  //     });
  // };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row align="middle" justify="space-between" gutter={10}>
        {/* <Col flex={3} span={8}>
          <Form.Item
            // label="Type"
            style={{ fontWeight: "bold" }}
            name="workType"
          >
            <Radio.Group options={options} />
          </Form.Item>
        </Col> */}

        <Col flex={3} span={16}>
          <Form.Item
            label="Select Lead"
            name="leadId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "leadId",
              icon: <InfoCircleOutlined />,
            }}
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Select  Party",
                // },
              ]
            }
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Lead"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateLeadsModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
              disabled={props.leadId ? true : false}
            >
              {lead &&
                lead?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item._id} key={i}>
                      {item?.seq}. {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={16}>
          <Form.Item
            label="Select Party"
            name="partyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "partyId",
              icon: <InfoCircleOutlined />,
            }}
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Select  Party",
                // },
              ]
            }
            hasFeedback
          >
            <Select
              // onSelect={(id) => {
              //   const selected = partyList.find((e: any) => e._id === id);
              //   // console.log(selected);
              //   form.setFieldsValue({ partyId: selected.partyId[0]._id });
              // }}
              showSearch
              placeholder="Select Party"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (
                  option!.props?.children[1]?.props
                    ?.children as unknown as string
                )
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
              disabled={props.partyId ? true : false}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={4}>
          <Form.Item
            label="Start Date"
            name="startDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "start Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Start Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col flex={3} span={4}>
          <Form.Item
            label="End Date"
            name="endDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "End Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select End Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Select Laboratory"
            name="laboratoryId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "laboratoryId",
              icon: <InfoCircleOutlined />,
            }}
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Select  Party",
                // },
              ]
            }
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Lab"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (
                  option!.props?.children[1]?.props
                    ?.children as unknown as string
                )
                  .toString()
                  .toLowerCase()
                  .includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {laboratoryList &&
                laboratoryList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Select Authority"
            name="authorityId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "authority",
              icon: <InfoCircleOutlined />,
            }}
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Select  Party",
                // },
              ]
            }
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Authority"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (
                  option!.props?.children[1]?.props
                    ?.children as unknown as string
                )
                  .toString()
                  .toLowerCase()
                  .includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {authorityList &&
                authorityList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={24}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Enter Tilte"
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Select Service"
            name="serviceId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "service",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Work status",
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              placeholder="Select Service"
              optionFilterProp="children"
              filterOption={(input: any, option: any) => {
                // console.log(option);
                return (option.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input);
              }}
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateServiceModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {service &&
                service.map((ele: any, i: any) => {
                  return (
                    <Select.Option key={i} value={ele.id}>
                      {ele.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Select Product"
            name="productId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "product",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              onChange={(id) => {
                let selProduct = product.filter((element: any) => element.id === id)
                // console.log(selProduct)
                selProduct[0]?.standardId && form.setFieldsValue({ standardId: selProduct[0]?.standardId })
              }}
              showSearch
              placeholder="Select Product"
              optionFilterProp="children"
              filterOption={(input: any, option: any) => {
                // console.log(option);
                return (option.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input);
              }}
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateProductModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {product &&
                product.map((ele: any, i: any) => {
                  return (
                    <Select.Option key={i} value={ele.id}>
                      {ele.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Select Standard"
            name="standardId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "standard",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              showSearch
              placeholder="Select Standard"
              optionFilterProp="children"
              filterOption={(input: any, option: any) => {
                // console.log(option);
                return (option.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input);
              }}
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateStandardModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {standard &&
                standard.map((ele: any, i: any) => {
                  return (
                    <Select.Option key={i} value={ele.id}>
                      {ele.ISNumber}-{ele.ISName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        {/* <Col flex={3} span={8}>
          <Form.Item
            label="Select Service"
            name="serviceId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "service",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Work status",
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              placeholder="Select Service"
              optionFilterProp="children"
              filterOption={(input: any, option: any) => {
                // console.log(option);
                return (option.options.children as unknown as string).includes(
                  input
                );
              }}
            >
              {serviceList &&
                serviceList.map((ele: any, i: any) => {
                  return (
                    !ele.parentId && (
                      <Select.OptGroup key={i} label={ele.title}>
                        {ele.childs.map((seg: any, ind: any) => {
                          return (
                            <Select.Option key={ind} value={seg.id}>
                              {seg.title}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    )
                  );
                })}
            </Select>
          </Form.Item>
        </Col> */}
        <Col flex={3} span={12}>
          <Form.Item
            label="Work Status"
            name="workStatus"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "work Status",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Work status",
              },
            ]}
            hasFeedback
          >
            <Select placeholder="Select Work Status" options={statusOptions} />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Select Team"
            name="teamId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "team",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select team",
              },
            ]}
            hasFeedback
          >
            <Select mode="multiple" placeholder="Please Select team">
              {personnel &&
                personnel?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                    // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Description"
        name="description"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "description",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: " please enter description",
          },
        ]}
        hasFeedback
      >
        <ReactQuill theme="snow" modules={quillModules} />
      </Form.Item>

      <Row justify={"space-between"}>
        <Form.Item
          label="Status"
          style={{ fontWeight: "bold" }}
          name="isActive"
          tooltip={{
            title: "status",
            icon: <InfoCircleOutlined />,
          }}
          valuePropName="checked"
        // rules={[{ required: true }]}
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateWork;
