import * as React from "react";
import { Form, Input, Button, message, Switch, Select } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageCollectionModalContext } from "../Modal/ManageCollection";

import { useQuery } from "react-query";

import { getAllActiveCollection, getCollectionById } from "../Requests";

const ManageCollectionForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageCollectionModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  console.log(props.id);
  const manageCollectionMutation = useMutation(
    (values: any) =>
      request("admin.collection.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("collection");
      },
    }
  );
  const {
    isLoading,
    data: response,
    error,
    isError,
  } = useQuery(
    ["collection", props.id],
    async () => getCollectionById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const {
    //isLoading,
    //isError,
    data: collection,
  } = useQuery(["collection"], () => getAllActiveCollection(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageCollectionMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The collection has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  console.log(collection);
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={response}
      form={form}
    >
      <Form.Item
        label="Title "
        name="collectionTitle"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Collection Title",
          },
          { message: "Please enter Collection Title" },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Icon Name "
        name="icon"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Icon",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Icon Title",
          },
          { message: "Please enter Icon Title" },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Route "
        name="route"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Route",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Route Name",
          },
          { message: "Please enter Route Name" },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Image "
        name="image"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Image",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter Image ",
        //   },
        //   { message: "Please enter Image " },
        // ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Parent "
        name="parentId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Parent",
          icon: <InfoCircleOutlined />,
        }}
        // rules={[
        //   {
        //     required: true,
        //     message: "Please enter Icon Title",
        //   },
        //   { message: "Please enter Icon Title" },
        // ]}
        hasFeedback
      >
        <Select
          // mode="multiple"
          showSearch
          placeholder="Select Parent"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {collection &&
            collection?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.collectionTitle}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Status"
        name="isActive"
        style={{ fontWeight: "bold" }}
        valuePropName="checked"
        required
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageCollectionForm;
