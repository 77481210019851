import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const TaskTemplateRouter: TRoute[] = [
  {
    path: "/taskTemplate",
    name: "taskTemplate",
    title: "taskTemplate",
    component: DepartmentPageHome,
  },
];

export default TaskTemplateRouter;
