import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Space,
  Table,
  Tag,
  Image,
  Empty,
  Typography,
  Card,
  Row,
  Avatar,
} from "antd";

import { getFollowupByLeadId } from "../Requests/index";

import { s3Path } from "../../../utils/s3Config/s3upload";
import moment from "moment";
import { imLogoIcon } from "../../../constants/logo";
const { Text, Title } = Typography;
interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const FollowUpListByLead: React.FC<{ id: string }> = (props) => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["followup", props.id], () => getFollowupByLeadId(props.id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "FollowUp Date",
      dataIndex: "followupDate",
      key: "followupDate",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Concerned Person",
      children: [
        {
          title: "Name",
          dataIndex: "name",
          key: "name",

          render: (_: any, i: any) => (
            <>
              <Text>{i?.concernedPersonId?.name}</Text>
              <br />
              <Text>{i?.concernedPersonId?.designation}</Text>
            </>
          ),
        },
        {
          title: "contact",
          dataIndex: "mobile",
          key: "mobile",

          render: (_: any, i: any) => (
            <>
              <Text>{i?.concernedPersonId?.mobile}</Text>
              <br />
              <Text>{i?.concernedPersonId?.email}</Text>
            </>
          ),
        },
      ],
    },

    {
      title: "Followup Purpose",
      dataIndex: "followupPurpose",
      key: "followupPurpose",
    },
    {
      title: "Client Remarks",
      dataIndex: "clientRemarks",
      key: "clientRemarks",
    },

    {
      title: "Next Followup Date",
      dataIndex: "nextFollowupDate",
      key: "nextFollowupDate",
      render: (text) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "FollowedUp By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_: any, i: any) => (
        <>
          <Tag color="orange">{i?.createdBy?.username}</Tag>
          <br />
          <Text>{i?.createdBy?.name}</Text>
        </>
      ),
    },
  ];

  return (
    <>
      {response &&
        response.map((item: any, i: any) => {
          return (
            <Card size="small">
              <Space.Compact direction="vertical" style={{ margin: 10 }}>
                <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {moment(item?.followupDate).format("DD")}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "teal",
                  }}
                >
                  {moment(item?.followupDate).format("MMM")}
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: 12,
                    color: "teal",
                  }}
                >
                  {moment(item?.followupDate).format("YYYY")}
                </Typography.Text>
              </Space.Compact>
              <Space.Compact direction="vertical">
                <Row align={"middle"}>
                  <Avatar
                    src={
                      <img
                        src={
                          item?.concernedPersonId?.partyId?.imageUrl
                            ? s3Path + item?.concernedPersonId.partyId.imageUrl
                            : imLogoIcon
                        }
                        alt={item?.concernedPersonId?.partyId?.personName}
                      />
                    }
                  />
                  <Typography.Text strong>
                    {item?.concernedPersonId?.partyId?.companyName}
                  </Typography.Text>
                </Row>
                <Typography.Text strong>
                  {item.concernedPersonId.name}
                </Typography.Text>
                <Typography.Text>{item.clientRemarks}</Typography.Text>
              </Space.Compact>
              <Row justify={"end"} align={"bottom"}>
                {/* <Tag> {moment(item.followupDate).format("DD-MMM-YYYY")}</Tag> */}
                <Tag color="magenta">{item.type}</Tag>

                <Tag color="blue">{item.followupPurpose}</Tag>
                <Tag color="gold">
                  {" "}
                  {moment(item.nextFollowupDate).format("DD-MMM-YYYY")}
                </Tag>
                <Avatar
                  src={
                    <img
                      src={
                        item.createdBy?.partyId?.imageUrl
                          ? s3Path + item.createdBy?.partyId?.imageUrl
                          : imLogoIcon
                      }
                      alt={"W"}
                    />
                  }
                />
              </Row>
            </Card>
          );
        })}
    </>
  );

  // <Table size="small" columns={column} dataSource={response} bordered />;
};

export default FollowUpListByLead;
