import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";
import CreateBlogModal from "../Modal/CreateBlog";
import BlogList from "../Component/List";
import { getAccessAuthrorization } from "../../../components/access";

const BlogPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("blog");
  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Blog List</h2>
              {/* <CreateBlogModal /> */}
              {accessAuth[0].includes("Create") && <CreateBlogModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <BlogList /> */}
          {accessAuth[0].includes("View") ? (
            <BlogList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(BlogPageHome);
