import { request } from "../../../utils/services";

export const getAllActiveDocument = async () => {
  const { data } = await request("admin.document.list.active");
  return data;
};

export const getAllDocument = async () => {
  const { data } = await request("admin.document.list");
  return data;
};

export const getDocumentById = async (id: string) => {
  const { data } = await request("admin.document.id", { id });
  return data;
};
export const getDocumentByPartyId = async (id: string) => {
  const { data } = await request("admin.document.party.id", { id });
  return data;
};
export const getDocumentByWorkId = async (id: string) => {
  const { data } = await request("admin.document.work.id", { id });
  return data;
}
export const getDocumentByType = async (type: string) => {
  const { data } = await request("admin.document.type", {
    type,
  });
  return data;
};
