import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Upload,
  Typography,
  Slider,
} from "antd";
import { request } from "../../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  MailOutlined,
  IdcardOutlined,
  FlagOutlined,
  HomeOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../../src";
import { CreatePersonnelModalContext } from "../Modal/CreatePersonnel";

import { Card } from "antd";

import LocalStorageService from "../../../../utils/local-storage";

import { getAllDepartment } from "../../Department/Requests";

import {
  getAllDesignation,
  getAllDesignationByDeptId,
} from "../../Designation/Requests";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";
import { uploadFileToAWS } from "../../../../utils/s3Config/s3upload";
import { InputRules } from "../../../../utils/InputRules";

const { Text } = Typography;
const CreatePersonnelForm: React.FC = () => {
  const modalContext = React.useContext(CreatePersonnelModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [designation, setDesignation] = React.useState([]);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const createPersonnelMutation = useMutation(
    (values: any) => request("admin.personnel.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("personnel");
      },
    }
  );

  const {
    //  isLoading,
    //  isError,
    data: department,
  } = useQuery(["department"], () => getAllDepartment(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const { Option } = Select;
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    values.imageUrl = galley;
    createPersonnelMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The personnel has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });

    setFormLoading(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const mutateDesignation = useMutation((id: string) =>
    getAllDesignationByDeptId(id)
  );

  const getDesignation = async (id: string) => {
    console.log(id, "parameters");

    mutateDesignation
      .mutateAsync(id)
      .then((payload) => {
        setDesignation(payload);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };
  const setCurrentAddress = (value: any) => {
    const formData = form.getFieldsValue();
    console.log(value);

    value.target.checked
      ? form.setFieldsValue({
          currentAddress: formData.address,
          currentAddressLocalId: formData.localId,
        })
      : form.setFieldsValue({
          currentAddress: "",
          currentAddressLocalId: "",
        });
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Card title="Personal Details">
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item
              label="Department"
              style={{ fontWeight: "bold" }}
              name="departmentId"
              tooltip={{
                title: "department",
                icon: <InfoCircleOutlined />,
              }}
              // rules={[{ required: true }]}
              hasFeedback
            >
              <Select onSelect={getDesignation} placeholder="Select Department">
                {department &&
                  department?.map((item: any, i: number) => {
                    return (
                      <Select.Option value={item?.id} key={i}>
                        {item?.departmentName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Designation"
              style={{ fontWeight: "bold" }}
              name="designationId"
              tooltip={{
                title: "desigination",
                icon: <InfoCircleOutlined />,
              }}
              //rules={[{ required: true }]}
              hasFeedback
            >
              <Select placeholder="Select Designation">
                {designation &&
                  designation?.map((item: any, i: number) => {
                    return (
                      <Select.Option value={item?.id} key={i}>
                        {item?.designationName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col flex={5} span={12}>
            <Form.Item
              label="Upload Image"
              style={{ fontWeight: "bold" }}
              name="imageUrl"
              valuePropName="checked"
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                accept="image/png, image/jpeg, image/jpg"
                action={async (file) => {
                  const response: any = await uploadFileToAWS(
                    file,
                    "personnel"
                  );
                  setGallery(response.key);
                  return response.key;
                }}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Employee Name"
              style={{ fontWeight: "bold" }}
              name="personName"
              tooltip={{
                title: "personnelName",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Employee Name",
                },
                { whitespace: true },
                { min: 3 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              <Input addonBefore={<UserOutlined />} maxLength={25} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Mobile Number"
              style={{ fontWeight: "bold" }}
              name="phoneNo"
              tooltip={{
                title: "mobileNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number",
                },
                { whitespace: true },
                { min: 10 },
                ...InputRules.phoneNo,
              ]}
              hasFeedback
            >
              <Input addonBefore="+91-" maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Alt Mobile Number"
              style={{ fontWeight: "bold" }}
              name="altMobileNo"
              tooltip={{
                title: "AltMobileNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ whitespace: true }, { min: 10 }, ...InputRules.phoneNo]}
              hasFeedback
            >
              <Input addonBefore="+91-" maxLength={10} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item
              label="Email"
              style={{ fontWeight: "bold" }}
              name="email"
              tooltip={{
                title: "email",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Email",
                  type: "email",
                },
                { whitespace: true },
                { min: 10 },
              ]}
              hasFeedback
            >
              <Input
                addonBefore={<MailOutlined />}
                type="email"
                maxLength={40}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Gender"
              style={{ fontWeight: "bold" }}
              name="gender"
              tooltip={{
                title: "gender",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select defaultValue="Gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Marital Status"
              style={{ fontWeight: "bold" }}
              name="maritalStatus"
              tooltip={{
                title: "maritalStatus",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select defaultValue="Marital Status">
                <Option value="Married">Married</Option>
                <Option value="UnMarried">UnMarried</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item
              label="Nationality"
              style={{ fontWeight: "bold" }}
              name="nationality"
              tooltip={{
                title: "nationality",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter nationality",
                },
                { whitespace: true },
                { min: 3 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              <Input addonBefore={<FlagOutlined />} maxLength={15} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Aadhar Card Number"
              style={{ fontWeight: "bold" }}
              name="addharNo"
              tooltip={{
                title: "addharCardNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Adharcard",
                },
                { whitespace: true },
                { min: 12 },
                ...InputRules.number,
              ]}
              hasFeedback
            >
              <Input addonBefore={<IdcardOutlined />} maxLength={12} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="PAN Card Number"
              style={{ fontWeight: "bold" }}
              name="panNo"
              tooltip={{
                title: "panCardNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Pan Number",
                },
                { whitespace: true },
                { min: 10 },
                ...InputRules.number,
              ]}
              hasFeedback
            >
              <Input addonBefore={<IdcardOutlined />} maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Status"
              style={{ fontWeight: "bold" }}
              name="isActive"
              tooltip={{
                title: "isActive",

                icon: <InfoCircleOutlined />,
              }}
              //rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="In Active"
                defaultChecked
              />
              {/* <Input /> */}
            </Form.Item>
          </Col>
        </Row>
        {/* <p>
          <b> Password:</b>
        </p>
        <Checkbox>
          <b>Do you want to Update Password?</b>
        </Checkbox> */}
        {/* </Card>
      <Card title="Address"> */}
        <Row gutter={20}>
          <Col flex={4} span={16}>
            <Form.Item
              label="Permanent Address"
              style={{ fontWeight: "bold" }}
              name="address"
              tooltip={{
                title: "permanentAddress",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Permanent Address",
                },
                { whitespace: true },
                { min: 3 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              <Input
                addonBefore={<HomeOutlined className="site-form-item-icon" />}
                maxLength={60}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Checkbox onChange={setCurrentAddress}>
            <b>Is Permanent Address is Same as Present Address?</b>
          </Checkbox>
          ;
        </Row>
        <Row gutter={20}>
          <Col flex={4} span={16}>
            <Form.Item
              label="Current Address"
              style={{ fontWeight: "bold" }}
              name="currentAddress"
              tooltip={{
                title: "currentAddress",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Your Current Address",
                },
                { whitespace: true },
                { min: 3 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              <Input
                addonBefore={<HomeOutlined className="site-form-item-icon" />}
                maxLength={60}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Card title="Job Details">
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Date of Joining"
              style={{ fontWeight: "bold" }}
              name="doj"
              tooltip={{
                title: "doj",
                icon: <InfoCircleOutlined />,
              }}
              //rules={[{ required: true }]}
              hasFeedback
            >
              <DatePicker
                // defaultValue={moment("01/01/2015", dateFormatList[0])}
                format={"DD-MM-YY"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Qualification"
              style={{ fontWeight: "bold" }}
              name="qualification"
              tooltip={{
                title: "qualification",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select
                defaultValue="Please Select"
                //style={{ width: 120 }}
                allowClear
              >
                <Option value="Below 10">Below 10</Option>
                <Option value="10th pass">10th Pass</Option>
                <Option value="10+2 pass">10+2 Pass</Option>
                <Option value="ITI">ITI</Option>
                <Option value="Graduate">Graduate</Option>
                <Option value="Post-Graduate">Post-Graduate</Option>
                <Option value="PhD">PhD</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Experience"
              style={{ fontWeight: "bold" }}
              name="experience"
              tooltip={{
                title: "experience",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Slider
                min={0}
                max={10}
                // onChange={onChange}
                // value={typeof inputValue === "number" ? inputValue : 0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Employee Type"
              style={{ fontWeight: "bold" }}
              name="empType"
              tooltip={{
                title: "empType",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select
                defaultValue="Please Select"
                // style={{ width: 120 }}
                allowClear
              >
                <Option value="Inhouse Employee">Inhouse Employee</Option>
                <Option value="Outsource Employee">Outsource Employee</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Shift Time"
              style={{ fontWeight: "bold" }}
              name="shiftTime"
              tooltip={{
                title: "shiftTime",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select
                defaultValue="Please Select"
                //  style={{ width: 120 }}
                allowClear
              >
                <Option value="Day">Day</Option>
                <Option value="Night">Night</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Job Type"
              style={{ fontWeight: "bold" }}
              name="jobType"
              tooltip={{
                title: "jobType",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
              hasFeedback
            >
              <Select
                defaultValue="Please Select"
                // style={{ width: 120 }}
                allowClear
              >
                <Option value="Full Time">Full Time</Option>
                <Option value="Part Time">Part Time</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              label="Biometric Employee Code"
              style={{ fontWeight: "bold" }}
              name="bioEmpCode"
              tooltip={{
                title: "bioEmpCode",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                // {
                //   required: true,
                //   message: "Please Enter Employee Code",
                // },
                { whitespace: true },
                { min: 5 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              <Input maxLength={20} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Provident Fund Account No."
              style={{ fontWeight: "bold" }}
              name="pfAccNo"
              tooltip={{
                title: "pfAccNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                // {
                //   required: true,
                //   message: "Please Enter PF Number",
                // },
                { whitespace: true },
                { min: 22 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              <Input maxLength={22} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="ESIC Number"
              style={{ fontWeight: "bold" }}
              name="esicNo"
              tooltip={{
                title: "esicNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                // {
                //   required: true,
                //   message: "Please Enter ESIC Number",
                // },
                { whitespace: true },
                { min: 17 },
                ...InputRules.text,
              ]}
              hasFeedback
            >
              {" "}
              <Input maxLength={17} />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      {/* <Card title="Skills/ Expertise">
        <Form.Item name="skillId">
          <Checkbox.Group style={{ width: "100%" }}>
            <Row>
              {skills &&
                skills?.map((item: any) => {
                  return (
                    <Col span={8}>
                      <Checkbox value={item.id}>{item.title}</Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Card> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePersonnelForm;
