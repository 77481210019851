import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Col,
  Row,
  Checkbox,
  Tag,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { CreateAccessModalContext } from "../Modal/CreateAccess";
import LocalStorageService from "../../../utils/local-storage";
import { getAllRoles } from "../../../modules/Roles/Requests";
import { getAllCollection } from "../../Collection/Requests";
import { forEach } from "lodash";
import { useForm } from "rc-field-form";
import { getAccessByRoleId } from "../Requests";

const CreateAccessForm: React.FC = () => {
  const modalContext = React.useContext(CreateAccessModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [isAssigned, setIsAssigned] = React.useState(false);

  const createAccessMutation = useMutation(
    (values: any) => request("admin.access.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("access");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: roles,
  } = useQuery(["roles"], () => getAllRoles(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const {
    //isLoading,
    //isError,
    data: collections,
  } = useQuery(["collections"], () => getAllCollection(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;

    createAccessMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The access has been successfully created.");
        form.resetFields();
        setIsAssigned(false);
        // collections = undefined;
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const options = [
    { label: "Create", value: "Create" },
    { label: "Edit", value: "Edit" },
    { label: "View", value: "View" },
    { label: "No Access", value: "NoAccess" },
  ];
  const [form] = Form.useForm();
  const mutateRoles = useMutation((id: string) => getAccessByRoleId(id));
  const onRoleSelect = async (id: string) => {
    mutateRoles
      .mutateAsync(id)
      .then((payload) => {
        if (payload.length > 0) {
          setIsAssigned(false);
          message.warning("Module Already Assigned");
        } else setIsAssigned(true);
      })
      .catch((e: any) => {
        console.log("error", e);
      });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      //initialValues={roles}
    >
      <Form.Item
        label="Role"
        name="roleId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Select Role",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select Role"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA: any, optionB: any) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
          onSelect={onRoleSelect}
        >
          {roles &&
            roles.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <h3>Modules Access </h3>

      <Form.List name="collection">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              <Row
                gutter={16}
                key={field.key}
                justify="space-around"
                align="top"
              >
                <Col flex={1} span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "collectionId"]}
                        initialValue={collections[index].id}
                      >
                        <Select disabled>
                          {collections &&
                            collections.map((item: any, i: number) => {
                              return (
                                <Select.Option value={item.id} key={i}>
                                  {item.collectionTitle}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col flex={1} span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item {...field} name={[field.name, "accessType"]}>
                        <Checkbox.Group
                          options={options}
                          defaultValue={["View"]}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
              </Row>
            ))}
            {isAssigned && (
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    for (let i = 0; i < collections.length; i++) {
                      // const element = array[i];
                      add();
                    }
                    setIsAssigned(false);
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Access
                </Button>
              </Form.Item>
            )}
          </>
        )}
      </Form.List>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateAccessForm;
