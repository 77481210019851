import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Checkbox,
  Col,
  Upload,
  Typography,
  Card,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  UserOutlined,
  BankOutlined,
  AccountBookOutlined,
  IdcardOutlined,
  CodepenOutlined,
  BranchesOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../";
import { ManageBankDetailsModalContext } from "../Modal/ManageBankDetails";
import { useQuery } from "react-query";

import LocalStorageService from "../../../utils/local-storage";

import { getBankByIFSC } from "../../../modules/Company/Requests";
import { getBankDetailsByPartyId } from "../Requests";
import { useForm } from "antd/lib/form/Form";
const { Text } = Typography;
const ManageBankDetailsForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageBankDetailsModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // get company details
  const {
    isLoading,
    data: bankDetails,
    error,
    isError,
  } = useQuery(
    ["party", props.id],
    async () => getBankDetailsByPartyId(props.id),
    {
      refetchOnWindowFocus: false,
      // enabled: false,
    }
  );
  const ManageBankDetailsMutation = useMutation(
    (values: any) =>
      bankDetails.length > 0
        ? request("admin.bankDetails.update", { id: props.id }, values)
        : request("admin.bankDetails.add", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("bankDetails");
      },
    }
  );
  const mutateIFSC = useMutation((id: string) => getBankByIFSC(id));
  const [bankInfo, setBankInfo] = React.useState<[Object] | any>([]);
  const [form] = Form.useForm();
  const onIFSCSearch = async (id: string) => {
    mutateIFSC
      .mutateAsync(id)
      .then((payload: any) => {
        console.log(payload);
        setBankInfo(payload);
        form.setFieldsValue({ bankName: payload.BANK });
        form.setFieldsValue({ branch: payload.BRANCH });
      })
      .catch((e: any) => {
        console.log("error", e);
      });
    // console.log(bankInfo, "bk");

    // const bankName = bankInfo?.BANK;
    // const branch = bankInfo?.BRANCH;

    // form.setFieldsValue({ bankName: bankName });
    // form.setFieldsValue({ branch: branch });
  };

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    setFormLoading(true);

    values.partyId = props.id;
    ManageBankDetailsMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The bankDetails has been successfully Managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={bankDetails[0]}
    >
      <Card title="Bank Details">
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item
              label="Account Number"
              style={{ fontWeight: "bold" }}
              name="accNo"
              tooltip={{
                title: "accNo",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Input addonBefore={<AccountBookOutlined />} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Account Holder Name"
              style={{ fontWeight: "bold" }}
              name="accHolderName"
              tooltip={{
                title: "accHolderName",
                icon: <InfoCircleOutlined />,
              }}
              //rules={[{ required: true }]}
            >
              <Input addonBefore={<UserOutlined />} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="UPI ID"
              style={{ fontWeight: "bold" }}
              name="upiId"
              tooltip={{
                title: "upiId",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Input addonBefore={<IdcardOutlined />} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={8}>
            <Form.Item
              label="IFSC Code"
              style={{ fontWeight: "bold" }}
              name="ifscCode"
              tooltip={{
                title: "ifscCode",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Input.Search
                onSearch={onIFSCSearch}
                addonBefore={<CodepenOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Bank Name"
              style={{ fontWeight: "bold" }}
              name="bankName"
              tooltip={{
                title: "bankName",
                icon: <InfoCircleOutlined />,
              }}
              //rules={[{ required: true }]}
            >
              <Input addonBefore={<BankOutlined />} disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Branch"
              style={{ fontWeight: "bold" }}
              name="branch"
              tooltip={{
                title: "branch",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Input addonBefore={<BranchesOutlined />} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update BankDetails
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageBankDetailsForm;
