import { TRoute } from "../../utils/routes";
import DocumentPageHome from "./Page/Home";

const DocumentRouter: TRoute[] = [
  {
    path: "/document",
    name: "document",
    title: "document",
    component: DocumentPageHome,
  },
];

export default DocumentRouter;
