import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const PaymentOutRouter: TRoute[] = [
  {
    path: "/paymentOut",
    name: "paymentOut",
    title: "paymentOut",
    component: DepartmentPageHome,
  },
];

export default PaymentOutRouter;
