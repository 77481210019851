import { request } from "../../../utils/services";

export const getAllActiveSubMenu = async () => {
  const { data } = await request("admin.submenu.list.active");
  return data;
};

export const getAllSubMenu = async () => {
  const { data } = await request("admin.submenu.list");
  return data;
};

export const getSubMenuById = async (id: string) => {
  const { data } = await request("admin.submenu.id", { id });
  return data;
};

export const getSubMenuByCategoryId = async (id: string) => {
  const { data } = await request("admin.submenu.category.id", { id });
  return data;
};
export const getActiveSubMenuByCategoryId = async (id: string) => {
  const { data } = await request("admin.active.submenu.category.id", { id });
  return data;
};
export const getSubMenuByServiceId = async (id: string) => {
  const { data } = await request("admin.submenu.service.id", { id });
  return data;
};
export const getActiveSubMenuByServiceId = async (id: string) => {
  const { data } = await request("admin.active.submenu.service.id", { id });
  return data;
};
