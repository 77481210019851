import * as React from "react";
import {
  Descriptions,
  Card,
  Divider,
  Row,
  Tag,
  Col,
  Typography,
  Space,
  Button,
  Avatar,
  Modal,
  message,
  Popover,
  Image,
  Tooltip,
} from "antd";
import {
  ProfileOutlined,
  PhoneOutlined,
  HomeOutlined,
  GlobalOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useQuery } from "react-query";
import { getLeadById, getLeadByIdDetail } from "../Requests";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import FollowUpListByLead from "../Home/FollowupListByLeadId";
import Meta from "antd/es/card/Meta";
import ShowPersonnelDetailsForm from "../../HR/Personnel/Form/ShowPersonnelDetailsForm";
import { s3Path } from "../../../utils/s3Config/s3upload";
import PartyCard from "../../Party/Component/Home/PartyCard";
import { imLogoIcon } from "../../../constants/logo";
const { Text, Title } = Typography;

const ShowLeadDetailsForm: React.FC<{ id: string }> = (props) => {
  const [view, setView] = React.useState(false);
  const [id, setId] = React.useState("");
  const { data: lead } = useQuery(
    ["lead", props.id],
    async () => getLeadByIdDetail(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );

  return (
    <>
      <Divider dashed orientation="center">
        <Tag>WWC/Leads/{lead[0]?.seq}</Tag> Detail of {lead[0]?.title}
      </Divider>
      <Card
        title={
          <Row justify={"space-between"}>
            <Col>
              {"Enquiry Date: " +
                moment(lead[0]?.enquiryDate).format("DD-MMM-YYYY") +
                "(" +
                lead[0]?.leadStatus +
                ")"}
            </Col>
            {lead[0]?.assignedPersonId && (
              <Col>
                Assigned To:
                <Avatar
                  src={
                    <img
                      src={
                        lead[0]?.assignedPersonId[0]?.partyId[0]?.imageUrl
                          ? s3Path +
                            lead[0]?.assignedPersonId[0]?.partyId[0]?.imageUrl
                          : imLogoIcon
                      }
                      alt={lead[0]?.assignedPersonId[0]?.partyId[0]?.personName}
                    />
                  }
                />
                <Tag>
                  {lead[0]?.assignedPersonId[0]?.partyId[0]?.personName}
                </Tag>
              </Col>
            )}
          </Row>
        }
      >
        <PartyCard data={lead[0].partyId[0]} />

        <Card title={"Services"}>
          {lead[0]?.services?.map((item: any, i: number) => (
            <>
              <Tag color="blue" style={{ margin: 2 }}>
                {i + 1 + ". " + item}
              </Tag>
            </>
          ))}
        </Card>
        <Card title={"Remarks"}>{lead[0]?.remarks}</Card>
      </Card>
    </>
  );
};

export default ShowLeadDetailsForm;
