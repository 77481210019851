import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Empty, Space, Table, Tag, Image } from "antd";

import { getAllDocument } from "../Requests";
import DocumentViewModal from "../Modal/ShowDocumentDetails";
import ManageDocumentModal from "../Modal/ManageDocument";
import { getAccessAuthrorization } from "../../../components/access";
import { s3Path } from "../../../utils/s3Config/s3upload";
import CreateSubMenuModal from "../../SubMenu/Modal/CreateSubMenu";
import SubMenuViewModal from "../../SubMenu/Modal/ShowSubMenuDetails";
// import CreateServiceMenu from "../../ServiceMenu/Form/CreateServiceMenuForm";
// import CreateServiceMenuModal from "../../ServiceMenu/Modal/CreateServiceMenu";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const DocumentList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("document");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["document"], () => getAllDocument(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Parent",
      dataIndex: "parentId",
      key: "parentId",
      render: (text: any) => <p>{text?.title}</p>,
    },
    {
      title: "SubMenu",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <DocumentViewModal id={id} /> */}
          {/* <CreateSubMenuModal id={id} /> */}
          <SubMenuViewModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageDocumentModal id={id} />} */}
        </Space>
      ),
    },
    {
      title: "Custom Url",
      dataIndex: "customUrl",
      key: "customUrl",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <DocumentViewModal id={id} /> */}
          {/* <ManageDocumentModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageDocumentModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default DocumentList;
