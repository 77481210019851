import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Radio,
  InputNumber,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreateServiceModalContext } from "../Modal/CreateService";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "../../../utils/keys";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import ReactWebEditor, {
  StyleEditorBlock,
  TextEditorBlock,
} from "react-web-editor";
import { getCategoryByType } from "../../Category/Requests";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// // import { FullPage } from "@ckeditor/ckeditor5-html-support";
// import { Essentials } from "@ckeditor/ckeditor5-essentials";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";
// import { StyledEditorBlock } from "react-web-editor";
const { Text } = Typography;
const CreateService: React.FC = () => {
  const modalContext = React.useContext(CreateServiceModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const [editorkey, seteditorkey] = React.useState(4);

  const createServiceMutation = useMutation(
    (values: any) => request("admin.service.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("service");
      },
    }
  );
  const { data: category } = useQuery(
    ["category", "Service"],
    async () => getCategoryByType("Service"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.description = remarks;
    values.fileUrl = galley;

    setFormLoading(true);

    createServiceMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Service has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Please Enter Title"
              onChange={(e) =>
                form.setFieldsValue({
                  customUrl: e.target.value.toLowerCase().replace(/ /g, "-"),
                })
              }
              // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="Custom Url"
            name="customUrl"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "customUrl",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter customUrl",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Custom Url" />
          </Form.Item>

          <Form.Item
            label="Service Category"
            name="serviceCategoryId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "serviceCategoryId",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please select service Category",
              },
            ]}
            hasFeedback
          >
            <Select placeholder="Select Service Category">
              {category &&
                category?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Row gutter={20} justify={"space-between"}>
            <Form.Item
              label="Price"
              name="price"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "icon",
                icon: <InfoCircleOutlined />,
              }}
              // rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <InputNumber addonBefore="₹" placeholder="Enter Service Charge" />
            </Form.Item>
            <Form.Item
              label="Discounted Price"
              name="discountPrice"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "icon",
                icon: <InfoCircleOutlined />,
              }}
              // rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <InputNumber
                addonBefore="₹"
                placeholder="Enter Discounted Price"
              />
            </Form.Item>
          </Row>
          <Row gutter={20}>
            <Col flex={2} span={12}>
              <Form.Item
                label="Upload Image"
                style={{ fontWeight: "bold" }}
                name="fileUrl"
                valuePropName="checked"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Upload Image",
                //   },
                // ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  accept="image/png, image/jpeg, image/jpg"
                  action={async (file) => {
                    const response: any = await uploadFileToAWS(
                      file,
                      "service"
                    );
                    setGallery(response.key);
                    return response.key;
                  }}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col flex={2} span={12}>
              <Form.Item
                label="Status"
                style={{ fontWeight: "bold" }}
                name="isActive"
                tooltip={{
                  title: "status",
                  icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
                // rules={[{ required: true }]}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  defaultChecked
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <Editor
            //initialValue={service.description}
            apiKey={tinyMCE.apiKey}
            // onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              plugins: tinyMCE.plugins,
              toolbar: tinyMCE.toolbar,
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              // mergetags_list: [
              //   { value: "First.Name", title: "First Name" },
              //   { value: "Email", title: "Email" },
              // ],

              paste_data_images: true,

              ai_request: (request: any, respondWith: any) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
            // initialValue="Welcome to TinyMCE!"
            onEditorChange={handleEditorChange}
            // outputFormat="html"
          />
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateService;
