import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Button,
  Descriptions,
  message,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";

import { getAccessAuthrorization } from "../../../components/access";
// import { getFollowupByLeadId } from "src/modules/Leads/Requests";
import ManageCredentialModal from "../../Credential/Modal/ManageCredential";
import { getCredentialByPartyId } from "../../Credential/Requests";
import PwdTag from "../../../components/pwdTag";
import useCopyToClipboard from "../../../components/copytoClipboard";
import { CopyOutlined } from "@ant-design/icons";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const CredentialListByPartyId: React.FC<{ id: string | any }> = (props) => {
  let accessAuth = getAccessAuthrorization("credential");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["credential", props.id],
    () => getCredentialByPartyId(props.id),
    {
      refetchOnWindowFocus: false,

      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }

  // React.useEffect(() => { }, []);
  return <CredentialTable data={response} />;
};

export default CredentialListByPartyId;

export const CredentialTable: React.FC<{ data: any }> = ({ data }) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const { Text } = Typography;
  const [copyToClipboard, copyResult] = useCopyToClipboard();
  const handleClickCopy = (text: string) => {
    message.success("Copied Successfully!");

    // Copy the text from the input field into the clipboard
    copyToClipboard(text);
  };
  const column: ColumnsType<DataType> = [
    {
      title: "Platform Name",
      dataIndex: "platformName",
      key: "platformName",
    },
    // {
    //   title: "Platform Link",
    //   dataIndex: "platformLink",
    //   key: "platformLink",
    // },

    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text) => (
        <Tag>
          {text}
          <Button
            type="text"
            icon={<CopyOutlined />}
            onClick={() => handleClickCopy(text)}
          />
        </Tag>
      ),
    },
    {
      title: "Password",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <PwdTag password={i.password} />

        // <Space>
        //   <Tag
        //     color="purple"
        //     icon={
        //       passwordVisible ? (
        //         <EyeTwoTone
        //           onClick={() => setPasswordVisible((prevState) => !prevState)}
        //         />
        //       ) : (
        //         <EyeInvisibleOutlined
        //           onClick={() => setPasswordVisible((prevState) => !prevState)}
        //         />
        //       )
        //     }
        //     key={i.id}
        //   >
        //     Pwd: {passwordVisible ? i.password : "************"}
        //     <Button
        //       type="text"
        //       icon={<CopyOutlined />}
        //       onClick={() => handleClickCopy(i.password)}
        //     />
        //   </Tag>
        // </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CredentialViewModal id={id} /> */}
          <ManageCredentialModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageTechnologyModal id={id} />} */}
        </Space>
      ),
    },
  ];
  return (
    <Table
      rowKey={(record) => record.id}
      size="small"
      columns={column}
      dataSource={data}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Descriptions
              bordered
              layout="horizontal"
              size="small"
              style={{ backgroundColor: "beige" }}
            >
              <Descriptions.Item label="Platform Link" span={3}>
                {record.platformLink}
                <Button
                  type="text"
                  icon={<CopyOutlined />}
                  onClick={() => handleClickCopy(record.platformLink)}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Remarks">
                {record.remarks}
              </Descriptions.Item>
            </Descriptions>
          </>
        ),
        // rowExpandable: (record) => record?.platformName !== "Not Expandable",
      }}
    />
  );
};
