import * as React from "react";
import { Descriptions, Badge } from "antd";
import { useQuery } from "react-query";
import { getUserById } from "../Requests";
const ShowUserDetailsForm: React.FC<{ id: string }> = (props) => {
  const { data: user } = useQuery(
    ["user", props.id],
    async () => getUserById(props.id),
    {
      refetchOnWindowFocus: false,

      staleTime: 5000,
    }
  );
  return (
    <>
      <h1>{user.personName}</h1>

      <Descriptions title="User Info" layout="vertical" bordered>
        <Descriptions.Item label="Classificaiton">
          {user.classification}
        </Descriptions.Item>
        <Descriptions.Item label="User Type">{user.userType}</Descriptions.Item>

        <Descriptions.Item label="Company Name">
          {user.companyName}
        </Descriptions.Item>
        <Descriptions.Item label="Person Name">
          {user.personName}
        </Descriptions.Item>
        <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
        <Descriptions.Item label="Phone No">{user.phoneNo}</Descriptions.Item>
        <Descriptions.Item label="GST No.">{user.gstInNo}</Descriptions.Item>
        <Descriptions.Item label="Website">{user.website}</Descriptions.Item>
        <Descriptions.Item label="Status">
          <>
            {user?.isActive ? (
              <Badge status="processing" text="Active" />
            ) : (
              <Badge status="error" text="Inactive" />
            )}
          </>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ShowUserDetailsForm;
