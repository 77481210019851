import { path } from "./path";

export const purchaseInvoiceRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createPurchaseInvoice",
    module: "purchaseInvoice",
    alias: "admin.purchaseInvoice.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updatePurchaseInvoice/:id",
    module: "purchaseInvoice",
    alias: "admin.purchaseInvoice.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActivePurchaseInvoice",
    module: "purchaseInvoice",
    alias: "admin.purchaseInvoice.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllPurchaseInvoice",
    module: "purchaseInvoice",
    alias: "admin.purchaseInvoice.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOnePurchaseInvoice/:id",
    module: "purchaseInvoice",
    alias: "admin.purchaseInvoice.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOnePurchaseInvoiceWithDetail/:id",
    module: "purchaseInvoice",
    alias: "admin.purchaseInvoice.id.with.detail",
    isSubDomain: true,
  },
 
];
