import { path } from "./path";

export const credentialRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createCredential",
    module: "credential",
    alias: "admin.credential.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateCredential/:id",
    module: "credential",
    alias: "admin.credential.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveCredential",
    module: "credential",
    alias: "admin.credential.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllCredential",
    module: "credential",
    alias: "admin.credential.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneCredential/:id",
    module: "credential",
    alias: "admin.credential.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneCredentialWithDetail/:id",
    module: "credential",
    alias: "admin.credential.id.detail",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findCredentialByPartyId/:id",
    module: "credential",
    alias: "admin.credential.by.party",
    isSubDomain: true,
  },
];
