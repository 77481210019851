import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Typography } from "antd";

import { getAllEnquiry } from "../Requests";
import EnquiryViewModal from "../Modal/ShowEnquiryDetails";
import ManageEnquiryModal from "../Modal/ManageEnquiry";
import { getAccessAuthrorization } from "../../../components/access";
import CreateLeadsByEnquiryForm from "../../Leads/Form/CreateLeadsByEnquiryForm";
import CreateLeadsByEnquiryModal from "../../Leads/Modal/CreateLeadByEnquiry";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const EnquiryList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("enquiry");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["enquiry"], () => getAllEnquiry(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Enquiry No.",
      dataIndex: "seq",
      key: "seq",
      render: (_: any, i: any) => (
        <Typography.Text>{i.seq ? "WWC/ENQ/" + i.seq : ""}</Typography.Text>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: any, i: any) => (
        <Space direction="vertical">
          <Typography.Text>{i?.name}</Typography.Text>
          <Typography.Text>{i?.company}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "Contants",
      dataIndex: "company",
      key: "company",
      render: (_: any, i: any) => (
        <Space direction="vertical">
          <Typography.Text>{i?.mobile}</Typography.Text>
          <Typography.Text>{i?.email}</Typography.Text>
        </Space>
      ),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_: any, data: any) => (
        <Space size={10}>
          <CreateLeadsByEnquiryModal data={data} />
          {/* <EnquiryViewModal id={id} /> */}
          {/* <ManageEnquiryModal id={id} /> */}
          {/* {accessAuth[0].includes("Edit") && <ManageEnquiryModal id={id} />} */}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default EnquiryList;
