import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Empty, Space, Table, Tag, Image, Typography } from "antd";

import { getAllProduct } from "../Requests";
import ProductViewModal from "../Modal/ShowProductDetails";
import ManageProductModal from "../Modal/ManageProduct";
import { getAccessAuthrorization } from "../../../components/access";
import ManageCategoryModal from "../../Category/Modal/ManageCategory";
import SubMenuViewModal from "../../SubMenu/Modal/ShowSubMenuDetails";
import { s3Path } from "../../../utils/s3Config/s3upload";


interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const ProductList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("product");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["product"], () => getAllProduct(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "50%",
      render: (_: any, i: any) => (
        <Space direction="vertical">
          <Typography.Text>{i?.title}</Typography.Text>
          <Typography.Text code>Standard: {i?.standardId?.ISNumber}{i?.standardId?.ISName}</Typography.Text>
        </Space>
      ),
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text: any) => <Tag color="gold">{text}</Tag>,
    // },
    {
      title: "Category",
      dataIndex: "productCategoryId",
      key: "productCategoryId",
      render: (text: any) => <p>{text?.title}</p>,
    },
    // {
    //   title: "SubMenu",
    //   dataIndex: "id",
    //   key: "id",
    //   render: (id: any) => (
    //     <Space size={10}>
    //       {/* <CategoryViewModal id={id} /> */}
    //       {/* <CreateSubMenuModal id={id} /> */}
    //       <SubMenuViewModal id={id} />
    //       {/* {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />} */}
    //     </Space>
    //   ),
    // },
    // {
    //   title: "Custom Url",
    //   dataIndex: "customUrl",
    //   key: "customUrl",
    // },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CategoryViewModal id={id} /> */}
          {/* <ManageCategoryModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageProductModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default ProductList;
