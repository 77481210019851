import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  Popover,
  Row,
  Space,
  Statistic,
  Tabs,
  Typography,
} from "antd";
import * as React from "react";
import { useQuery } from "react-query";
import {
  GlobalOutlined,
  
  AndroidOutlined,

  InfoCircleOutlined,
} from "@ant-design/icons";

import { s3Path } from "../../../utils/s3Config/s3upload";
import ShowWorkAdminDetailsForm from "../../Work/Form/ShowWorkAdminDetailsForm";
import { getAnalytics } from "../Requests";

const WorkStats: React.FC = () => {
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["analytics"], () => getAnalytics(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
};

  // console.log(response);
  return (
    <>
      <Card title="Work Stat" hoverable>
       
        {/* <Card.Grid style={gridStyle} title="New">
            <Badge count={response[0]?.workstats?.new} color="green">
          New
        </Badge>
          <Space.Compact direction="vertical">
         <Typography.Text style={{fontWeight:"lighter"}}> New <Typography.Text code>{response[0].workstats.new}</Typography.Text> </Typography.Text>
          <Typography.Text code>{response[0].workstats.new}</Typography.Text>
        </Space.Compact>
        </Card.Grid>
    <Card.Grid style={gridStyle}>
          <Badge count={response[0].workstats.inProgress === 0 ? 0 : response[0].workstats.inProgress} color="blue">
          In Progress
        </Badge>
    </Card.Grid>
    <Card.Grid style={gridStyle}>Content</Card.Grid>
    <Card.Grid style={gridStyle}>Content</Card.Grid>
    <Card.Grid style={gridStyle}>Content</Card.Grid>
    <Card.Grid style={gridStyle}>Content</Card.Grid>
    <Card.Grid style={gridStyle}>Content</Card.Grid> */}
  
      <Row gutter={12}>
         
    <Col span={4}>  <Statistic title="New" value={response[0].workstats.new} /></Col>
       <Col span={4}>  <Statistic title="In Progress" value={response[0].workstats.inProgress} /></Col>
     <Col span={4}>  <Statistic title="On Hold" value={response[0].workstats.onHold} /></Col>
      <Col span={4}> <Statistic title="In Testing" value={response[0].workstats.inTesting} /></Col>
      <Col span={4}> <Statistic title="Testing Hold" value={response[0].workstats.testingHold} /></Col>
      <Col span={4}> <Statistic title="Testing Done" value={response[0].workstats.testingDone} /></Col>
      <Col span={4}> <Statistic title="Application Preparation" value={response[0].workstats.applicationPreparation} /></Col>
      <Col span={4}> <Statistic title="Application Submitted" value={response[0].workstats.applicationSubmitted} /></Col>
     <Col span={4}>  <Statistic title="Queried" value={response[0].workstats.queried} /></Col>
     <Col span={4}>  <Statistic title="Queried Resolved" value={response[0].workstats.queriedResolved} /></Col>
     <Col span={4}>  <Statistic title="Approval Granted" value={response[0].workstats.approvalGranted} /></Col>
     <Col span={4}>  <Statistic title="Approval Cancelled" value={response[0].workstats.approvalCancelled} /></Col>
      <Col span={4}> <Statistic title="Completed" value={response[0].workstats.completed} /></Col>
      <Col span={4}> <Statistic title="Ticket Open" value={response[0].workstats.ticketOpen} /></Col>
      <Col span={4}> <Statistic title="Ticket Closed" value={response[0].workstats.ticketClosed} /></Col>
<Col span={4}> <Statistic title="Total Work" value={response[0].workstats.totalWork} /></Col>
    
        </Row>
      </Card>
      <Card title="Lead Stat" style={{marginTop:3}} hoverable>
        <Row gutter={12}>
    <Col span={4}>  <Statistic title="New" value={response[1].leadStats.new} /></Col>
    <Col span={4}>  <Statistic title="Interested" value={response[1].leadStats.interested} /></Col>
    <Col span={4}>  <Statistic title="Quoted" value={response[1].leadStats.quoted} /></Col>
    <Col span={4}>  <Statistic title="Open" value={response[1].leadStats.open} /></Col>
    <Col span={4}>  <Statistic title="Converted" value={response[1].leadStats.converted} /></Col>
    <Col span={4}>  <Statistic title="Cancelled" value={response[1].leadStats.cancelled} /></Col>
    <Col span={4}>  <Statistic title="On Hold" value={response[1].leadStats.onHold} /></Col>
    <Col span={4}>  <Statistic title="Not Interested" value={response[1].leadStats.notInterested} /></Col>
    <Col span={4}>  <Statistic title="Total Lead" value={response[1].leadStats.totalLead} /></Col>

        </Row>
      </Card>
    </>
  );
};
export default WorkStats;

