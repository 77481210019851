import { Empty, Row } from "antd";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import AccessList from "../Component/Home/List";
import CreateAccessModal from "../Modal/CreateAccess";
import { getAccessAuthrorization } from "../../../components/access";

const AccessPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("access");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Access List</h2>
              <CreateAccessModal />
              {/* {accessAuth[0].includes("Create") && <CreateAccessModal />} */}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <AccessList />
          {/* {accessAuth[0].includes("View") ? (
            <AccessList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            ></Empty>
          )} */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(AccessPageHome);
