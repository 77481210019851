import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../../../src";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreateGalleryModalContext } from "../Modal/CreateGallery";

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const { Text } = Typography;
const CreateGallery: React.FC = () => {
  const modalContext = React.useContext(CreateGalleryModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const createGalleryMutation = useMutation(
    (values: any) => request("admin.gallery.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("gallery");
      },
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    setFormLoading(true);

    createGalleryMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Gallery has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={20}>
        <Col flex={5} span={12}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
        <Col flex={5} span={12}>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= 8 ? null : uploadButton}
          </Upload>
        </Col>
      </Row>

      <Form.Item
        label="Description"
        name="description"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Description",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter your Description",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea />
      </Form.Item>
      <Divider />

      <h3>For SEO Purpose</h3>
      <Divider />
      <Row gutter={15}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Meta Title"
            name="metaTitle"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Meta Title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Meta Keyword"
            name="metaKeyword"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Meta Keyword",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Meta Keyword",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Meta Discription"
        name="metaDiscription"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Meta Discription",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter Meta Discription",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea />
      </Form.Item>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
            // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateGallery;
