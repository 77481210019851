import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Checkbox,
  Col,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageUserModalContext } from "../Modal/ManageUser";
import { useQuery } from "react-query";
import { getUserById } from "../Requests";
import LocalStorageService from "../../../utils/local-storage";

const ManageUserForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageUserModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const ManageUserMutation = useMutation(
    (values: any) => request("admin.user.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: res,
  } = useQuery(["user", props.id], async () => getUserById(props.id), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    setFormLoading(true);

    ManageUserMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The user has been successfully Managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  // const options = [
  //   { label: "Vendor", value: "Vendor" },
  //   { label: "Client", value: "Client" },
  //   { label: "Lead Provider", value: "Lead Provider" },
  //   { label: "Partner", value: "Partner" },
  //   { label: "Inhouse Employee", value: "Inhouse Employee" },
  //   { label: "Outsource Employee", value: "Outsource Employee" },
  // ];

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={res}
    >
      <Form.Item label="Type" style={{ fontWeight: "bold" }} name="userType">
        <Row gutter={20}>
          <Col flex={1}>
            <Form.Item
              label="First Name"
              style={{ fontWeight: "bold" }}
              name="firstname"
              tooltip={{
                title: "firstname",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label="Last Name"
              name="lastname"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "lastname",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex={5} span={12}>
            <Form.Item
              label="User Name"
              name="username"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "username",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter user name",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex={4} span={12}>
            <Form.Item
              label="Password"
              name="password"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "password",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                { required: true, message: "Please Enter your password!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col flex={4} span={18}>
            <Form.Item
              label="Email"
              style={{ fontWeight: "bold" }}
              name="email"
              tooltip={{
                title: "email",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col flex={4} span={6}>
            <Form.Item
              label="Roles"
              style={{ fontWeight: "bold" }}
              name="roles"
              tooltip={{
                title: "roles",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ required: true }]}
            >
              <Select />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageUserForm;
