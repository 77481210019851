import _ from "lodash";

import { authRoutes } from "./routes/auth-route";

import { profileRoutes } from "./routes/profile-route";

import { rolesRoutes } from "./routes/roles-route";
import { partyRoutes } from "./routes/party-route";

import { leadRoutes } from "./routes/lead-route";
import { blogCategoriesRoutes } from "./routes/blog-categories-route";
import { blogRoutes } from "./routes/blog-route";
import { galleryRoutes } from "./routes/gallery-route";
import { testimonialRoutes } from "./routes/testimonial-route";
import { reportRoutes } from "./routes/task-route";
import { departmentRoutes } from "./routes/department-route";
import { localRoutes } from "./routes/locality-route";
import { designationRoutes } from "./routes/designation-route";
import { personnelRoutes } from "./routes/personnel-route";
import { collectionRoutes } from "./routes/collection-route";
import { accessRoutes } from "./routes/access-route";
import { userRoutes } from "./routes/user-routes";

import { workRoutes } from "./routes/work-route";

import { credentialRoutes } from "./routes/credential-route";

import { replyRoutes } from "./routes/reply-route";

import { notificationRoutes } from "./routes/notification-route";
import { categoryRoutes } from "./routes/category-route";
import { serviceRoutes } from "./routes/service-route";
import { submenuRoutes } from "./routes/submenu-route";
import { enquiryRoutes } from "./routes/enquiry-route";
import { documentRoutes } from "./routes/document-route";
import { taxInvoiceRoutes } from "./routes/taxInvoice-route";
import { performaInvoiceRoutes } from "./routes/performaInvoice-route";
import { quotationRoutes } from "./routes/quotation-route";
import { purchaseOrderRoutes } from "./routes/PurchaseOrder-route";
import { paymentRoutes } from "./routes/payment-route";
import { ledgerRoutes } from "./routes/ledger-route";
import { taskTemplateRoutes } from "./routes/taskTemplate-route";
import { proposalRoutes } from "./routes/proposal-route";
import { standardRoutes } from "./routes/standard-route";
import { productRoutes } from "./routes/product-route";
import PurchaseInvoiceList from "../modules/Purchase Invoice/Component/List";
import { purchaseInvoiceRoutes } from "./routes/PurchaseInvoice-route";
//const path = "https://api.patnarepair.com:8089";
//const path = "https://api.techabilititsolutions.com:8080";
const routes = {
  /**
   * @property {array} Array of all available routes
   */
  collection: [
    ...authRoutes,

    ...profileRoutes,
    ...partyRoutes,
    ...rolesRoutes,

    ...leadRoutes,
    ...blogCategoriesRoutes,
    ...blogRoutes,
    ...galleryRoutes,
    ...testimonialRoutes,
    ...reportRoutes,
    ...departmentRoutes,
    ...localRoutes,
    ...designationRoutes,
    ...personnelRoutes,
    ...collectionRoutes,
    ...accessRoutes,
    ...userRoutes,
    ...purchaseInvoiceRoutes,
    ...workRoutes,

    ...credentialRoutes,
    ...replyRoutes,

    ...notificationRoutes,
    ...categoryRoutes,
    ...serviceRoutes,
    ...submenuRoutes,
    ...enquiryRoutes,
    ...documentRoutes,
    ...taxInvoiceRoutes,
    ...performaInvoiceRoutes,
    ...quotationRoutes,
    ...purchaseOrderRoutes,
    ...paymentRoutes,
    ...ledgerRoutes,

    ...proposalRoutes,
    ...taskTemplateRoutes,
    ...standardRoutes,
    ...productRoutes,
  ],

  /**
   * Method used to generate route path from alias.
   *
   * @param {string} alias
   * @param {object} parameters
   */
  route: (alias: string, parameters: any = {}): any => {
    const routeCollection = _.cloneDeep(routes.collection);
    let route = _.find(routeCollection, { alias });
    if (route === undefined) {
      throw new Error(`Can't find route for alias: ${alias}`);
    }

    if (parameters) {
      for (const param in parameters) {
        if (!route.path.includes(param)) {
          throw new Error(
            `Route parameter: ${param} is not allowed in route path: ${route.path}`
          );
        }

        if (
          typeof parameters === "object" &&
          parameters.hasOwnProperty(param)
        ) {
          route.path = route.path.replace(`:${param}`, parameters[param]);
        }
      }
    }

    return route;
  },
};

export default routes;
