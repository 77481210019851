import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const PaymentInRouter: TRoute[] = [
  {
    path: "/paymentIn",
    name: "paymentIn",
    title: "paymentIn",
    component: DepartmentPageHome,
  },
];

export default PaymentInRouter;
