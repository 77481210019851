import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const TaxInvoiceRouter: TRoute[] = [
  {
    path: "/taxInvoice",
    name: "taxInvoice",
    title: "taxInvoice",
    component: DepartmentPageHome,
  },
];

export default TaxInvoiceRouter;
