import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";
import CreateWorkModal from "../Modal/CreateWork";
import WorkList from "../Component/List";
import { getAccessAuthrorization } from "../../../components/access";

const WorkPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("work");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Work List</h2>
              {/* <CreateWorkModal /> */}
              {accessAuth[0].includes("Create") && <CreateWorkModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <WorkList /> */}
          {accessAuth[0].includes("View") ? (
            <WorkList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(WorkPageHome);
