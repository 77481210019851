import * as React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Switch,
  Select,
  Col,
  Row,
  Checkbox,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreateUserModalContext } from "../Modal/CreateUser";
import LocalStorageService from "../../../utils/local-storage";
import { getAllRoles } from "../../../modules/Roles/Requests";
const CreateUserForm: React.FC<{ id?: string }> = (props) => {
  const modalContext = React.useContext(CreateUserModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createUserMutation = useMutation(
    (values: any) => request("admin.user.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("user");
      },
    }
  );
  const {
    //isLoading,
    //isError,
    data: roles,
  } = useQuery(["roles"], () => getAllRoles(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;
    values.partyId = props.id;
    createUserMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The user has been successfully created.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Row gutter={20}>
        <Col flex={1}>
          <Form.Item
            label="Name"
            style={{ fontWeight: "bold" }}
            name="name"
            tooltip={{
              title: "firstname",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            label="Mobile"
            name="mobile"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "lastname",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={5} span={12}>
          <Form.Item
            label="User Name"
            name="username"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "username",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter user name",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex={4} span={12}>
          <Form.Item
            label="Password"
            name="password"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "password",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true, message: "Please Enter your password!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={4} span={18}>
          <Form.Item
            label="Email"
            style={{ fontWeight: "bold" }}
            name="email"
            tooltip={{
              title: "email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col flex={4} span={6}>
          <Form.Item
            label="Roles"
            style={{ fontWeight: "bold" }}
            name="roles"
            tooltip={{
              title: "roles",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              //onChange={getSubCategory}
              showSearch
              placeholder="Select Category"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare(
                    (optionB!.children as unknown as string).toLowerCase()
                  )
              }
            >
              {roles &&
                roles?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateUserForm;
