import * as React from "react";

import { Modal, Button, Tooltip } from "antd";

import { EyeOutlined ,FolderViewOutlined } from "@ant-design/icons";
import ShowDocumentDetailsForm from "../Form/ShowDocumentForm";
import { s3Path } from "../../../utils/s3Config/s3upload";
export const ShowDocumentModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const DocumentViewModal: React.FC<{ path: string }> = ({ path }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ShowDocumentModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="View All Details">
        <Button
          onClick={showModal}
         size="large"
          shape="circle"
          type="link"
          icon={<FolderViewOutlined style={{fontSize:25}} />}
        />
      </Tooltip>
      <Modal
        width={1000}
        title=" View Document"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
   <embed src={s3Path+path} width="750vw" height="650vh" />

        {/* <ShowDocumentDetailsForm id={id} /> */}
      </Modal>
    </ShowDocumentModalContext.Provider>
  );
};

export default DocumentViewModal;
