import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import {
  Empty,
  Space,
  Table,
  Tag,
  Image,
  Row,
  Button,
  Col,
  Typography,
} from "antd";

import { getAllDocument, getDocumentByPartyId } from "../Requests";
import DocumentViewModal from "../Modal/ShowDocumentDetails";
import ManageDocumentModal from "../Modal/ManageDocument";
import { getAccessAuthrorization } from "../../../components/access";
import { s3Path } from "../../../utils/s3Config/s3upload";
import CreateSubMenuModal from "../../SubMenu/Modal/CreateSubMenu";
import SubMenuViewModal from "../../SubMenu/Modal/ShowSubMenuDetails";
// import CreateServiceMenu from "../../ServiceMenu/Form/CreateServiceMenuForm";
// import CreateServiceMenuModal from "../../ServiceMenu/Modal/CreateServiceMenu";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const DocumentListByParty: React.FC<{ id: string }> = (props) => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [fileDisplay, setFileDisplay] = React.useState<any>();
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("document");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["documentByParty", props.id],
    () => getDocumentByPartyId(props.id),
    {
      refetchOnWindowFocus: false,

      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const readFile = async (file: any) => {
    let blob = await fetch(s3Path + file).then((r) => r.blob());
    // blob.name = file;
    setFileDisplay(window.URL.createObjectURL(blob));
    return window.URL.createObjectURL(blob);
  };

  const column: ColumnsType<DataType> = [
    {
      title: "Document",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) => {
        return text;
        // let file = readFile(s3Path + text);

        // console.log(file);
        // return file && <object data={s3Path + file} width="300" height="200" />;
      },

      // text.split(".").pop() === "jpg" || "png" ? (
      //   <Image width={100} height={100} src={s3Path + text} />
      // ) : (
      //   <object data={s3Path + text} width="300" height="200" />
      // ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_: any, text: any) => {
        return (
          <>
            <Typography.Text italic>
              {"Upload on " + moment(text.createdAt).format("DD-MMM-YYYY")}
            </Typography.Text>
            {text.leadId && (
              <Typography.Text italic>
                {" in "} <Tag>{"WWC/Leads/" + text.leadId.seq} </Tag>
              </Typography.Text>
            )}
            {text.workId && (
              <Typography.Text italic>
                {" in "} <Tag>{"WWC/Works/" + text.workId.seq} </Tag>
              </Typography.Text>
            )}
          </>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (_: any, i: any) => (
        <Space size={10}>
          {/* <DocumentViewModal id={id} /> */}
          {/* <ManageDocumentModal id={id} /> */}
          {accessAuth[0]?.includes("Edit") && <ManageDocumentModal id={i.id} />}
          <Button onClick={() => setFileDisplay(s3Path + i.fileUrl)}>
            View
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Row justify={"space-between"}>
      <Col span={12}>
        <Table size="small" columns={column} dataSource={response} />
      </Col>
      <Col span={12}>
        {" "}
        {fileDisplay &&
        fileDisplay.split(".").pop() !== "pdf" &&
        fileDisplay.split(".").pop() !== "PDF" ? (
          <Image src={fileDisplay} />
        ) : (
          <Row>
            {/* <object
            data={fileDisplay}
            width="500"
            height="750"
            // type="application/pdf"
          >
            File Load Fails!
          </object> */}
            {/* <iframe
            title="rt"
            height="375"
            width="600"
            src={fileDisplay}
          ></iframe> */}
            <embed src={fileDisplay} width="750vw" height="650vh" />
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default DocumentListByParty;
