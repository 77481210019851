import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";
import LeadsList from "../Home/List";
import CreateLeadsModal from "../Modal/CreateLeads";
import { Empty, Row } from "antd";
import { getAccessAuthrorization } from "../../../components/access";
import BulkLeadsModal from "../Modal/BulkLeads";
import { isMobile } from "react-device-detect";

const LeadsPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("leads");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between" align={"middle"}>
              <h2>Leads List</h2>
              {/* <CreateLeadsModal /> */}
              {accessAuth[0].includes("Create") && (
                <Row justify={"end"}>
                  {!isMobile && <BulkLeadsModal />}

                  <CreateLeadsModal />
                </Row>
              )}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <LeadsList /> */}
          {accessAuth[0].includes("View") ? (
            <LeadsList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(LeadsPageHome);
