import { request } from "../../../utils/services";

export const getAllActiveRoles = async () => {
  const { data } = await request("admin.roles.list.active");
  return data;
};

export const getAllRoles = async () => {
  const { data } = await request("admin.roles.list");
  return data;
};

export const getRolesById = async (id: string) => {
  const { data } = await request("admin.roles.id", { id });
  return data;
};
