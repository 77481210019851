import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Radio,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreateCategoryModalContext } from "../Modal/CreateCategory";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "../../../utils/keys";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import { getAllCategory } from "../Requests";

const { Text } = Typography;
const CreateCategory: React.FC<{ type?: string; parentId?: string }> = (
  props
) => {
  const modalContext = React.useContext(CreateCategoryModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
  const [parent, setParent] = React.useState<[object] | any>([{}]);
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const [editorkey, seteditorkey] = React.useState(4);

  const createCategoryMutation = useMutation(
    (values: any) => request("admin.category.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("category");
      },
    }
  );
  const { data: category } = useQuery(
    ["category"],
    async () => getAllCategory(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.description = remarks;
    values.fileUrl = galley;
    values.type = props.type;

    setFormLoading(true);

    createCategoryMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Category has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const options = [
    { label: "Service", value: "Service" },
    // { label: "Case Study", value: "Case Study" },
    { label: "Job", value: "Job" },
    { label: "Blog", value: "Blog" },
    { label: "Other", value: "Other" },
  ];
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  // console.log(parent);
  form.setFieldsValue({ parentId: props.parentId });
  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row gutter={20}>
          <Col span={16}>
            {" "}
            {/* <CKEditor
            config={{
              plugins: [Essentials],
            }}
            editor={ClassicEditor}
            data="<p>Hello from CKEditor&nbsp;5!</p>"
            onReady={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event) => {
              console.log(event);
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          /> */}
            {/* <TextEditorBlock
            width={300}
            height={100}
            top={500}
            left={800}
            unit={"rem"}
            parentStyle={{ width: 500, height: 300 }}
          /> */}
            {/* <EmailEditor /> */}
            <Editor
              //initialValue={service.description}
              apiKey={tinyMCE.apiKey}
              // onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                plugins: tinyMCE.plugins,
                toolbar: tinyMCE.toolbar,
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                // mergetags_list: [
                //   { value: "First.Name", title: "First Name" },
                //   { value: "Email", title: "Email" },
                // ],

                paste_data_images: true,

                ai_request: (request: any, respondWith: any) =>
                  respondWith.string(() =>
                    Promise.reject("See docs to implement AI Assistant")
                  ),
              }}
              // initialValue="Welcome to TinyMCE!"
              onEditorChange={handleEditorChange}
              // outputFormat="html"
            />
          </Col>
          <Col span={8}>
            {!props.parentId && (
              <Form.Item
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "Please select type",
                  },
                ]}
                style={{ fontWeight: "bold" }}
                name="type"
              >
                <Radio.Group
                  options={options}
                  // onChange={(e) => {
                  //   setParent(
                  //     category.filter(
                  //       (element: any) => element.type === e.target.value
                  //     )
                  //   );
                  // }}
                />
              </Form.Item>
            )}
            {props.parentId && (
              <Form.Item
                label="Parent"
                name="parentId"
                style={{ fontWeight: "bold" }}
                tooltip={{
                  title: "Parent",
                  icon: <InfoCircleOutlined />,
                }}
                hasFeedback
              >
                <Select placeholder="Select Category" disabled>
                  {category &&
                    category?.map((item: any, i: number) => {
                      return (
                        <Select.Option value={item?.id} key={i}>
                          {item?.title}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="Title"
              name="title"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Title",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  title",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Please Enter Title"
                onChange={(e) =>
                  form.setFieldsValue({
                    customUrl: e.target.value.toLowerCase().replace(/ /g, "-"),
                  })
                }
                // addonBefore={<ToolOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              label="Custom Url"
              name="customUrl"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "customUrl",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter customUrl",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input placeholder="Enter Custom Url" />
            </Form.Item>

            <Form.Item
              label="Icon Name"
              name="icon"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "icon",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <Input placeholder="Enter Icon" />
            </Form.Item>
            <Row gutter={20}>
              <Col flex={2} span={12}>
                <Form.Item
                  label="Upload Image"
                  style={{ fontWeight: "bold" }}
                  name="fileUrl"
                  valuePropName="checked"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please Upload Image",
                  //   },
                  // ]}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    accept="image/png, image/jpeg, image/jpg"
                    action={async (file) => {
                      const response: any = await uploadFileToAWS(
                        file,
                        "service"
                      );
                      setGallery(response.key);
                      return response.key;
                    }}
                    onChange={handleChange}
                  >
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col flex={2} span={12}>
                <Form.Item
                  label="Status"
                  style={{ fontWeight: "bold" }}
                  name="isActive"
                  tooltip={{
                    title: "status",
                    icon: <InfoCircleOutlined />,
                  }}
                  valuePropName="checked"
                  // rules={[{ required: true }]}
                >
                  <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />

            <h3>For SEO Purpose</h3>
            <Divider />

            <Form.Item
              label="Meta Title"
              name="metaTitle"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Meta Title",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <TextArea placeholder="Enter Meta Title" />
            </Form.Item>

            <Form.Item
              label="Meta Keyword"
              name="metaKeywords"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Meta Keyword",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <TextArea placeholder="Enter Meta Keyword " />
            </Form.Item>

            <Form.Item
              label="Meta Description"
              name="metaDescription"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Meta Description",
                icon: <InfoCircleOutlined />,
              }}
              rules={[{ whitespace: true }, { min: 3 }]}
              hasFeedback
            >
              <TextArea placeholder="Enter Description" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            size="large"
            loading={formLoading}
            type="primary"
            htmlType="submit"
            shape="round"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateCategory;
