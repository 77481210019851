import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Radio,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageStandard from "../../../utils/local-storage";
import { CreateStandardModalContext } from "../Modal/CreateStandard";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "../../../utils/keys";

import { getCategoryByType } from "../../Category/Requests";

const CreateStandard: React.FC = () => {
  const modalContext = React.useContext(CreateStandardModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState();
  const [editorkey, seteditorkey] = React.useState(4);

  const createStandardMutation = useMutation(
    (values: any) => request("admin.standard.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("standard");
      },
    }
  );
  const { data: category } = useQuery(
    ["category", "Standard"],
    async () => getCategoryByType("Standard"),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageStandard.getAccount().id;
    values.description = remarks;
    setFormLoading(true);

    createStandardMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Standard has been successfully created.");
        form.resetFields();
        cleareditor();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={20}>
        <Col span={10}>
          <Form.Item
            label="IS Number"
            name="ISNumber"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "ISNumber",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Please Enter Title"
              onChange={(e) =>
                form.setFieldsValue({
                  customUrl: e.target.value
                    .toLowerCase()
                    .replace(/ /g, "_")
                    .replace(/:/g, "_"),
                })
              }
              // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="IS Name"
            name="ISName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "ISName",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Please Enter Title"
              onChange={(e) =>
                form.setFieldsValue({
                  customUrl:
                    form
                      .getFieldsValue()
                      .ISNumber.toLowerCase()
                      .replace(/ /g, "_")
                      .replace(/:/g, "_") +
                    e.target.value
                      .toLowerCase()
                      .replace(/[,.-]/g, "")
                      .replace(/ /g, "-")
                      .replace(/:/g, "_"),
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Custom Url"
            name="customUrl"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "customUrl",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter customUrl",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Custom Url" />
          </Form.Item>

          <Form.Item
            label="Standard Category"
            name="standardCategoryId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "standardCategoryId",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select product Category",
            //   },
            // ]}
            hasFeedback
          >
            <Select placeholder="Select Product Category">
              {category &&
                category?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Scope"
            name="scope"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "scope",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter customUrl",
            //   },
            //   { whitespace: true },
            //   { min: 3 },
            // ]}
            hasFeedback
          >
            <TextArea placeholder="Enter Custom Url" />
          </Form.Item>
          <Form.List
            name="testCovered"
            // rules={[
            //   {
            //     validator: async (_, names) => {
            //       if (!names || names.length < 2) {
            //         return Promise.reject(new Error("At least 2 passengers"));
            //       }
            //     },
            //   },
            // ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Test Covered" : ""}
                    style={{ fontWeight: "bold" }}
                    // required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      // rules={[
                      //   {
                      //     required: true,
                      //     whitespace: true,
                      //     message:
                      //       "Please input passenger's name or delete this field.",
                      //   },
                      // ]}
                      noStyle
                    >
                      <Input
                        placeholder="Add Test Name"
                        style={{ width: "95%", marginLeft: 2 }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                    icon={<PlusOutlined />}
                  >
                    Add Tests
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={14}>
          <h3> Descriptions</h3>
          <Editor
            //initialValue={service.description}
            apiKey={tinyMCE.apiKey}
            // onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              plugins: tinyMCE.plugins,
              toolbar: tinyMCE.toolbar,
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              // mergetags_list: [
              //   { value: "First.Name", title: "First Name" },
              //   { value: "Email", title: "Email" },
              // ],

              paste_data_images: true,

              ai_request: (request: any, respondWith: any) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
            // initialValue="Welcome to TinyMCE!"
            onEditorChange={handleEditorChange}
            // outputFormat="html"
          />
        </Col>
      </Row>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateStandard;
