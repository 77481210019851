import * as React from "react";
import {
  AppOutline,
  UserOutline,
  UnorderedListOutline,
  BellOutline,
} from "antd-mobile-icons";
import { TabBar } from "antd-mobile";
import { isMobile } from "react-device-detect";

import "./Layout.css";
import { Col, Drawer, FloatButton, Layout, Menu, Row, Typography } from "antd";
import Icon, * as AntIcons from "@ant-design/icons";

import NavRouteLink from "../NavRouteLink";

import LocalStorageService from "../../utils/local-storage";

import LogoWhite from "../../assets/img/logoWhite.png";

import ProfileCard from "../ProfileCard";
import DynamicIcon from "../dynamicIcon";
import { useHistory, useLocation } from "react-router-dom";
import Quotation from "../../modules/Quotation/Form/Quotation";
import TodoList from "../../modules/Dashboard/Component/TodoList";
import MobileFollowupList from "../../modules/Dashboard/Component/FollowUpList";

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

const AuthLayout: React.FC<{ children?: React.ReactNode }> = (props) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [taskOpen, setTaskOpen] = React.useState(false);
  const [commentOpen, setCommentOpen] = React.useState(false);
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [collection, setCollection] = React.useState<Object | any>(undefined);
  const [access, setAccess] = React.useState(LocalStorageService.getAccess());
  // console.log(access1, "1");
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  //Important Use of Reduce Start
  const history = useHistory();

  const location = useLocation();
  const { pathname } = location;
  const ArrangeParentChildCollection = (arr: any) => {
    var parentChildArray;
    arr?.reduce((acc: any, val: any, ind: any, array: any) => {
      const childs: any = [];
      array.forEach((el: any, i: any) => {
        if (
          childs.includes(el.collectionId.parentId) ||
          el.collectionId.parentId === val.collectionId._id
        ) {
          childs.push(el);
        }
      });

      parentChildArray = acc.concat({ ...val, childs });

      return acc.concat({ ...val, childs });
    }, []);
    return parentChildArray;
  };

  React.useEffect(() => {
    // Run Arrange as per child stage
    var getChild: any = ArrangeParentChildCollection(access);
    var getChildofChild: any = ArrangeParentChildCollection(getChild);
    setCollection(getChildofChild);
  }, []);
  const { Text } = Typography;
  const setRouteActive = (value: string) => {
    history.push(value);
  };
  const tabs = [
    {
      key: "/",
      title: (
        <Text
          style={{
            color: pathname === "/" ? "black" : "#ffffff",
            fontWeight: pathname === "/" ? "bold" : "normal",
          }}
        >
          Dashboard
        </Text>
      ),
      icon: <AppOutline color={pathname === "/" ? "black" : "#ffffff"} />,
    },
    {
      key: "/party",
      title: (
        <Text
          style={{
            color: pathname === "/party" ? "black" : "#ffffff",
            fontWeight: pathname === "/party" ? "bold" : "normal",
          }}
        >
          Party
        </Text>
      ),
      icon: <UserOutline color={pathname === "/party" ? "black" : "#ffffff"} />,
    },
    {
      key: "/leads",
      title: (
        <Text
          style={{
            color: pathname === "/leads" ? "black" : "#ffffff",
            fontWeight: pathname === "/leads" ? "bold" : "normal",
          }}
        >
          Leads
        </Text>
      ),
      icon: (
        <UnorderedListOutline
          color={pathname === "/leads" ? "black" : "#ffffff"}
        />
      ),
    },
    {
      key: "/notification",
      title: (
        <Text
          style={{
            color: pathname === "/notification" ? "black" : "#ffffff",
            fontWeight: pathname === "/notification" ? "bold" : "normal",
          }}
        >
          Notifications
        </Text>
      ),
      icon: (
        <BellOutline
          color={pathname === "/notification" ? "black" : "#ffffff"}
        />
      ),
    },
  ];

  return (
    <>
      {location.pathname.includes("quotationpdf") ? (
        <>{location.pathname.includes("quotationpdf") && <Quotation />}</>
      ) : (
        <>
          <Layout>
            <div style={{ display: "flex", padding: 10 }}>
              <div style={{ flex: "0 0 50px" }}>
                {" "}
                <img
                  width={200}
                  // style={{
                  //   backgroundColor: "#fa07",
                  // }}
                  src={LogoWhite}
                  alt="Journey Starts Here"
                />
              </div>{" "}
              <Menu style={{ flex: "auto", minWidth: 0 }} mode="horizontal">
                <Menu.Item key="dashboard" icon={<AntIcons.PieChartOutlined />}>
                  <NavRouteLink name="dashboard">Dashboard</NavRouteLink>
                </Menu.Item>
                {collection &&
                  collection?.map((item: any, i: any) => {
                    return item.childs.length !== 0 &&
                      !item.collectionId.parentId &&
                      !item.accessType.includes("NoAccess") ? (
                      <SubMenu
                        key={item.collectionId.route}
                        icon={<DynamicIcon type={item.collectionId.icon} />}
                        title={item.collectionId.collectionTitle}
                      >
                        {item.childs &&
                          item.childs.map((info: any, i: any) => {
                            return info.childs.length !== 0 &&
                              !info.accessType.includes("NoAccess") ? (
                              <SubMenu
                                key={info.collectionId.route}
                                icon={
                                  <DynamicIcon type={info.collectionId.icon} />
                                }
                                // icon={<AntIcons.UngroupOutlined />}
                                title={info.collectionId.collectionTitle}
                              >
                                {info.childs.map((element: any) => {
                                  return (
                                    <>
                                      {!info.accessType.includes(
                                        "NoAccess"
                                      ) && (
                                        <Menu.Item
                                          key={element.collectionId.route}
                                          icon={
                                            <DynamicIcon
                                              type={element.collectionId.icon}
                                            />
                                          }
                                        >
                                          <NavRouteLink
                                            name={element.collectionId.route}
                                          >
                                            {
                                              element.collectionId
                                                .collectionTitle
                                            }
                                          </NavRouteLink>
                                        </Menu.Item>
                                      )}
                                    </>
                                  );
                                })}
                              </SubMenu>
                            ) : (
                              <>
                                {!info.accessType.includes("NoAccess") && (
                                  <Menu.Item
                                    key={info.collectionId.route}
                                    icon={
                                      <DynamicIcon
                                        type={info.collectionId.icon}
                                      />
                                    }
                                  >
                                    <NavRouteLink
                                      name={info.collectionId.route}
                                    >
                                      {info.collectionId.collectionTitle}
                                    </NavRouteLink>
                                  </Menu.Item>
                                )}
                              </>
                            );
                          })}
                      </SubMenu>
                    ) : item.collectionId.parentId ? (
                      <></>
                    ) : (
                      <>
                        {!item.accessType.includes("NoAccess") && (
                          <Menu.Item
                            key={item.collectionId.route}
                            // icon={<AntIcons.PieChartOutlined />}
                            icon={<DynamicIcon type={item.collectionId.icon} />}
                          >
                            <NavRouteLink name={item.collectionId.route}>
                              {item.collectionId.collectionTitle}
                            </NavRouteLink>
                          </Menu.Item>
                        )}
                      </>
                    );
                  })}

                <Menu.Item key="7" icon={<AntIcons.GlobalOutlined />}>
                  <NavRouteLink name="dashboard">About Us</NavRouteLink>
                </Menu.Item>
              </Menu>
              <ProfileCard />
              {/* <div style={{ flex: "0 0 50px" }}></div> */}
            </div>

            <Layout>
              <FloatButton.Group shape="square">
                <FloatButton
                  icon={<AntIcons.CommentOutlined />}
                  onClick={() => setCommentOpen(!commentOpen)}
                />
                <FloatButton onClick={() => setTaskOpen(!taskOpen)} />
                <FloatButton icon={<AntIcons.SyncOutlined />} />
                <FloatButton.BackTop visibilityHeight={0} />
              </FloatButton.Group>
              <Content
                className="site-layout-background"
                style={{
                  margin: isMobile ? "0" : "0",
                  // padding: 24,
                  padding: isMobile ? 0 : 24,

                  height: "89vh",
                  overflow: "auto",
                }}
              >
                {props.children}
              </Content>
              {isMobile && (
                <TabBar
                  className="site-mobile-background"
                  activeKey={pathname}
                  onChange={(value) => setRouteActive(value)}
                  style={{ backgroundColor: "#f6a820" }}
                >
                  {tabs.map((item) => (
                    <TabBar.Item
                      key={item.key}
                      icon={item.icon}
                      title={item.title}
                    />
                  ))}
                </TabBar>
              )}
            </Layout>
          </Layout>
          <Drawer
            title="My Task"
            onClose={() => setTaskOpen(!taskOpen)}
            open={taskOpen}
            placement="left"
            width={"60%"}
          >
            <TodoList id={user.personnel.id} />
          </Drawer>
          <Drawer
            title="Followups"
            onClose={() => setCommentOpen(!commentOpen)}
            open={commentOpen}
            placement="right"
            width={"40%"}
          >
            <MobileFollowupList />
          </Drawer>
        </>
      )}
    </>
  );
};

export default AuthLayout;
