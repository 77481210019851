import { TRoute } from "../../utils/routes";
import StandardPageHome from "./Page/Home";

const StandardRouter: TRoute[] = [
  {
    path: "/standard",
    name: "standard",
    title: "standard",
    component: StandardPageHome,
  },
];

export default StandardRouter;
