import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Upload,
  Select,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageProductModalContext } from "../Modal/ManageProduct";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getAllActiveProduct, getProductById } from "../Requests";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import { tinyMCE } from "../../../utils/keys";
import { Editor } from "@tinymce/tinymce-react";
import { getCategoryByType } from "../../Category/Requests";
import { getAllActiveStandard } from "../../Standard/Requests";
import CreateStandardModal from "../../Standard/Modal/CreateStandard";
const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ManageProductForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageProductModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageProductMutation = useMutation(
    (values: any) => request("admin.product.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("product");
      },
    }
  );
  const {
    isLoading,
    data: response,
    error,
    isError,
  } = useQuery(["product", props.id], async () => getProductById(props.id), {
    refetchOnWindowFocus: false,
  });
  const { data: product } = useQuery(
    ["Product"],
    async () => getAllActiveProduct(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: category } = useQuery(
    ["category", "Product"],
    async () => getCategoryByType("Product"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: standard } = useQuery(
    ["standard"],
    async () => getAllActiveStandard(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const onFinish = async (values: any) => {
    values.description = remarks;
    values.imageUrl = galley;
    setFormLoading(true);

    manageProductMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Product has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={response}
    >
      <Row gutter={20}>
        <Col span={10}>
          <Form.Item
            label="Title"
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your  title",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Please Enter Title"
              onChange={(e) =>
                form.setFieldsValue({
                  customUrl: e.target.value.toLowerCase().replace(/ /g, "-"),
                })
              }
            // addonBefore={<ToolOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            label="Custom Url"
            name="customUrl"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "customUrl",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter customUrl",
              },
              { whitespace: true },
              { min: 3 },
            ]}
            hasFeedback
          >
            <Input placeholder="Enter Custom Url" />
          </Form.Item>
          <Form.Item
            label="Parent"
            name="parentId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "parentId",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select product Category",
            //   },
            // ]}
            hasFeedback
          >
            <Select placeholder="Select Product Category">
              {product &&
                product?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Product Category"
            name="productCategoryId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "productCategoryId",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select product Category",
            //   },
            // ]}
            hasFeedback
          >
            <Select placeholder="Select Product Category">
              {category &&
                category?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Select Standard"
            name="standardId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "standard",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              showSearch
              placeholder="Select Standard"
              optionFilterProp="children"
              filterOption={(input: any, option: any) => {
                // console.log(option);
                return (option.options.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input);
              }}
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateStandardModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {standard &&
                standard.map((ele: any, i: any) => {
                  return (
                    <Select.Option key={i} value={ele.id}>
                      {ele.ISNumber}-{ele.ISName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          {/* <Form.Item
        label="Icon Name"
        name="icon"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "icon",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ whitespace: true }, { min: 3 }]}
        hasFeedback
      >
        <Input placeholder="Enter Icon" />
      </Form.Item> */}
          <Row gutter={20}>
            <Col flex={2} span={12}>
              <Form.Item
                label="Upload Image"
                style={{ fontWeight: "bold" }}
                name="imageUrl"
                valuePropName="checked"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Upload Image",
              //   },
              // ]}
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  accept="image/png, image/jpeg, image/jpg"
                  action={async (file) => {
                    const response: any = await uploadFileToAWS(file, "product");
                    setGallery(response.key);
                    return response.key;
                  }}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col flex={2} span={6}>
              <Form.Item
                label="IsParent"
                style={{ fontWeight: "bold" }}
                name="isParent"
                tooltip={{
                  title: "isParent",
                  icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
              // rules={[{ required: true }]}
              >
                <Switch
                  checkedChildren="True"
                  unCheckedChildren="False"
                // defaultChecked={false}
                />
              </Form.Item>
            </Col>
            <Col flex={2} span={6}>
              <Form.Item
                label="Status"
                style={{ fontWeight: "bold" }}
                name="isActive"
                tooltip={{
                  title: "status",
                  icon: <InfoCircleOutlined />,
                }}
                valuePropName="checked"
              // rules={[{ required: true }]}
              >
                <Switch
                  checkedChildren="Active"
                  unCheckedChildren="Inactive"
                  defaultChecked
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <h3> Descriptions</h3>
          <Editor
            initialValue={product?.description}
            apiKey={tinyMCE.apiKey}
            // onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              plugins: tinyMCE.plugins,
              toolbar: tinyMCE.toolbar,
              tinycomments_mode: "embedded",
              tinycomments_author: "Author name",
              // mergetags_list: [
              //   { value: "First.Name", title: "First Name" },
              //   { value: "Email", title: "Email" },
              // ],

              paste_data_images: true,

              ai_request: (request: any, respondWith: any) =>
                respondWith.string(() =>
                  Promise.reject("See docs to implement AI Assistant")
                ),
            }}
            // initialValue="Welcome to TinyMCE!"
            onEditorChange={handleEditorChange}
          // outputFormat="html"
          />
        </Col>
      </Row>
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageProductForm;
