import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Upload,
  Select,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageSubMenuModalContext } from "../Modal/ManageSubMenu";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getSubMenuById } from "../Requests";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import { tinyMCE } from "../../../utils/keys";
import { Editor } from "@tinymce/tinymce-react";
import { getCategoryByType } from "../../Category/Requests";

const ManageSubMenuForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageSubMenuModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const manageSubMenuMutation = useMutation(
    (values: any) => request("admin.submenu.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("submenu");
      },
    }
  );
  const {
    isLoading,
    data: submenu,
    error,
    isError,
  } = useQuery(["submenu", props.id], async () => getSubMenuById(props.id), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    values.description = remarks;

    setFormLoading(true);

    manageSubMenuMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The SubMenu has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={submenu}
    >
      <Form.Item
        label="Title"
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter your  title",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Please Enter Title" />
      </Form.Item>

      <Editor
        initialValue={submenu.description}
        apiKey={tinyMCE.apiKey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        // initialValue="Welcome to TinyMCE!"
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />

      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageSubMenuForm;
