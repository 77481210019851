import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const CredentialRouter: TRoute[] = [
  {
    path: "/credential",
    name: "credential",
    title: "credential",
    component: DepartmentPageHome,
  },
];

export default CredentialRouter;
