import styled from "styled-components";
export const PrintableBodyWrapper = styled.div`
  @media print {
    // position: fixed;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // display: flex;
    // flex: 1;
    // width:100%;
    // height:100%;
    // justify-content: space-around;
    // align-items: center;
    .no-print,
    .no-print * {
      display: none !important;
      // visibility:hidden !important;
      // opacity:0 !important;
      text-decoration: none !important;
    }
    body {
      background-color: #fff !important;
      /* transform: scale(70%); */
      zoom: 50%;
      /* width:100%; */
      margin: 10;
      padding: 10;
    }
    @page {
      size: 210mm 297mm portrait;
      margin: 1mm !important;
    }
    .h {
      height: auto !important;
      width: 100% !important;
      max-width: 100% !important;
      //flex-basis: 100% !important;
      overflow: hidden !important;
    }
    thead > tr:first-child {
      /* background: #1A2980;  /* fallback for old browsers 
    background: -webkit-linear-gradient(to left, #26D0CE, #1A2980);  /* Chrome 10-25, Safari 5.1-6 
    background: linear-gradient(to left, #26D0CE, #1A2980); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      text-transform: uppercase;
      color: #1a2980;
      font-size: 12px;
    }
    tbody > tr {
      font-size: 13px;
    }
    tfoot > tr {
      font-size: 11px;
    }
  }
`;
export const GatePassWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  //justify-content: center;
  height: 20%;
  //line-height: 44px;
`;
export const GatePassHeader = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  margin: 0px;
  padding: 0px;
`;
export const GatePassSubHeader = styled.p`
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
`;
export const GatePassGSTIN = styled(GatePassSubHeader)`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
`;
export const GatePassBody = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    //font-size: 1rem;
    align-items: center;
    //   border:1px solid;
  }

  th,
  td {
    text-align: center;
    padding: 2px;
    //margin: 5px;
    // border: 0.5px dashed;
  }
  .m0 {
    margin: 0;
    padding: 0;
  }
  // display: flex;
  // flex: 1;
  // flex-direction: row;

  // justify-content: space-betwwen;
  // .date {
  //   // display:flex;
  //   align-self: left;
  //   font-size: 1rem;
  // }
  // .remarks {
  //   // display:flex;
  //   align-self: center;
  //   font-size: 1rem;
  // }
  // .station {
  //   // display:flex;
  //   align-self: right;
  //   font-size: 1rem;
  // }
`;
export const GatePassName = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  .bold {
    font-weight: bold;
    text-transform: Capitalize;
  }
`;
export const GatePassTableWrapper = styled(GatePassName)`
  table {
    border-collapse: collapse;
    width: 100%;
    // align-items: center;
    //   border:1px solid;
  }

  th,
  td {
    // text-align: center;
    padding: 2px;
    border: 0.5px dashed;
  }
  .m0 {
    margin: 0;
    padding: 0;
  }
`;
