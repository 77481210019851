import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../components/Card";
import DesignationList from "../Component/Home/List";
import CreateDesignationModal from "../Modal/CreateDesignation";
import { Empty, Row } from "antd";
import { getAccessAuthrorization } from "../../../../components/access";

const DesignationPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("designation");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Designation List</h2>
              {/* <CreateDesignationModal /> */}
              {accessAuth[0].includes("Create") && <CreateDesignationModal />}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <DesignationList /> */}
          {accessAuth[0].includes("View") ? (
            <DesignationList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(DesignationPageHome);
