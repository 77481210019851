import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TodoList from "../Component/TodoList";
import { Button, Col, Row, Tabs } from "antd";
import {
  AndroidOutlined,
  AppleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import WorkStats from "../Component/workStat";
import MentionList from "../Component/MetionList";
import LocalStorageService from "../../../utils/local-storage";
import AdminDashboard from "../Component/AdminDashboard";
import UserDashboard from "../Component/UserDashboard";
import { isMobile } from "react-device-detect";
import TodoMobileList from "../Component/TodoMobileList";
import MobileMentionList from "../Component/MobileMetionList";
import MobileAdminDashboard from "../Component/MobileAdminDashboard";
import MobileFollowupList from "../Component/FollowUpList";
import { Card } from "antd-mobile";

const DashboardPageHome: React.FC<RouteComponentProps> = () => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  return (
    <>
      <Row>
        <Col span={12}>
          <WorkStats/>
        </Col>
        <Col span={12}>
        <Card><TodoList id={user.personnel.id} /></Card>
        </Col>
      </Row>
      {/* <Row justify={"space-between"} align={"middle"}>
        <h2>Welcome To White Wisdom</h2>
        {isMobile ? (
          <Button
            icon={<ReloadOutlined />}
            onClick={() => window.location.reload()}
          />
        ) : (
          <Button
            icon={<ReloadOutlined />}
            onClick={() => window.location.reload()}
          >
            Reload Window
          </Button>
        )}
      </Row> */}
      {/* <Tabs
        centered={!isMobile}
        defaultActiveKey="1"
        type="card"
        items={[
          {
            key: "1",
            label: (
              <>
                <AndroidOutlined /> Dashboard
              </>
            ),
            children: (
              <>
                {user.roles[0] === "ROLE_ADMIN" ? (
                  isMobile ? (
                    <MobileAdminDashboard />
                  ) : (
                    <AdminDashboard />
                  )
                ) : (
                  <UserDashboard />
                )}
              </>
            ),
          },
          // {
          //   key: "2",
          //   label: (
          //     <>
          //       <AndroidOutlined /> Work
          //     </>
          //   ),
          //   children: <WorkStats />,
          //   disabled: user.roles[0] !== "ROLE_ADMIN",
          // },
          {
            key: "3",
            label: (
              <>
                <AndroidOutlined /> Task
              </>
            ),
            children: isMobile ? (
              <TodoMobileList id={user.personnel.id} />
            ) : (
              <TodoList id={user.personnel.id} />
            ),
          },
          {
            key: "4",
            label: (
              <>
                <AndroidOutlined /> Mentions
              </>
            ),
            children: isMobile ? <MobileMentionList /> : <MentionList />,
          },
          {
            key: "5",
            label: (
              <>
                <AndroidOutlined /> Follow Up
              </>
            ),
            children: <MobileFollowupList />,
          },
        ]}
      /> */}
    </>
  );
};

export default withRouter(DashboardPageHome);
