import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Empty, Space, Table, Tag, Image, Typography } from "antd";

import { getAllService } from "../Requests";
import ServiceViewModal from "../Modal/ShowServiceDetails";
import ManageServiceModal from "../Modal/ManageService";
import { getAccessAuthrorization } from "../../../components/access";
import ManageCategoryModal from "../../Category/Modal/ManageCategory";
import SubMenuViewModal from "../../SubMenu/Modal/ShowSubMenuDetails";
import { s3Path } from "../../../utils/s3Config/s3upload";
import AddSopsModal from "../Modal/AddSops";
import AddProductModal from "../Modal/AddProduct";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const ServiceList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("service");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["service"], () => getAllService(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Image",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text: any) =>
        text ? (
          <Image width={100} height={100} src={s3Path + text} />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (text: any) => <Tag color="gold">{text}</Tag>,
    // },
    {
      title: "Category",
      dataIndex: "serviceCategoryId",
      key: "serviceCategoryId",
      render: (text: any) => <p>{text?.title}</p>,
    },
    {
      title: "Price",
      dataIndex: "id",
      key: "id",
      render: (id: any, i: any) => (
        <Space size={10} direction="vertical">
          <Typography.Text strong>
            Actual Price:- <Tag color="red">₹ {i?.price}</Tag>
          </Typography.Text>
          <Typography.Text strong>
            Discounted Price:- <Tag color="green">₹ {i?.discountPrice}</Tag>
          </Typography.Text>
        </Space>
      ),
    },
     {
      title: "Product",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
       
          <AddProductModal id={id} />
      
        </Space>
      ),
    },
    {
      title: "SOPs",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CategoryViewModal id={id} /> */}
          {/* <CreateSubMenuModal id={id} /> */}
          <AddSopsModal id={id} />
          {/* {accessAuth[0].includes("Edit") && <ManageCategoryModal id={id} />} */}
        </Space>
      ),
    },
    // {
    //   title: "Custom Url",
    //   dataIndex: "customUrl",
    //   key: "customUrl",
    // },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          {/* <CategoryViewModal id={id} /> */}
          {/* <ManageCategoryModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageServiceModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default ServiceList;
