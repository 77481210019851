import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";
import CreateServiceModal from "../Modal/CreateService";
import ServiceList from "../Component/List";
import { getAccessAuthrorization } from "../../../components/access";

const ServicePageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("service");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Service List</h2>
              <CreateServiceModal />
              {/* {accessAuth[0].includes("Create") && <CreateServiceModal />} */}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ServiceList />
          {/* {accessAuth[0].includes("View") ? (
            <ServiceList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )} */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(ServicePageHome);
