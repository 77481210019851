import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  Row,
  Checkbox,
  Col,
  Upload,
  Typography,
  Divider,
  Space,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { ManagePartyModalContext } from "../Modal/ManageParty";
import { useQuery } from "react-query";
import { getPartyById } from "../Requests";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { s3Path, uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import LocalStorageService from "../../../utils/local-storage";
import { InputRules } from "../../../utils/InputRules";
import { countriesCode } from "../../../constants/dialCode";
// import { products } from "../../../constants/products";
import CreateProductModal from "../../Product/Modal/CreateProduct";
import { getAllActiveProduct } from "../../Product/Requests";
// import { getAllLocality } from "src/modules/Locality/Requests";
const { Text } = Typography;
const ManagePartyForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManagePartyModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();

  const ManagePartyMutation = useMutation(
    (values: any) => request("admin.party.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("party");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: party,
  } = useQuery(["party", props.id], async () => getPartyById(props.id), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  const { data: product } = useQuery(
    ["product"],
    async () => getAllActiveProduct(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    setFormLoading(true);
    // const response = await uploadFileToAWS(selectedFile, "Profile");
    values.imageUrl = galley;

    ManagePartyMutation.mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The party has been successfully Managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const options = [
    { label: "Admin", value: "Admin", disabled: true },
    { label: "Client", value: "Client" },
    { label: "Laboratory", value: "Laboratory" },
    { label: "Authority", value: "Authority" },

    { label: "Lead Provider", value: "Lead Provider" },
    { label: "Partner", value: "Partner", disabled: true },
    { label: "Inhouse Employee", value: "Inhouse Employee", disabled: true },
    {
      label: "Outsource Employee",
      value: "Outsource Employee",
      disabled: true,
    },
  ];

  const [form] = Form.useForm();
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={party}
    >
      <Row align="middle" justify="space-between">
        <Form.Item
          label="Type"
          style={{ fontWeight: "bold" }}
          name="partyType"
        // valuePropName="checked"
        >
          <Checkbox.Group options={options} />
        </Form.Item>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Company Name"
            name="companyName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Company Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Comapany name",
              // },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<BankOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Upload Image"
            style={{ fontWeight: "bold" }}
            name="imageUrl"
            valuePropName="checked"
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              accept="image/png, image/jpeg"
              action={async (file) => {
                const response: any = await uploadFileToAWS(file, "party");
                setGallery(response.key);
                return response.key;
              }}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={5} span={12}>
          <Form.Item
            label="Person Name"
            name="personName"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Person Name",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<UserOutlined className="site-form-item-icon" />}
              maxLength={30}
            />
          </Form.Item>
        </Col>
        <Col flex={4} span={12}>
          <Space.Compact>
            <Form.Item
              label="Country Code"
              name="countryCode"
              style={{ fontWeight: "bold", width: "40%" }}
              // tooltip={{
              //   title: "Official Phone no",
              //   icon: <InfoCircleOutlined />,
              // }}
              rules={[
                {
                  required: true,
                  message: "Please enter country code",
                },
                // { whitespace: true },

                // ...InputRules.phoneNo,
              ]}
              initialValue={"+91"}
            >
              <Select
                showSearch
                // placeholder="Select attention"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
              >
                {countriesCode.map((ele: any, i: any) => {
                  return (
                    <Select.Option
                      value={ele.dial_code}
                      key={i}
                    >{`${ele.name}(${ele.code}) ${ele.dial_code}`}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNo"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Official Phone no",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your Phone Number",
                },
                { whitespace: true },

                ...InputRules.phoneNo,
              ]}
            >
              <Input
                // addonBefore="+91-"
                addonAfter={<MobileOutlined />}
                maxLength={10}
              />
            </Form.Item>
          </Space.Compact>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={4}>
          <Form.Item
            label="Address"
            style={{ fontWeight: "bold" }}
            name="address"
            tooltip={{
              title: "address",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your Comapany name",
              },
              { whitespace: true },
              { min: 3 },
              ...InputRules.text,
            ]}
          >
            <Input
              addonBefore={<HomeOutlined className="site-form-item-icon" />}
              maxLength={250}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="Website"
            style={{ fontWeight: "bold" }}
            name="website"
            tooltip={{
              title: "Website e.g www.example.com",
              icon: <InfoCircleOutlined />,
            }}
            rules={[{ type: "url", message: "Please enter a valid url" }]}
            hasFeedback
          >
            <Input
              addonBefore={<GlobalOutlined className="site-form-item-icon" />}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Email"
            style={{ fontWeight: "bold" }}
            name="email"
            tooltip={{
              title: "email",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
              { type: "email", message: "Please enter a valid email" },
            ]}
            hasFeedback
          >
            <Input
              addonBefore={<MailOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={2} span={12}>
          <Form.Item
            label="GST No."
            style={{ fontWeight: "bold" }}
            name="gstInNo"
            tooltip={{
              title: "GSTIN No",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Comapany name",
              // },
              { whitespace: true },
              { min: 15 },
            ]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={15}
            />
          </Form.Item>
        </Col>
        <Col flex={2} span={12}>
          <Form.Item
            label="Select Product"
            name="productId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "product",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="Select Product"
              optionFilterProp="children"
              filterOption={(input: any, option: any) => {
                // console.log(option);
                return (option.children as unknown as string)
                  .toString()
                  .toLowerCase()
                  .includes(input);
              }}
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreateProductModal />
                  </Row>
                  {menu}
                </>
              )}
              allowClear
            >
              {product &&
                product.map((ele: any, i: any) => {
                  return (
                    <Select.Option key={i} value={ele.id}>
                      {ele.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            label="WeChat No."
            style={{ fontWeight: "bold" }}
            name="weChatNo"
            tooltip={{
              title: "weChatNo",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Comapany name",
              // },
              { whitespace: true },

            ]}
          >
            <Input
              placeholder="Enter WeChat No."
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label="Remarks"
            style={{ fontWeight: "bold" }}
            name="remarks"
            tooltip={{
              title: "remarks",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter your Comapany name",
              // },
              { whitespace: true },

            ]}
          >
            <Input
              placeholder="Enter Remarks"

            />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            label="Status"
            style={{ fontWeight: "bold" }}
            name="isActive"
            tooltip={{
              title: "status",
              icon: <InfoCircleOutlined />,
            }}
            valuePropName="checked"
          // rules={[{ required: true }]}
          >
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              defaultChecked
            />
          </Form.Item>
        </Col>

      </Row>
      {/* <Divider />
      <h3>Social Media Link</h3>
      <Divider />
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Facebook Url"
            style={{ fontWeight: "bold" }}
            name="facebookUrl"
            tooltip={{
              title: "Facebook Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Twitter Url"
            style={{ fontWeight: "bold" }}
            name="twitterUrl"
            tooltip={{
              title: "Twitter Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Instagram Url"
            style={{ fontWeight: "bold" }}
            name="instagramUrl"
            tooltip={{
              title: "Instagram Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="Pinterest Url"
            style={{ fontWeight: "bold" }}
            name="pinterestUrl"
            tooltip={{
              title: "Pinterest Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col flex={3} span={12}>
          <Form.Item
            label="Youtube Url"
            style={{ fontWeight: "bold" }}
            name="youtubeUrl"
            tooltip={{
              title: "Youtube Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={12}>
          <Form.Item
            label="LinkedInUrl"
            style={{ fontWeight: "bold" }}
            name="linkedInUrl"
            tooltip={{
              title: "LinkedIn Url",
              icon: <InfoCircleOutlined />,
            }}
            // rules={[{ required: true }]}
          >
            <Input
              addonBefore={<AuditOutlined className="site-form-item-icon" />}
              maxLength={20}
            />
          </Form.Item>
        </Col>
      </Row> */}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Party
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManagePartyForm;
