import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";
import CreateDocumentModal from "../Modal/CreateDocument";
import DocumentList from "../Component/List";
import { getAccessAuthrorization } from "../../../components/access";

const DocumentPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("document");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <Row justify="space-between">
              <h2>Document List</h2>
              {/* <CreateDocumentModal /> */}
              {/* {accessAuth[0].includes("Create") && <CreateDocumentModal />} */}
            </Row>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <DocumentList />
          {/* {accessAuth[0].includes("View") ? (
            <DocumentList />
          ) : (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>You are Not Authorised!</span>}
            />
          )} */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(DocumentPageHome);
