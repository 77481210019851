import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Avatar,
  DatePicker,
  InputNumber,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreatePaymentInModalContext } from "../Modal/CreatePaymentIn";
import { s3Path, uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import CreatePartyModal from "../../../modules/Party/Modal/CreateParty";
import {
  getAllActiveParty,
  getAllConcernedPerson,
} from "../../../modules/Party/Requests";
import { imLogoIcon } from "../../../constants/logo";
// import CreateConcernedPersonModal from "src/modules/Party/Modal/concernedPerson";

const { Text } = Typography;
const CreatePaymentIn: React.FC = () => {
  const modalContext = React.useContext(CreatePaymentInModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const [imageUrl, setImageUrl] = React.useState<string>();
  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const createPaymentInMutation = useMutation(
    (values: any) => request("admin.payment.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("payment");
      },
    }
  );

  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });
  const { data: concernedPerson } = useQuery(
    ["concernedPerson"],
    async () => getAllConcernedPerson(),
    {
      refetchOnWindowFocus: false,
    }
  );
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    setSelectedFile(info.file.originFileObj);
    if (info.file.status === "uploading") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.imageUrl = galley;
    values.type = "Incoming";
    setFormLoading(true);

    createPaymentInMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The PaymentIn has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  let clientList = party.filter(
    (element: any) => element.partyType[0] === "Client"
  );
  let vendorList = party.filter(
    (element: any) => element.partyType[0] === "Vendor"
  );
  let leadList = party.filter(
    (element: any) => element.partyType[0] === "Lead Provider"
  );

  let partyList = [...clientList, ...vendorList, ...leadList];
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label="Ref Number"
            name="refNumber"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "refNumber",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Input placeholder="Enter Ref Number" maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Select Party"
            name="partyId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "partyId",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              //onChange={onPartySelect}
              showSearch
              placeholder="Select Party"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
              dropdownRender={(menu) => (
                <>
                  <Row justify={"end"}>
                    <CreatePartyModal />
                  </Row>
                  {menu}
                </>
              )}
            >
              {partyList &&
                partyList?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item.id} key={i}>
                      <Avatar
                        src={
                          <img
                            src={
                              item.imageUrl
                                ? s3Path + item.imageUrl
                                : imLogoIcon
                            }
                            alt={item?.personName[0]}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {item?.personName}-{item?.companyName}
                      </Typography.Text>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Date"
            name="date"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "date",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col flex={3} span={8}>
          <Form.Item
            label="Mode"
            name="mode"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "mode",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Select
              placeholder="Select Mode"
              options={[
                {
                  value: "Cash",
                  label: "Cash",
                },
                {
                  value: "Cheque",
                  label: "Cheque",
                },
                {
                  value: "UPI",
                  label: "UPI",
                },
                {
                  value: "NEFT/IMPS",
                  label: "NEFT/IMPS",
                },
                {
                  value: "RTGS",
                  label: "RTGS",
                },
                {
                  value: "Card",
                  label: "Card",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col flex={3} span={8}>
          <Form.Item
            label="Ack. Number"
            name="ackNumber"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "ackNumber",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Input placeholder="Enter Ack. Number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Amount"
            name="amount"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "amount",
              icon: <InfoCircleOutlined />,
            }}
            hasFeedback
          >
            <Input placeholder="Enter amount" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Remarks"
        name="remarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "remarks",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <TextArea placeholder="Enter remarks" maxLength={250} />
      </Form.Item>
      <Form.Item
        label="Status"
        style={{ fontWeight: "bold" }}
        name="isActive"
        tooltip={{
          title: "status",
          icon: <InfoCircleOutlined />,
        }}
        valuePropName="checked"
        // rules={[{ required: true }]}
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePaymentIn;
