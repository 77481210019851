import { request } from "../../../utils/services";

export const getAllActivePurchaseInvoice = async () => {
  const { data } = await request("admin.purchaseInvoice.list.active");
  return data;
};

export const getAllPurchaseInvoice = async () => {
  const { data } = await request("admin.purchaseInvoice.list");
  return data;
};

export const getPurchaseInvoiceById = async (id: string) => {
  const { data } = await request("admin.purchaseInvoice.id", { id });
  return data;
};

export const getOnePurchaseInvoiceWithDetail = async (id: string) => {
  const { data } = await request("admin.purchaseInvoice.id.with.detail", { id });
  return data;
};
export const getPurchaseInvoiceByPartyId = async (id: string) => {
  const { data } = await request("admin.purchaseInvoice.party.id", { id });
  return data;
};
