import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Input,
  Col,
  Upload,
  Switch,
  Divider,
  Select,
  Radio,
} from "antd";

import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import type { RcFile, UploadProps } from "antd/es/upload";

import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { CreateDocumentModalContext } from "../Modal/CreateDocument";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "../../../utils/keys";
import { uploadFileToAWS } from "../../../utils/s3Config/s3upload";
import { getAllDocument } from "../Requests";

const { Text } = Typography;
const CreateDocument: React.FC<{
  partyId?: string;
  workId?: string;
  leadId?: string;
  serviceId?: string;
}> = (props) => {
  const modalContext = React.useContext(CreateDocumentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [title, setTitle] = React.useState<string>();
  const [gallery, setGallery] = React.useState<string>();
  // const [parent, setParent] = React.useState<[object] | any>([{}]);
  // const [loading, setLoading] = React.useState(false);

  // const [imageUrl, setImageUrl] = React.useState<string>();
  // const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  // const [editorkey, seteditorkey] = React.useState(4);

  const createDocumentMutation = useMutation(
    (values: any) => request("admin.document.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("document");
        queryClient.invalidateQueries("documentByParty");
        queryClient.invalidateQueries("documentByWork");
      },
    }
  );
  const { data: document } = useQuery(
    ["document"],
    async () => getAllDocument(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;
    values.partyId = props.partyId && props.partyId;
    values.leadId = props.leadId && props.leadId;
    values.workId = props.workId && props.workId;
    values.serviceId = props.serviceId && props.serviceId;
    // values.description = remarks;
    values.fileUrl = gallery;

    setFormLoading(true);

    createDocumentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Document has been successfully created.");
        form.resetFields();

        // modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row gutter={20}>
          <Col flex={1}>
            <Form.Item
              // label="Title"
              name="title"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "Title",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please enter your  title",
                },
                { whitespace: true },
                { min: 3 },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Enter Document Title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col flex={1}>
            {" "}
            <Form.Item
              // label="Upload Image"
              style={{ fontWeight: "bold" }}
              name="fileUrl"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  message: "Please Upload Image",
                },
              ]}
            >
              {title && (
                <Upload
                  maxCount={1}
                  accept="image/png, image/jpeg, application/pdf"
                  action={async (file) => {
                    const response: any = await uploadFileToAWS(
                      file,
                      "Documents",
                      title
                    );
                    setGallery(response.key);
                    return response.key;
                  }}
                // onChange={handleChange}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              )}
            </Form.Item>
          </Col>

          <Col flex={1}>
            <Form.Item
              // label="Status"
              style={{ fontWeight: "bold" }}
              name="isActive"
              tooltip={{
                title: "status",
                icon: <InfoCircleOutlined />,
              }}
              valuePropName="checked"
            // rules={[{ required: true }]}
            >
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                defaultChecked
              />
            </Form.Item>{" "}
          </Col>

          <Form.Item style={{ textAlign: "center" }}>
            <Button
              size="large"
              loading={formLoading}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default CreateDocument;
