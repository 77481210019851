import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../components/Card";

import { Empty, Row } from "antd";
import CreateTaskTemplateModal from "../Modal/CreateTaskTemplate";
import TaskTemplateList from "../Component/List";
import { getAccessAuthrorization } from "../../../components/access";
import CreateTaskTemplate from "../Form/CreateTaskTemplateForm";

const TaskTemplatePageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("taskTemplate");

  return (
    <React.Fragment>
      <Card>
        <CreateTaskTemplate />
      </Card>
    </React.Fragment>
  );
};

export default withRouter(TaskTemplatePageHome);
