import * as React from "react";
import { Form, Input, Button, message, Switch } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreateRolesModalContext } from "../Modal/CreateRoles";

const CreateRolesForm: React.FC = () => {
  const modalContext = React.useContext(CreateRolesModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createRolesMutation = useMutation(
    (values: any) => request("admin.roles.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("roles");
      },
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);

    createRolesMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The roles has been successfully created.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label="Title Name"
        name="name"
        tooltip={{
          title: "name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Status" name="status" required>
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateRolesForm;
