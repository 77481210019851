import { path } from "./path";

export const productRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveProduct",
    module: "product",
    alias: "admin.product.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateProduct/:id",
    module: "product",
    alias: "admin.product.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveProduct",
    module: "product",
    alias: "admin.product.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllProduct",
    module: "product",
    alias: "admin.product.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneProduct/:id",
    module: "product",
    alias: "admin.product.id",
    isSubDomain: true,
  },
];
