import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManagePartyForm from "../Form/ManagePartyForm";
import { EditOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import ManageMobilePartyForm from "../Form/ManageMobilePartyForm";

export const ManagePartyModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const PartyManageModal: React.FC<{ id: string; buttonType?: any }> = ({
  id,
  buttonType,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManagePartyModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type={buttonType ? buttonType : "primary"}
          shape="circle"
          icon={<EditOutlined />}
        />
      </Tooltip>
      <Modal
        title="Manage Party"
        visible={isModalVisible}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        {isMobile ? (
          <ManageMobilePartyForm id={id} />
        ) : (
          <ManagePartyForm id={id} />
        )}
      </Modal>
    </ManagePartyModalContext.Provider>
  );
};

export default PartyManageModal;
