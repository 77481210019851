import * as React from "react";
import {
  Form,
  Button,
  message,
  Select,
  DatePicker,
  Row,
  Typography,
  Col,
  Input,
  Upload,
  Card,
  Avatar,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  TagOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { CreateLeadsModalContext } from "../Modal/CreateLeads";
import { useQuery } from "react-query";
import TextArea from "antd/lib/input/TextArea";

import LocalStorageService from "../../../utils/local-storage";
import { s3Path, uploadFileToAWS } from "../../../utils/s3Config/s3upload";

import { getAllPersonnel } from "../../HR/Personnel/Requests";

import { imLogoIcon } from "../../../constants/logo";
import moment from "moment";
import { CreateLeadsByEnquiryModalContext } from "../Modal/CreateLeadByEnquiry";

const CreateLeadsByEnquiryForm: React.FC<{ data: any }> = ({ data }) => {
  const modalContext = React.useContext(CreateLeadsByEnquiryModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();

  const createLeadMutation = useMutation(
    (values: any) => request("admin.bulk.lead.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lead");
      },
    }
  );

  const onFinish = (values: any) => {
    values.createdBy = LocalStorageService.getAccount().id;

    values.leadStatus = "New Lead";
    values.content = `New Lead Created By ${
      LocalStorageService.getAccount().username
    }`;
    values.description = values.remarks;
    values.imageUrl = galley;
    values.dueDate = new Date();
    setFormLoading(true);
    values.taskTitle = "Take followup to -";
    //Notification Values
    values.notificationTitle = "Hey! New Lead Assigned";
    values.notificationBody =
      "Don't Forget to Keep in touch with client!  Click to view & take Followup";
    values.receiverId = [values.assignedPersonId];
    values.senderId = LocalStorageService.getAccount().personnel.id;
    values.phoneNo = values.mobile;
    values.companyName = values.company;
    values.personName = values.name;

    createLeadMutation
      .mutateAsync({ list: [values] })
      .then(() => {
        setFormLoading(false);
        message.success("The lead has been successfully created.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        console.log(e);

        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  // console.log(data);
  data.enquiryDate = moment(data.createdAt, "YYYY-MM-DD");
  // data.enquiryDate = data.createdAt;
  data.source = "Website";
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={data}
    >
      <Form.Item
        label="Enquiry Date"
        name="enquiryDate"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Enquiry Date",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Enquiry Date",
          },
        ]}
        hasFeedback
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="Lead Title"
        name="title"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Lead Title",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Lead Title",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter name",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Company Name"
        name="company"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Company Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Company Name",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Mobile"
        name="mobile"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "mobile",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Mobile No.",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "email",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter email",
          },
        ]}
        hasFeedback
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Source"
        name="source"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Source",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Source",
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Source" maxLength={50} />
      </Form.Item>
      <h3>Services</h3>
      <Form.List name="services">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any) => (
              <Row
                gutter={16}
                key={field.key}
                justify="space-between"
                align="top"
              >
                <Col span={23}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        // name={[field.name, "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Services Required",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={
                            <TagOutlined className="site-form-item-icon" />
                          }
                          placeholder="Enter Services"
                          maxLength={100}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                <DeleteOutlined onClick={() => remove(field.name)} />
              </Row>
            ))}

            <Form.Item>
              <Button onClick={() => add()} block icon={<PlusOutlined />}>
                Add Services
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        label="Remark"
        name="remarks"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Remarks ",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please enter remarks",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <TextArea placeholder="Enter Remarks" maxLength={150} />
      </Form.Item>
      {/* <Form.Item
        label="Upload Image"
        style={{ fontWeight: "bold" }}
        name="imageUrl"
        valuePropName="checked"
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          accept="image/png, image/jpeg"
          action={async (file) => {
            const response: any = await uploadFileToAWS(file, "lead");
            setGallery(response.key);
            return response.key;
          }}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item> */}
      <Form.Item
        label="Assigned Person"
        style={{ fontWeight: "bold" }}
        name="assignedPersonId"
        tooltip={{
          title: "Person ",
          icon: <InfoCircleOutlined />,
        }}
      >
        <Select
          // onSelect={OnPersonSelect}
          showSearch
          size="large"
          popupMatchSelectWidth
          // style={{ minHeight: 150 }}
          placeholder="Select Person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option!.children as unknown as string).includes(input)
          }
        >
          {person &&
            person?.map((item: any, i: number) => {
              return (
                <Select.Option
                  value={item?.id}
                  key={i}
                  // disabled={item.availability === 0 ? true : false}
                >
                  <Row justify={"space-between"} align={"middle"}>
                    <Typography.Text strong>
                      {item?.partyId?.personName}
                    </Typography.Text>

                    <Avatar
                      src={
                        <img
                          src={
                            item.imageUrl ? s3Path + item.imageUrl : imLogoIcon
                          }
                          alt={item?.partyId?.personName[0]}
                        />
                      }
                    />
                  </Row>
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateLeadsByEnquiryForm;
