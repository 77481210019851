import { Badge, Card, Row, Tag, Typography } from "antd";

import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";
import ReplyManageModal from "../../Modal/ManageReply";

import { Space, Table } from "antd";

import { getAllReply } from "../../Requests";
import { getAccessAuthrorization } from "../../../../components/access";
import moment from "moment";
const { Text } = Typography;
const ReplyList: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(100);
  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState("");
  let accessAuth = getAccessAuthrorization("reply");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["reply"], () => getAllReply(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  interface DataType {
    // id: number;
    name: any;
    route: any;
    parent: any;
    // status: any;
  }

  const column: ColumnsType<DataType> = [
    // {
    //   title: "Reply ID",
    //   dataIndex: "id",
    //   key: "id",

    //   render: (text: any) => <p>PR/Dept/{text}</p>,
    // },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text: any) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Title ",
      dataIndex: "title",
      key: "title",
      render: (_: any, i: any) => (
        <Badge.Ribbon text={i?.type}>
          <Card title={i?.title} size="small">
            {i?.remarks}
          </Card>
        </Badge.Ribbon>
      ),
    },

    {
      title: "Assigned Person",
      dataIndex: "assignedPersonId",
      key: "assignedPersonId",
      render: (_: any, i: any) => (
        <Space size={10}>
          <Tag>{i?.assignedPersonId?.partyId?.personName}</Tag>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <Tag color="geekblue">{text}</Tag>,
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",

      render: (id: any) => (
        <Space size={10}>
          {/* <ReplyManageModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ReplyManageModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default ReplyList;
