import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { getCategoryById } from "../../Category/Requests";
import { useQuery } from "react-query";
import CreateSubMenu from "./CreateSubMenuForm";
import { PlusOutlined } from "@ant-design/icons";
import SubMenuList from "../Component/List";
import SubMenuByCategoryList from "../Component/ListByCategory";
import SubMenuByServiceList from "../Component/ListByService";
import { getServiceById } from "../../Service/Requests";

const ShowSubMenuDetails: React.FC<{ id: string }> = (props) => {
  const { data: service } = useQuery(
    ["service", props.id],
    async () => getServiceById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "SubMenu List",
      children: <SubMenuByServiceList id={props.id} />,
    },
    {
      key: "2",
      label: "Add SubMenu",
      children: <CreateSubMenu id={props.id} />,
      icon: <PlusOutlined />,
    },
  ];

  return (
    <>
      <Tabs
        defaultActiveKey="2"
        type="card"
        items={items}
        tabBarExtraContent={
          <Typography.Title level={4}>{service.title}</Typography.Title>
        }
      />
    </>
  );
};

export default ShowSubMenuDetails;
