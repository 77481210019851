import moment from "moment";
import * as React from "react";

import {
  Avatar,
  Badge,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Row,
  Tabs,
  Tag,
  Typography,
} from "antd";

import {
  MenuUnfoldOutlined,
  LeftSquareOutlined,
  NotificationOutlined,
  PlaySquareOutlined,
  NodeExpandOutlined,
} from "@ant-design/icons";
import parse from "html-react-parser";
import { ShowTaskData } from "../../Task/Form/ShowTaskDetailsForm";
import { imLogoIcon } from "../../../constants/logo";
import { s3Path } from "../../../utils/s3Config/s3upload";
const { Text, Paragraph } = Typography;

// const StoryListByWorkId: React.FC<{ data: string | any }> = (props) => {
//   console.log(props.data);
//   const ArrangeParentChildStory = (arr: any) => {
//     var parentChildArray;
//     arr?.reduce((acc: any, val: any, ind: any, array: any) => {
//       const childs: any = [];

//       array.forEach((el: any, i: any) => {
//         if (childs.includes(el.storyId) || el.story[0]?._id === val._id) {
//           childs.push(el);
//         }
//       });

//       parentChildArray = acc.concat({ ...val, childs });

//       return acc.concat({ ...val, childs });
//     }, []);
//     return parentChildArray;
//   };

//   var getChild: any = ArrangeParentChildStory(props.data);
//   var getChildofChild: any = ArrangeParentChildStory(getChild);
//   // console.log(getChildofChild);
//   var storyData = getChildofChild.filter(
//     (item: any) => item.storyId === getChildofChild.storyId
//   );

//   return (
//     <ModuleCollaspe data={storyData} type={"Module"} tagColor={"success"} />
//   );
// };
// export default StoryListByWorkId;

// export const ModuleCollaspe: React.FC<{
//   data: any;
//   type: any;
//   tagColor: any;
// }> = ({ data, type, tagColor }) => {
//   return (
//     <Collapse
//       // key={seg.i}
//       size="small"
//       accordion
//       expandIcon={({ isActive }) => (
//         <NodeExpandOutlined rotate={isActive ? 90 : 0} />
//       )}
//       items={
//         data &&
//         data?.map((seg: any, i: any) => {
//           return {
//             key: i,
//             label: (
//               <Paragraph>
//                 <Text code strong type={tagColor}>
//                   {type} #{i + 1}
//                 </Text>
//                 <Text strong>{seg?.title}</Text>
//               </Paragraph>
//             ),

//             children: (
//               <>
//                 <Tabs
//                   centered
//                   // tabPosition="left"
//                   defaultActiveKey="2"
//                   // type="card"
//                   items={[
//                     {
//                       key: "1",
//                       label: <>Description/Details</>,
//                       children: (
//                         <Card style={{ width: "100%" }}>
//                           {seg?.description && parse(seg?.description)}
//                         </Card>
//                       ),
//                     },
//                     {
//                       key: "2",
//                       label: <>Tasks List</>,
//                       children: (
//                         <Card style={{ width: "100%" }}>
//                           <StoryTaskList data={seg.tasks} />
//                         </Card>
//                       ),
//                     },
//                     {
//                       key: "3",
//                       label: <>Sub-Modules List</>,
//                       children: (
//                         <Card style={{ width: "100%" }}>
//                           {seg.childs && (
//                             <ModuleCollaspe
//                               data={seg.childs}
//                               type={"Sub-Module"}
//                               tagColor={"secondary"}
//                             />
//                           )}
//                         </Card>
//                       ),
//                     },
//                   ]}
//                 />
//               </>
//             ),
//           };
//         })
//       }
//     />
//   );
// };

export const TaskListByWorkId: React.FC<{ data: any }> = ({ data }) => {
  // console.log(data);

  return (
    <>
      <Collapse
        size="small"
        ghost
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <LeftSquareOutlined rotate={isActive ? -90 : 0} />
        )}
        bordered
        accordion
        items={
          data &&
          data?.map((taskElement: any, taskIndex: any) => {
            return {
              key: taskIndex,
              label: (
                <Badge.Ribbon
                  text={taskElement?.type}
                  color={
                    taskElement.type === "Lead"
                      ? "#A4FF16"
                      : taskElement.type === "Work"
                      ? "#DD1B"
                      : taskElement.type === "Utilities"
                      ? "#F29084"
                      : taskElement.type === "Other"
                      ? "#49668F"
                      : "#16FFF3"
                  }
                  style={{ marginRight: 50 }}
                >
                  <Card size="small" style={{ marginRight: 50 }}>
                    <Row justify={"space-between"}>
                      <Col>
                        <Tag color="orange">Task #{taskElement?.seq}</Tag>
                        <Typography.Text code>
                          {moment(taskElement?.dueDate).format("DD-MMM-YYYY")}
                        </Typography.Text>{" "}
                        <Typography.Text strong>
                          {taskElement?.title}
                        </Typography.Text>{" "}
                        <Tag
                          color={
                            taskElement.status === "To Do"
                              ? "orange"
                              : taskElement.status === "In Progress"
                              ? "blue"
                              : taskElement.status === "On Hold"
                              ? "volcano"
                              : taskElement.status === "In Review"
                              ? "cyan"
                              : "lime"
                          }
                          style={{ marginLeft: 20 }}
                        >
                          {taskElement.status}
                        </Tag>
                      </Col>
                      <Col style={{ marginRight: 50 }}>
                        <Avatar
                          src={
                            <img
                              src={
                                taskElement?.assignedPersonId[0]?.party[0]
                                  ?.imageUrl
                                  ? s3Path +
                                    taskElement?.assignedPersonId[0]?.party[0]
                                      ?.imageUrl
                                  : imLogoIcon
                              }
                              alt={
                                taskElement?.assignedPersonId[0]?.party[0]
                                  ?.personName
                              }
                            />
                          }
                        />
                        {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                      </Col>
                    </Row>
                  </Card>
                </Badge.Ribbon>
              ),

              children: (
                // <>{taskElement.remarks && parse(taskElement.remarks)}</>
                <ShowTaskData data={taskElement} />
              ),
            };
          })
        }
      />
    </>
  );
};
