import * as React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Switch,
  Select,
  DatePicker,
  Row,
  Typography,
  Upload,
  Avatar,
  Col,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreateTaskModalContext } from "../Modal/CreateTask";
import { getAllActiveTask, getTaskById } from "../Requests";
import { getAllPersonnel } from "../../HR/Personnel/Requests";
import {
  deleteFileToAWS,
  s3Path,
  uploadFileToAWS,
} from "../../../utils/s3Config/s3upload";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";
import LocalStorageService from "../../../utils/local-storage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";
import { tinyMCE } from "../../../utils/keys";
import { ManageTaskModalContext } from "../Modal/ManageTask";
import moment from "moment";

const ManageTaskForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageTaskModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [galley, setGallery] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);

  const [selectedFile, setSelectedFile] = React.useState<RcFile>();
  const [remarks, setRemarks] = React.useState<string>();
  const [editorkey, seteditorkey] = React.useState(4);

  const manageTaskMutation = useMutation(
    (values: any) => request("admin.task.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
      },
    }
  );
  const {
    isLoading,
    data: response,
    error,
    isError,
  } = useQuery(["task", props.id], async () => getTaskById(props.id), {
    refetchOnWindowFocus: false,
  });
  const {
    //isLoading,
    //isError,
    data: task,
  } = useQuery(["task"], () => getAllActiveTask(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.taskId = props.id;
    values.attachment = imageUrl;
    values.remarks = remarks;
    values.updatedBy = LocalStorageService.getAccount().id;
    //
    values.notificationTitle = "Attn.! Task Assigend to You Updated";
    values.notificationBody =
      "Task: " + values.title + "!  Click to view updatess in task";
    values.receiverId = [values.assignedPersonId];
    values.senderId = LocalStorageService.getAccount().personnel.id;

    manageTaskMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The task has been successfully managed.");
        form.resetFields();
        cleareditor();

        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  const handleEditorChange = (content: any, editor: any) => {
    // console.log("Content was updated:", content);
    setRemarks(content);
  };
  const cleareditor = () => {
    const newkey = editorkey * 43;
    seteditorkey(newkey);
  };
  response[0].dueDate = moment(response[0].dueDate, "DD-MM-YYYY");
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={response[0]}
    >
      <Row gutter={10}>
        <Col span={8}>
          <Form.Item
            label="Due Date"
            name="dueDate"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Due Date",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please Select Enquiry Date",
              },
            ]}
            hasFeedback
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="Title "
            name="title"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Title",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Task Title",
              },
              { message: "Please enter Task Title" },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={8}>
          {" "}
          <Form.Item
            label="Type "
            name="type"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "Type",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              {
                required: true,
                message: "Please enter Type Name",
              },
              { message: "Please enter Type Name" },
            ]}
            hasFeedback
          >
            <Select placeholder="Select Task Type">
              <Select.Option value="Lead">Lead</Select.Option>
              <Select.Option value="WorkModule">WorkModule</Select.Option>
              <Select.Option value="TestingBug">TestingBug</Select.Option>
              <Select.Option value="DeployedBug">DeployedBug</Select.Option>
              <Select.Option value="Utilities">Utilities</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          {" "}
          <Form.Item
            label="Value"
            name="value"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "value",
              icon: <InfoCircleOutlined />,
            }}
            rules={[
              // {
              //   required: true,
              //   message: "Please enter Type Name",
              // },
              { message: "Please enter Type Name" },
            ]}
            hasFeedback
          >
            <Select placeholder="Allot Value">
              <Select.Option value="Penny">Penny</Select.Option>
              <Select.Option value="Silver">Silver</Select.Option>
              <Select.Option value="Gold">Gold</Select.Option>
              <Select.Option value="Platinum">Platinum</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          {" "}
          <Form.Item
            label="Assigned Person"
            style={{ fontWeight: "bold" }}
            name="assignedPersonId"
            tooltip={{
              title: "Person ",
              icon: <InfoCircleOutlined />,
            }}
          >
            <Select
              // onSelect={OnPersonSelect}
              showSearch
              size="large"
              popupMatchSelectWidth
              // style={{ minHeight: 150 }}
              placeholder="Select Person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option!.children as unknown as string).includes(input)
              }
            >
              {person &&
                person?.map((item: any, i: number) => {
                  return (
                    <Select.Option
                      value={item?.id}
                      key={i}
                      // disabled={item.availability === 0 ? true : false}
                    >
                      <Row justify={"space-between"} align={"middle"}>
                        <Typography.Text strong>
                          {item?.partyId?.personName}
                        </Typography.Text>

                        <Avatar
                          src={
                            <img
                              src={s3Path + item.partyId.imageUrl}
                              alt={item?.partyId?.personName[0]}
                            />
                          }
                        />
                      </Row>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {/* <Form.Item label="Remarks" name="remarks" style={{ fontWeight: "bold" }}>
        <ReactQuill theme="snow" modules={modules} />
      </Form.Item> */}
      <Editor
        apiKey={tinyMCE.apiKey}
        key={editorkey}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          plugins: tinyMCE.plugins,
          toolbar: tinyMCE.toolbar,
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          // mergetags_list: [
          //   { value: "First.Name", title: "First Name" },
          //   { value: "Email", title: "Email" },
          // ],

          paste_data_images: true,

          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
        initialValue={response[0].remarks}
        onEditorChange={handleEditorChange}
        // outputFormat="html"
      />

      <Row justify={"space-between"}>
        <Form.Item
          label="Attachment"
          style={{ fontWeight: "bold" }}
          name="attachment"
          valuePropName="checked"
        >
          <Upload
            accept="application/pdf,application/msword, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            maxCount={4}
            multiple
            listType="picture"
            action={async (file) => {
              const response: any = await uploadFileToAWS(file, "task").catch(
                (err: any) => message.error(err?.toString())
              );
              setImageUrl([...imageUrl, response.key]);
              return response.key;
            }}
            beforeUpload={(fileList) => {
              console.log("FILE", fileList.size);
              return fileList.size / 1024 / 1024 <= 2 ? true : false;
            }}
            onRemove={(e) => {
              if (e?.error?.url) {
                deleteFileToAWS(e.error.url).then((value) =>
                  message.success(value)
                );
                var file = imageUrl.filter((item) => item !== e.error.url);
                setImageUrl(file);
              }
            }}
          >
            {imageUrl.length < 4 && (
              <Button icon={<UploadOutlined />}>Attachment</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Status"
          name="isActive"
          style={{ fontWeight: "bold" }}
          valuePropName="checked"
          required
        >
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            defaultChecked
          />
        </Form.Item>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageTaskForm;
