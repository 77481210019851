import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router, Route } from "react-router-dom";
import "./core/i18n";
import Routes from "./core/Routes";
import "./assets/sass/style.scss";
import AccountPageLogin from "./modules/Account/Page/Login";
import { QueryClientProvider, QueryClient, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AuthContext } from "./modules/Account/Context/AuthContext";
import Loadable from "react-loadable";

import CookiesService from "./utils/cookie";
import { Spin, Alert, ConfigProvider, theme } from "antd";
import ReactDOM from "react-dom";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import LocalStorageService from "./utils/local-storage";
import { getNotificationByPersonnel } from "./modules/Notification/Requests";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
      staleTime: 5000,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const Root: React.FC = () => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  // console.log(user);
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["notification", user.personnel.id],
    () => getNotificationByPersonnel(user.personnel.id),
    {
      refetchOnWindowFocus: true,

      // keepPreviousData: true,
      staleTime: 10,
    }
  );
  return (
    <Router>
      <Route component={Routes} />
    </Router>
  );
};

const MainApp: React.FC = () => {
  const localToken = CookiesService.getToken();

  const [token, setToken] = React.useState(localToken || "");

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#2F4557",
          // colorInfo: "darkcyan",
          borderRadius: 6,

          // Alias Token
          //  colorBgContainer: "#f6ffed",
        },
        // algorithm: theme.darkAlgorithm,
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
      }}
    >
      <React.Suspense
        fallback={
          <>
            <Spin spinning={true}>
              <Alert
                message="The content on the page is loading"
                description="Please wait for just a second..."
                type="info"
              />
            </Spin>
          </>
        }
      >
        <QueryClientProvider client={queryClient}>
          <AuthContext.Provider value={{ token, setToken }}>
            {token ? <Root /> : <AccountPageLogin />}
            <ReactQueryDevtools />
          </AuthContext.Provider>
        </QueryClientProvider>
      </React.Suspense>
    </ConfigProvider>
  );
};
// const container = document.getElementById("root");
// const root = createRoot(container!); // createRoot(container!) if you use TypeScript
// root.render(<MainApp />);

ReactDOM.render(<MainApp />, document.getElementById("root") as HTMLElement);
// Loadable.preloadReady().then(() => {
//   ReactDOM.hydrate(<MainApp />, document.getElementById("root") as HTMLElement);
// });
