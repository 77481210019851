import * as React from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Switch,
  Select,
  Radio,
  Col,
  Row,
  Checkbox,
  DatePicker,
  Card,
  Tag,
  Typography,
  Divider,
  Space,
  Avatar,
  Collapse,
  RadioChangeEvent,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation, useQuery } from "react-query";
import {
  ProfileOutlined,
  PhoneOutlined,
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  GlobalOutlined,
  MailOutlined,
  AuditOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../../src";
import { CreateFollowupModalContext } from "../Modal/CreateFollowup";
import LocalStorageService from "../../../utils/local-storage";
import Title from "antd/es/skeleton/Title";
import moment from "moment";
import FollowUpListByLead from "../Home/FollowupListByLeadId";
import TextArea from "antd/es/input/TextArea";
import { values } from "lodash";
import { getLeadByIdDetail } from "../Requests";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { imLogoIcon } from "../../../constants/logo";
import { getAllPersonnel } from "../../HR/Personnel/Requests";
import PartyCard from "../../Party/Component/Home/PartyCard";
import ShowLeadDetailsForm from "./ShowLeadsForm";
import CreateConcernedPersonModal from "../../Party/Modal/CreateConcernedPerson";
const CreateFollowupForm: React.FC<{ leadId: string }> = (props) => {
  const modalContext = React.useContext(CreateFollowupModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const createFollowupMutation = useMutation(
    (values: any) => request("admin.followup.create", {}, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("followup");
        queryClient.invalidateQueries("lead");
        queryClient.invalidateQueries("leadById");
      },
    }
  );

  const { data: lead } = useQuery(
    ["leadById", props.leadId],
    async () => getLeadByIdDetail(props.leadId),
    {
      refetchOnWindowFocus: false,

      // staleTime: 5000,
    }
  );
  const [partyId, setPartyId] = React.useState(lead[0]?.partyId[0]?._id);
  const [concernedpersons, setConcernedpersons] = React.useState(
    lead[0]?.partyId[0]?.concernedpersons
  );
  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;

    values.leadId = props.leadId;
    values.content = `${values.followupPurpose} to ${values.concernedPersonId}`;
    values.description = values.clientRemarks;
    values.taskTitle = "Take next followup to -";
    values.taskType = "Lead";
    values.notificationTitle = "Action Pending!You have to take Followup";
    values.notificationBody =
      "Be in touch with client!  Click to view & take Followup";
    values.receiverId = [values.assignedPersonId];
    values.senderId = LocalStorageService.getAccount().personnel.id;
    createFollowupMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The followup has been successfully created.");
        // modalContext.setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onChange = (e: RadioChangeEvent) => {
    //  console.log("radio checked", e.target.value);
    setPartyId(e.target.value);
    e.target.value === lead[0]?.partyId[0]?._id
      ? setConcernedpersons(lead[0]?.partyId[0]?.concernedpersons)
      : e.target.value === lead[0]?.laboratoryId[0]?._id
      ? setConcernedpersons(lead[0]?.laboratoryId[0]?.concernedpersons)
      : setConcernedpersons(lead[0]?.authorityId[0]?.concernedpersons);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Card>
        <Collapse
          items={[
            {
              label: (
                <Row justify={"space-between"}>
                  <Space.Compact direction="horizontal">
                    <Tag>
                      {/* {moment(lead[0].enquiryDate).format("DD-MMM-YYYY")} */}
                      {"WWC/Leads/" + lead[0].seq}
                    </Tag>

                    <Typography.Text strong>{lead[0].title}</Typography.Text>
                    <Tag color="lime">{lead[0].leadStatus}</Tag>
                  </Space.Compact>
                  <Row align={"middle"}>
                    <Typography.Text strong type="success">
                      Client:-
                    </Typography.Text>
                    <Avatar
                      src={
                        <img
                          src={
                            lead[0].partyId[0].imageUrl
                              ? s3Path + lead[0].partyId[0].imageUrl
                              : imLogoIcon
                          }
                          alt={lead[0].partyId[0]?.personName}
                        />
                      }
                    />
                    <Typography.Text strong>
                      {lead[0].partyId[0]?.companyName}
                    </Typography.Text>
                  </Row>
                  {lead[0]?.laboratoryId?.length > 0 && (
                    <Row align={"middle"}>
                      <Typography.Text strong type="danger">
                        Lab:-
                      </Typography.Text>
                      <Avatar
                        src={
                          <img
                            src={
                              lead[0].laboratoryId[0].imageUrl
                                ? s3Path + lead[0].laboratoryId[0].imageUrl
                                : imLogoIcon
                            }
                            alt={lead[0].laboratoryId[0]?.personName}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {lead[0].laboratoryId[0]?.companyName}
                      </Typography.Text>
                    </Row>
                  )}
                  {lead[0]?.authorityId?.length > 0 && (
                    <Row align={"middle"}>
                      <Typography.Text strong type="secondary">
                        Authority:-
                      </Typography.Text>
                      <Avatar
                        src={
                          <img
                            src={
                              lead[0].authorityId[0].imageUrl
                                ? s3Path + lead[0].authorityId[0].imageUrl
                                : imLogoIcon
                            }
                            alt={lead[0].authorityId[0]?.personName}
                          />
                        }
                      />
                      <Typography.Text strong>
                        {lead[0].authorityId[0]?.companyName}
                      </Typography.Text>
                    </Row>
                  )}
                </Row>
              ),
              children: <ShowLeadDetailsForm id={props.leadId} />,
            },
          ]}
          expandIconPosition="end"
        />

        <Row gutter={20}>
          <Col flex={5} span={12}>
            <Typography.Title level={5}>Followups List</Typography.Title>
            <div style={{ height: "60vh", overflow: "auto" }}>
              <FollowUpListByLead id={props.leadId} />
            </div>
          </Col>
          <Col flex={5} span={12}>
            <Row justify={"space-between"} align={"middle"}>
              <Typography.Title level={5}>Add Followups </Typography.Title>

              <Radio.Group
                onChange={onChange}
                value={partyId}
                defaultValue={partyId}
                buttonStyle="solid"
                optionType="button"
                options={[
                  {
                    label: "Client",
                    value: lead[0].partyId[0]._id,
                  },
                  {
                    label: "Laboratory",
                    value: lead[0]?.laboratoryId[0]?._id,
                    disabled: lead[0].laboratoryId.length > 0 ? false : true,
                  },
                  {
                    label: "Authority",
                    value: lead[0]?.authorityId[0]?._id,
                    disabled: lead[0]?.authorityId?.length > 0 ? false : true,
                  },
                ]}
              />
            </Row>

            <Row justify={"space-between"} gutter={10}>
              {" "}
              <Col span={6}>
                <Form.Item
                  label=" Followup Date"
                  name="followupDate"
                  style={{ fontWeight: "bold" }}
                  tooltip={{
                    title: "followupDate",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date",
                    },
                  ]}
                >
                  <DatePicker showToday format={"DD-MM-YYYY"} />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Followup Purpose"
                  style={{ fontWeight: "bold" }}
                  name="followupPurpose"
                  rules={[
                    {
                      required: true,
                      message: "Please Select FollowUp Purpose",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Select
                    placeholder="Select Purpose"
                    options={[
                      { value: "Introduction", label: "Introduction" },
                      { value: "Branding", label: "Branding" },
                      { value: "Pitching", label: "Pitching" },
                      { value: "Requirement", label: "Requirement" },
                      { value: "Proposal", label: "Proposal" },
                      { value: "Discussion", label: "Discussion" },
                      { value: "Negotiation", label: "Negotiation" },
                      { value: "Meeting", label: "Meeting" },
                      { value: "Finalisation", label: "Finalisation" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Type"
                  style={{ fontWeight: "bold" }}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select type",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Select
                    placeholder="Select Type"
                    options={[
                      { value: "Voice Call", label: "Voice Call" },
                      { value: "Video Call", label: "Video Call" },
                      { value: "Messaging", label: "Messaging" },
                      { value: "In Person", label: "In Person" },
                      { value: "Email", label: "Email" },
                      { value: "Other", label: "Other" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Concerned Person"
              name="concernedPersonId"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "concernedPerson",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Select Concern Person",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select Concerned Person"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
                dropdownRender={(menu) => (
                  <>
                    <Row justify={"end"}>
                      <CreateConcernedPersonModal
                        partyId={lead[0].partyId[0]._id}
                      />
                    </Row>
                    {menu}
                  </>
                )}
              >
                {concernedpersons?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item._id} key={i}>
                      <Row justify={"space-between"}>
                        <Col>
                          {item.name}
                          <Tag>{item.designation}</Tag>
                        </Col>
                        <Space.Compact direction="vertical">
                          <Typography.Text type="success">
                            <PhoneOutlined /> +91-{item.mobile}
                          </Typography.Text>
                          <Typography.Text type="warning">
                            {" "}
                            <MailOutlined /> {item.email}
                          </Typography.Text>
                        </Space.Compact>
                      </Row>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            {/* </Row> */}
            <Form.Item
              label="Client Remarks"
              name="clientRemarks"
              style={{ fontWeight: "bold" }}
              tooltip={{
                title: "clientRemarks",
                icon: <InfoCircleOutlined />,
              }}
              rules={[
                {
                  required: true,
                  message: "Please Enter Remarks",
                },
              ]}
            >
              <TextArea placeholder="Enter Client Remarks" />
            </Form.Item>
            <Row justify={"space-between"} gutter={10}>
              {" "}
              <Col span={6}>
                <Form.Item
                  label="Next Followup Date"
                  name="nextFollowupDate"
                  style={{ fontWeight: "bold" }}
                  tooltip={{
                    title: "nextFollowupDate",
                    icon: <InfoCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date",
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Lead Status"
                  style={{ fontWeight: "bold" }}
                  name="leadStatus"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Status",
                    },
                  ]}
                  // valuePropName="checked"
                >
                  <Select
                    placeholder="Select Status"
                    options={[
                      { value: "Open", label: "Open" },
                      { value: "Interested", label: "Interested" },
                      { value: "Quoted", label: "Quoted" },
                      { value: "Converted", label: "Converted" },
                      { value: "Cancelled", label: "Cancelled" },
                      { value: "On Hold", label: "On Hold" },
                      { value: "Not Interested", label: "Not Interested" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={10}>
                {" "}
                <Form.Item
                  label="Next Assign To"
                  style={{ fontWeight: "bold" }}
                  name="assignedPersonId"
                  tooltip={{
                    title: "Person ",
                    icon: <InfoCircleOutlined />,
                  }}
                >
                  <Select
                    // onSelect={OnPersonSelect}
                    showSearch
                    size="large"
                    popupMatchSelectWidth
                    // style={{ minHeight: 150 }}
                    placeholder="Select Person"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).includes(input)
                    }
                  >
                    {person &&
                      person?.map((item: any, i: number) => {
                        return (
                          <Select.Option
                            value={item?.id}
                            key={i}
                            // disabled={item.availability === 0 ? true : false}
                          >
                            <Row justify={"space-between"} align={"middle"}>
                              <Typography.Text strong>
                                {item?.partyId?.personName}
                              </Typography.Text>

                              <Avatar
                                src={
                                  <img
                                    src={
                                      item.imageUrl
                                        ? s3Path + item.imageUrl
                                        : imLogoIcon
                                    }
                                    alt={item?.partyId?.personName[0]}
                                  />
                                }
                              />
                            </Row>
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size="large"
                loading={formLoading}
                type="primary"
                htmlType="submit"
                shape="round"
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <br />
      {/* <FollowUpListByLead id={props.leadId} /> */}
    </Form>
  );
};

export default CreateFollowupForm;
