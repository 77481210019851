import * as React from "react";
import { Form, Input, Button, message, Switch } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageRolesModalContext } from "../Modal/ManageRoles.";

import { useQuery } from "react-query";

import { getRolesById } from "../Requests";

const ManageRolesForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageRolesModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  console.log(props.id);
  const manageRolesMutation = useMutation(
    (values: any) => request("admin.roles.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("roles");
      },
    }
  );
  const {
    isLoading,
    data: roles,
    error,
    isError,
  } = useQuery(["roles", props.id], async () => getRolesById(props.id), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageRolesMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The roles has been successfully managed.");
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  console.log(roles);
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={roles}
    >
      <Form.Item
        label="Title Name"
        name="name"
        tooltip={{
          title: "name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Status" name="status" required>
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageRolesForm;
