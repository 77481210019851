import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Upload,
  Select,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { queryClient } from "../../../";
import { ManageCredentialModalContext } from "../Modal/ManageCredential";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getCredentialById } from "../Requests";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { getAllActiveWork } from "../../../modules/Work/Requests";
import { getAllActiveParty } from "../../Party/Requests";
const { Text } = Typography;
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
const ManageCredentialForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageCredentialModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageCredentialMutation = useMutation(
    (values: any) =>
      request("admin.credential.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("credential");
      },
    }
  );
  const {
    isLoading,
    data: credential,
    error,
    isError,
  } = useQuery(
    ["credential", props.id],
    async () => getCredentialById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: party } = useQuery(["party"], async () => getAllActiveParty(), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageCredentialMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Credential has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState("");
  const [previewTitle, setPreviewTitle] = React.useState("");
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  credential.equiryDate = moment(credential.equiryDate, "YYYY-MM-DD");
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={credential}
    >
      <Form.Item
        label="Project/Product"
        name="partyId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "party",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Select Project/Product",
          },
        ]}
        hasFeedback
      >
        <Select placeholder="Select Project/Product">
          {party &&
            party?.map((item: any, i: number) => {
              return (
                <Select.Option value={item?.id} key={i}>
                  {item?.title}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Platform Name"
        name="platformName"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Platform Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ whitespace: true }, { min: 3 }]}
        hasFeedback
      >
        <Input placeholder="Enter Platform Name" />
      </Form.Item>
      <Form.Item
        label="Platform Link"
        name="platformLink"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "Platform Link",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter Platform Link",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter Platform Link" />
      </Form.Item>
      <Form.Item
        label="Username"
        name="username"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "User name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter username",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter username" />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        style={{
          fontWeight: "bold",
        }}
        tooltip={{
          title: "password",
          icon: <InfoCircleOutlined />,
        }}
        rules={[
          {
            required: true,
            message: "Please Enter password",
          },
          { whitespace: true },
          { min: 3 },
        ]}
        hasFeedback
      >
        <Input placeholder="Enter password" />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Credential
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageCredentialForm;
