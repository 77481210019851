import * as React from "react";
import CreatePartyForm from "../Form/CreatePartyForm";
import { Modal, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateMobilePartyForm from "../Form/CreateMobilePartyForm";
import { isMobile } from "react-device-detect";

export const CreatePartyModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreatePartyModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreatePartyModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add Party
      </Button>
      <Modal
        title="Create Party"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        centered
      >
        {isMobile ? <CreateMobilePartyForm /> : <CreatePartyForm />}
      </Modal>
    </CreatePartyModalContext.Provider>
  );
};

export default CreatePartyModal;
