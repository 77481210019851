import * as React from "react";
import { Modal, Button, Tooltip } from "antd";
import ManagePriceListForm from "../Form/ManagePriceListForm";
import { PlusOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

export const ManagePriceListModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const PriceListManageModal: React.FC<{ id: string; buttonType?: any }> = ({
  id,
  buttonType,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => setIsModalVisible(true);

  const handleOk = () => setIsModalVisible(false);

  const handleCancel = () => setIsModalVisible(false);

  return (
    <ManagePriceListModalContext.Provider value={{ setIsModalVisible }}>
      <Tooltip title="Edit/Update">
        <Button
          onClick={showModal}
          type={buttonType ? buttonType : "primary"}
          shape="circle"
          icon={<PlusOutlined />}
        >
          Add PriceList
        </Button>
      </Tooltip>
      <Modal
        title="Manage PriceList"
        visible={isModalVisible}
        onOk={handleOk}
        width={1000}
        onCancel={handleCancel}
        footer={null}
      >
        <ManagePriceListForm id={id} />
      </Modal>
    </ManagePriceListModalContext.Provider>
  );
};

export default PriceListManageModal;
