import { TRoute } from "../../utils/routes";
import DepartmentPageHome from "./Page/Home";

const GalleryRouter: TRoute[] = [
  {
    path: "/gallery",
    name: "gallery",
    title: "gallery",
    component: DepartmentPageHome,
  },
];

export default GalleryRouter;
