import { TRoute } from "../../utils/routes";
import QuotationPageHome from "./Page/Home";
import QuotationPDF from "./Form/Quotation";

const QuotationRouter: TRoute[] = [
  {
    path: "/quotation",
    name: "quotation",
    title: "quotation",
    component: QuotationPageHome,
  },

  {
    path: "/quotationpdf",
    name: "quotationpdf",
    title: "quotationpdf",
    component: QuotationPDF,
  },
];

export default QuotationRouter;
