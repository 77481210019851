import * as React from "react";
import { Form, Input, Button, message, Switch } from "antd";
import { request } from "../../../../utils/services";
import { useMutation } from "react-query";
import { InfoCircleOutlined } from "@ant-design/icons";
import { queryClient } from "../../../../../src";
import { ManageDepartmentModalContext } from "../Modal/ManageDepartment";

import { useQuery } from "react-query";

import { getDepartmentById } from "../Requests";
import LocalStorageService from "../../../../utils/local-storage";

const ManageDepartmentForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageDepartmentModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  console.log(props.id);
  const manageDepartmentMutation = useMutation(
    (values: any) =>
      request("admin.department.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("department");
      },
    }
  );
  const {
    isLoading,
    data: department,
    error,
    isError,
  } = useQuery(
    ["department", props.id],
    async () => getDepartmentById(props.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
    setFormLoading(true);
    values.updatedBy = LocalStorageService.getAccount().id;

    manageDepartmentMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The department has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  console.log(department);
  const [form] = Form.useForm();
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={department}
      form={form}
    >
      <Form.Item
        label="Department Name"
        name="departmentName"
        tooltip={{
          title: "Department Name",
          icon: <InfoCircleOutlined />,
        }}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Status"
        name="isActive"
        valuePropName="checked"
        required
      >
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageDepartmentForm;
