import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { PrintableBodyWrapper } from "./Quotation.styled";
import ShowQuotationDetailsForm from "./ShowQuotationForm";
import { Button, Col, Row } from "antd";
const QuotationPDF: React.FC<RouteComponentProps> = (props) => {
  //   let accessAuth = getAccessAuthrorization("quotation");
  const location = useLocation();
  let id: string = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const { push } = useHistory();

  return (
    <PrintableBodyWrapper>
      {" "}
      <ShowQuotationDetailsForm id={id} />{" "}
      <Row>
        <Col span={12} offset={10}>
          <Button
            className="no-print"
            type="primary"
            onClick={() => window.print()}
            style={{ margin: "1rem" }}
          >
            Print Now
          </Button>
          <Button
            className="no-print"
            onClick={() => push("/quotation")}
            style={{ margin: "1rem" }}
          >
            Back
          </Button>
        </Col>
      </Row>
    </PrintableBodyWrapper>
  );
};

export default withRouter(QuotationPDF);
