import { path } from "./path";

export const workRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createWork",
    module: "work",
    alias: "admin.work.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateWork/:id",
    module: "work",
    alias: "admin.work.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveWork",
    module: "work",
    alias: "admin.work.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllWork",
    module: "work",
    alias: "admin.work.list",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllWorkWithDetails",
    module: "work",
    alias: "admin.work.details",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneWork/:id",
    module: "work",
    alias: "admin.work.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findOneWorkWithDetail/:id",
    module: "work",
    alias: "admin.work.id.detail",
    isSubDomain: true,
  },
];
