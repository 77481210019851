import moment from "moment";
import * as React from "react";

import { useQuery } from "react-query";
import type { ColumnsType } from "antd/lib/table";

import { Space, Table, Tag, Image, Empty, Typography } from "antd";

import { getAllQuotation } from "../Requests";
import { getAccessAuthrorization } from "../../../components/access";
import QuotationViewModal from "../Modal/ShowQuotationDetails";
import ManageQuotationModal from "../Modal/ManageQuotation";
import { s3Path } from "../../../utils/s3Config/s3upload";

interface DataType {
  id: number;
  name: any;
}
type DataIndex = keyof DataType;
const QuotationList: React.FC = () => {
  let accessAuth = getAccessAuthrorization("quotation");

  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(["quotation"], () => getAllQuotation(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  if (isLoading || isError) {
    return <div>Loading</div>;
  }
  const column: ColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any) => moment(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Sequence",
      dataIndex: "seq",
      key: "seq",
      render: (text: any) => <Tag>WWC/Q/{text}</Tag>,
    },

    {
      title: "Party",
      dataIndex: "partyId",
      key: "partyId",
      render: (_: any, i: any) => (
        <Space>
          <Space.Compact direction="vertical">
            <Typography.Text
              style={{
                fontWeight: "bold",
              }}
            >
              {i?.partyId.personName}
            </Typography.Text>
            <Typography.Text
              type="danger"
              code
              style={{ width: 125, overflow: "auto", height: 25 }}
            >
              {i?.partyId?.companyName}
            </Typography.Text>
            <Typography.Text code>+91-{i?.partyId.phoneNo}</Typography.Text>
          </Space.Compact>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (text: any) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },

    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (id: any) => (
        <Space size={10}>
          <QuotationViewModal id={id} />
          {/* <ManageQuotationModal id={id} /> */}
          {accessAuth[0].includes("Edit") && <ManageQuotationModal id={id} />}
        </Space>
      ),
    },
  ];

  return <Table size="small" columns={column} dataSource={response} />;
};

export default QuotationList;
