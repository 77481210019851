import { request } from "../../../utils/services";

export const getAllLead = async () => {
  const { data } = await request("admin.lead.list");
  return data;
};

export const getLeadById = async (id: string) => {
  const { data } = await request("admin.lead.id", { id });
  return data;
};
export const getLeadByPartyId = async (id: string) => {
  const { data } = await request("admin.lead.party.id", { id });
  return data;
};
export const getLeadByIdDetail = async (id: string) => {
  const { data } = await request("admin.lead.id.detail", { id });
  return data;
};
// ......................................Followup Index ...........................................

export const getFollowupByLeadId = async (id: string) => {
  const { data } = await request("admin.followup.lead.id", { id });
  return data;
};
export const getAllFollowup = async (page: number = 1, size: number = 10) => {
  let query: {
    page: number;
    size: number;
  } = {
    page,
    size,
  };
  const { data } = await request(
    "admin.followup.list",
    {},
    {
      query,
    }
  );
  return data;
};
export const getFollowupByWorkId = async (id: string) => {
  const { data } = await request("admin.followup.work.id", { id });
  return data;
};

// .......................................Quotation Index .........................................

export const getAllActiveQuotation = async () => {
  const { data } = await request("admin.quotation.list.active");
  return data;
};

export const getAllQuotation = async () => {
  const { data } = await request("admin.quotation.list");
  return data;
};

export const getQuotationById = async (id: string) => {
  const { data } = await request("admin.quotation.id", { id });
  return data;
};

//...........................................TimeLIne.............................................

// export const getAllActiveTimelime = async () => {
//   const { data } = await request("admin.timeline .list.active");
//   return data;
// };

export const getLeadTimelineByLeadId = async (id: String) => {
  const { data } = await request("admin.lead.timeline.id", { id });
  return data;
};
