import * as React from "react";
import {
  Row,
  Col,
  Statistic,
  Divider,
  Steps,
  Descriptions,
  Space,
  Typography,
  Badge,
  Card,
  Tag,
  Avatar,
  Button,
  message,
  Modal,
  Select,
} from "antd";
import { useMutation, useQuery } from "react-query";
import { getTaskById } from "../Requests";
import LocalStorageService from "../../../utils/local-storage";
import moment from "moment";
import { s3Path } from "../../../utils/s3Config/s3upload";
import parse from "html-react-parser";
import CreateFollowupModal from "../../Leads/Modal/CreateFollowup";

import ManageReplyForm from "../../Reply/Form/ManageReplyForm";
import {
  DeploymentUnitOutlined,
  FlagOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ShowWorkDetailsForm from "../../Work/Form/ShowWorkForm";
import { request } from "../../../utils/services";
import { queryClient } from "../../../../src";
import ReactTimeAgo from "react-time-ago";
import { imLogoIcon } from "../../../constants/logo";
import CreateFollowupForm from "../../Leads/Form/CreateFollowupForm";
import LeadsViewModal from "../../Leads/Modal/ShowLeadsDetails";
const { Step } = Steps;

const ShowTaskDetailsForm: React.FC<{ id: string }> = (props) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());

  const {
    isLoading,
    data: response,
    error,
    isError,
  } = useQuery(["TaskById", props.id], async () => getTaskById(props.id), {
    refetchOnWindowFocus: false,
  });
  console.log(response);
  // console.log(props.id);
  return (
    <>
      <Badge.Ribbon text={response[0]?.type} style={{ marginRight: 50 }}>
        <Card size="small" style={{ marginRight: 50 }}>
          <Row>
            <Typography.Text code>
              {moment(response[0]?.dueDate).format("DD-MMM-YYYY")}
            </Typography.Text>{" "}
            <Typography.Text strong>{response[0]?.title}</Typography.Text>{" "}
            {response[0]?.leadId.length > 0 && (
              <>
                <Typography.Text code>
                  {response[0]?.leadId[0]?.partyId[0].personName}
                </Typography.Text>

                {response[0]?.leadId[0]?.partyId[0].companyName && (
                  <Typography.Text code>
                    {response[0]?.leadId[0]?.partyId[0].companyName}
                  </Typography.Text>
                )}
                <Tag color="gold" style={{ marginLeft: 20 }}>
                  {response[0]?.leadId[0]?.leadStatus}
                </Tag>
              </>
            )}
            <Tag
              color={
                response[0].status === "To Do"
                  ? "orange"
                  : response[0].status === "In Progress"
                  ? "blue"
                  : response[0].status === "On Hold"
                  ? "volcano"
                  : response[0].status === "In Review"
                  ? "cyan"
                  : "lime"
              }
            >
              {response[0]?.status}
            </Tag>
          </Row>
        </Card>
      </Badge.Ribbon>
      <ShowTaskData data={response[0]} />
    </>
  );
};

export default ShowTaskDetailsForm;

export const ShowTaskData: React.FC<{ data: any }> = ({ data }) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [isReply, setIsReply] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [isStory, setIsReply] = React.useState(false);
  const manageTaskMutation = useMutation(
    (values: any) => request("admin.task.update", { id: data._id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("task");
        queryClient.invalidateQueries("notification");
      },
    }
  );
  // console.log(data.createdBy);
  const onFinish = async (values: any) => {
    values.taskId = data._id;
    // values.attachment = galley;
    values.updatedBy = user.id;
    //Notification
    values.notificationTitle = "Attn.! Task Assigned by you, Status Updated";
    values.notificationBody =
      "Task: " + data.title + "!  Click to view Changes in task";
    values.receiverId = [data.assignedBy];
    values.senderId = LocalStorageService.getAccount().personnel.id;
    manageTaskMutation
      .mutateAsync(values)
      .then(() => {
        message.success("The task status has been successfully updated.");
        setIsEdit(!isEdit);
      })
      .catch((e: any) => {
        message.error(e);
      });
  };
  return (
    <>
      <Row justify={"space-between"}>
        {data?.assignedPersonId && (
          <Col>
            Assigned To:
            <Avatar
              src={
                <img
                  src={
                    data?.assignedPersonId[0]?.party[0]?.imageUrl
                      ? s3Path + data?.assignedPersonId[0]?.party[0]?.imageUrl
                      : imLogoIcon
                  }
                  alt={data?.assignedPersonId[0]?.party[0]?.personName}
                />
              }
            />
            <Tag>{data?.assignedPersonId[0]?.party[0]?.personName}</Tag>
          </Col>
        )}

        <Col>
          {" "}
          {data.workId.length > 0 && (
            <>
              {" "}
              {/* View Work Details:
              <WorkViewModal id={data.workId} /> */}
              <Tag color="red" onClick={() => setOpen(true)}>
                <DeploymentUnitOutlined />
                {data.workId[0].title}
              </Tag>
              <Modal
                open={open}
                width={"80%"}
                onCancel={() => setOpen(!open)}
                onOk={() => setOpen(!open)}
              >
                <ShowWorkDetailsForm id={data.workId[0]._id} />
              </Modal>
            </>
          )}
        </Col>

        {data?.createdBy && (
          <Col>
            Assigned By:
            <Avatar
              src={
                <img
                  src={
                    data?.createdBy[0]?.party[0]?.imageUrl
                      ? s3Path + data?.createdBy[0]?.party[0]?.imageUrl
                      : imLogoIcon
                  }
                  alt={data?.createdBy[0]?.party[0]?.personName}
                />
              }
            />
            <Tag>{data?.createdBy[0]?.party[0]?.personName}</Tag>
          </Col>
        )}

        <Col>
          {!isEdit ? (
            <Tag
              color={
                data.status === "To Do"
                  ? "orange"
                  : data.status === "In Progress"
                  ? "blue"
                  : data.status === "On Hold"
                  ? "volcano"
                  : data.status === "In Review"
                  ? "cyan"
                  : "lime"
              }
            >
              {data?.status}
              <EditOutlined onClick={() => setIsEdit(!isEdit)} />
            </Tag>
          ) : (
            <Select
              placeholder="Select Task Status"
              defaultValue={data.status}
              size="small"
              onChange={(value) => onFinish({ status: value })}
            >
              <Select.Option value="To Do">To Do</Select.Option>
              <Select.Option value="In Progress">In Progress</Select.Option>
              <Select.Option value="On Hold">On Hold</Select.Option>
              <Select.Option value="In Review">In Review</Select.Option>
              <Select.Option value="Done">Completed</Select.Option>
            </Select>
          )}
        </Col>
      </Row>

      <Row justify={"space-between"} style={{ padding: 10 }}>
        <Steps
          direction="horizontal"
          size="small"
          style={{ padding: 10 }}
          current={
            data.status === "To Do"
              ? 0
              : data.status === "In Progress"
              ? 1
              : data.status === "On Hold"
              ? 2
              : data.status === "In Review"
              ? 3
              : 4
          }
          items={[
            { title: "To Do" },
            { title: "In Progress" },
            { title: "On Hold" },
            { title: "In Review" },
            { title: "Done" },
          ]}
        />
        <Col span={24} style={{ padding: 10 }}>
          {data.remarks && parse(data.remarks)}

          {data?.leadId?.length > 0 && (
            <Row justify={"space-around"} style={{ margin: 20 }}>
              <Col span={12}>
                View Lead Details:-
                <LeadsViewModal id={data.leadId[0]._id} />
              </Col>
              <Col span={12}>
                Take Followup:-
                <CreateFollowupModal leadId={data.leadId[0]._id} />
              </Col>
            </Row>
          )}
          <Row>
            {data.reply &&
              data.reply.map((element: any, i: any) => {
                return (
                  <>
                    <Row align="top" style={{ width: "100%" }}>
                      <Col span={1}>
                        <Avatar
                          size={"large"}
                          src={
                            <img
                              src={
                                s3Path +
                                element?.createdBy[0]?.party[0]?.imageUrl
                              }
                              alt={"R"}
                            />
                          }
                        />
                      </Col>
                      <Col style={{ paddingLeft: 8 }} span={18}>
                        <Space.Compact direction="vertical">
                          <Typography.Text strong>
                            {element?.createdBy[0]?.name === user.name
                              ? "Me/MySelf "
                              : element?.createdBy[0]?.name}
                            <Tag>
                              <ReactTimeAgo
                                date={element?.createdAt}
                                locale="en-IN"
                                // timeStyle="twitter"
                              />
                            </Tag>
                          </Typography.Text>
                          <Typography.Text>
                            {element?.comment && parse(element?.comment)}
                          </Typography.Text>
                        </Space.Compact>
                      </Col>
                      <Col span={5}>
                        <Avatar.Group>
                          {element.tagto?.map((data: any) => (
                            <>
                              <Avatar
                                src={
                                  <img src={s3Path + data.imageUrl} alt="T" />
                                }
                              />
                            </>
                          ))}
                        </Avatar.Group>
                      </Col>
                    </Row>
                    <Divider />
                  </>
                );
              })}
          </Row>
          <Row justify={"end"}>
            <Button onClick={() => setIsReply(!isReply)}>Comment/Reply</Button>
          </Row>
          {isReply && <ManageReplyForm taskId={data._id} />}
        </Col>
      </Row>
    </>
  );
};
