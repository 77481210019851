import { path } from "./path";

export const documentRoutes = [
  {
    method: "post",
    path: path + "/api/admin/createDocument",
    module: "document",
    alias: "admin.document.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateDocument/:id",
    module: "document",
    alias: "admin.document.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveDocument",
    module: "document",
    alias: "admin.document.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllDocument",
    module: "document",
    alias: "admin.document.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneDocument/:id",
    module: "document",
    alias: "admin.document.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDocumentByPartyId/:id",
    module: "document",
    alias: "admin.document.party.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDocumentByTaskId/:id",
    module: "document",
    alias: "admin.document.task.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDocumentByTagTo/:id",
    module: "document",
    alias: "admin.document.tag.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findDocumentByWorkId/:id",
    module: "document",
    alias: "admin.document.work.id",
    isSubDomain: true,
  }
];
