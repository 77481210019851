import * as React from "react";

import { Modal, Button, Row, Col, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CreateDocument from "../Form/CreateDocumentForm";
import DocumentListByParty from "../Component/ListByPartyId";
import DocumentListByWork from "../Component/ListByWorkId";

export const CreateDocumentModalContext = React.createContext<{
  setIsModalVisible: (active: boolean) => void;
}>({
  setIsModalVisible: () => true,
});

const CreateDocumentModal: React.FC<{
  partyId?: string;
  workId?: string;
  leadId?: string;
  serviceId?: string;
}> = ({ partyId, workId, leadId, serviceId }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <CreateDocumentModalContext.Provider value={{ setIsModalVisible }}>
      <Button
        onClick={showModal}
        type="primary"
        shape="round"
        icon={<PlusOutlined />}
      >
        Add Document
      </Button>
      <Modal
        title="Add Document"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={"90vw"}
      >
        <Row>
          <Col flex={2}>
            <CreateDocument
              partyId={partyId}
              workId={workId}
              leadId={leadId}
              serviceId={serviceId}
            />
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: 'List By Party',
                  key: '1',
                  children: partyId && <DocumentListByParty id={partyId} />,
                },
                {
                  label: 'List By Work',
                  key: '2',
                  children: workId && <DocumentListByWork id={workId} />,

                },

              ]}
            />
            {/* {partyId && <DocumentListByParty id={partyId} />} */}
          </Col>
        </Row>
      </Modal>
    </CreateDocumentModalContext.Provider>
  );
};

export default CreateDocumentModal;
