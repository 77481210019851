import * as React from "react";
import {
  Form,
  Button,
  message,
 
  Select,

} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
 
} from "@ant-design/icons";
import { queryClient } from "../../..";


import { useQuery } from "react-query";


import { getServiceById } from "../Requests";



import { AddProductModalContext } from "../Modal/AddProduct";
import { getAllProduct } from "../../Product/Requests";


const AddProductForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(AddProductModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState<string>();
  const [galley, setGallery] = React.useState<string>();
 
  const manageServiceMutation = useMutation(
    (values: any) => request("admin.service.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("service");
      },
    }
  );
  const {
    isLoading,
    data: service,
    error,
    isError,
  } = useQuery(["service", props.id], async () => getServiceById(props.id), {
    refetchOnWindowFocus: false,
  });
 const { data: product } = useQuery(
    ["product"],
    async () => getAllProduct(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const onFinish = async (values: any) => {
  
    setFormLoading(true);

    manageServiceMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Product has been successfully Added in Service.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();
 
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={service}
    >
   
         
      

          <Form.Item
            label="Service Product"
            name="productId"
            style={{ fontWeight: "bold" }}
            tooltip={{
              title: "productId",
              icon: <InfoCircleOutlined />,
            }}
           
            hasFeedback
          >
            <Select placeholder="Select Product" mode="multiple">
              {product &&
                product?.map((item: any, i: number) => {
                  return (
                    <Select.Option value={item?.id} key={i}>
                      {item?.title}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
    

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddProductForm;
