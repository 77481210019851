import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../components/Card";
import PersonnelList from "../Component/Home/List";

import { Empty, Tabs } from "antd";
import CreatePersonnelForm from "../Form/CreatePersonnelForm";
import AttendanceForm from "../Form/AttendanceForm";
import { getAccessAuthrorization } from "../../../../components/access";
const onChange = (key: string) => {
  console.log(key);
};
const PersonnelPageHome: React.FC<RouteComponentProps> = () => {
  let accessAuth = getAccessAuthrorization("personnel");

  return (
    <React.Fragment>
      <Card>
        <CardHeader>
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Tabs onChange={onChange}>
            <Tabs.TabPane tab="Personnel List" key="item-1">
              {" "}
              {/* <PersonnelList /> */}
              {accessAuth[0].includes("View") ? (
                <PersonnelList />
              ) : (
                <Empty
                  image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                  imageStyle={{ height: 60 }}
                  description={<span>You are Not Authorised!</span>}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab=" Add Personnel " key="item-2">
              {/* <CreatePersonnelForm /> */}
              {accessAuth[0].includes("Create") && <CreatePersonnelForm />}
            </Tabs.TabPane>
            <Tabs.TabPane tab=" Attendance Table " key="item-3">
              <AttendanceForm />
            </Tabs.TabPane>
          </Tabs>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default withRouter(PersonnelPageHome);
