import {
  Avatar,
  Badge,
  Card,
  Col,
  Collapse,
  CollapseProps,
  Row,
  Segmented,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";

import moment from "moment";
import * as React from "react";
import { useQuery } from "react-query";
import { getAccessAuthrorization } from "../../../components/access";

import { getTaskAssignedBy, getTaskByPersonnel } from "../../Task/Requests";
import LocalStorageService from "../../../utils/local-storage";

import { s3Path } from "../../../utils/s3Config/s3upload";

import CreateTaskModal from "../../Task/Modal/CreateTask";

import ReactTimeAgo from "react-time-ago";
import TaskStatusManageModal from "../../Task/Modal/ManageTaskStatus";
import * as AntIcons from "@ant-design/icons";
import { ShowTaskData } from "../../Task/Form/ShowTaskDetailsForm";
import { imLogoIcon } from "../../../constants/logo";

const TodoList: React.FC<{ id: string }> = (props) => {
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  const [isReply, setIsReply] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState(
    moment().format("DD-MM-YYYY")
  );
  const [assignedByUser, setAssignedByUser] = React.useState(false);

  let accessAuth = getAccessAuthrorization("task");

  const {
    isLoading,
    isError,
    data: task,
  } = useQuery(["task"], () => getTaskByPersonnel(props.id), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });
  const {
    //  isLoading,
    //  isError,
    data: taskAssignedBy,
  } = useQuery(["taskAssignedBy"], () => getTaskAssignedBy(user.id), {
    refetchOnWindowFocus: true,

    keepPreviousData: true,
    staleTime: 100,
  });

  // const genExtra = (id: any) => <TaskStatusManageModal id={id} />;

  const getItemList = (list: any, status?: any) => {
    let filteredList = list.filter((element: any) => element.status === status);
    let tempList = status ? filteredList : list;
    const ItemList: CollapseProps["items"] =
      tempList &&
      tempList.map((item: any, i: any) => {
        return {
          key: i,
          label: (
            <Badge.Ribbon
              text={item?.type}
              color={
                item.type === "Lead"
                  ? "#ff1677"
                  : item.type === "Work"
                  ? "#1136ddba"
                  : item.type === "Utilities"
                  ? "#F29084"
                  : item.type === "Other"
                  ? "#49668F"
                  : "#16FFF3"
              }
              style={{ marginRight: 50 }}
            >
              <Card size="small" style={{ marginRight: 50 }}>
                <div style={{ marginRight: 100 }}>
                  <Row justify={"space-between"}>
                    <Col>
                      <Typography.Text code>
                        {moment(item?.dueDate).format("DD-MMM-YYYY")}
                      </Typography.Text>{" "}
                      <Typography.Text strong>{item?.title}</Typography.Text>{" "}
                      {item?.leadId?.length > 0 && (
                        <>
                          <Typography.Text code>
                            {item?.leadId[0]?.partyId[0].personName}
                          </Typography.Text>

                          {item?.leadId[0]?.partyId[0].companyName && (
                            <Typography.Text code>
                              {item?.leadId[0]?.partyId[0].companyName}
                            </Typography.Text>
                          )}
                          <Tag color="magenta" style={{ marginLeft: 20 }}>
                            {item?.leadId[0]?.leadStatus}
                          </Tag>
                        </>
                      )}
                      <Tag
                        color={
                          item?.status === "To Do"
                            ? "orange"
                            : item?.status === "In Progress"
                            ? "blue"
                            : item?.status === "On Hold"
                            ? "volcano"
                            : item?.status === "In Review"
                            ? "cyan"
                            : "lime"
                        }
                      >
                        {item?.status}
                      </Tag>
                      <ReactTimeAgo
                        date={item.createdAt}
                        locale="en-IN"
                        // timeStyle="twitter"
                      />
                    </Col>

                    {assignedByUser ? (
                      <Col>
                        <Avatar
                          src={
                            <img
                              src={
                                item?.assignedPersonId[0]?.party[0]?.imageUrl
                                  ? s3Path +
                                    item?.assignedPersonId[0]?.party[0]
                                      ?.imageUrl
                                  : imLogoIcon
                              }
                              alt={
                                item?.assignedPersonId[0]?.party[0]?.personName
                              }
                            />
                          }
                        />
                        {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                      </Col>
                    ) : (
                      item?.createdBy.length > 0 && (
                        <Col>
                          <Avatar
                            src={
                              <img
                                src={
                                  item?.createdBy[0]?.party[0]?.imageUrl
                                    ? s3Path +
                                      item?.createdBy[0]?.party[0]?.imageUrl
                                    : imLogoIcon
                                }
                                alt={item?.createdBy[0]?.party[0]?.personName}
                              />
                            }
                          />
                          {/* <Tag>{item?.createdBy[0]?.party[0]?.personName}</Tag> */}
                        </Col>
                      )
                    )}
                  </Row>
                </div>
              </Card>
            </Badge.Ribbon>
          ),
          children: <ShowTaskData data={item} />,
          // extra: genExtra(item._id),
        };
      });
    return ItemList;
  };
  const [todo, setTodo] = React.useState("Today");

  let allList = assignedByUser
    ? getItemList(taskAssignedBy)
    : getItemList(task);
  let todoList: any = assignedByUser
    ? getItemList(taskAssignedBy, "To Do")
    : getItemList(task, "To Do");
  let inProcessList = assignedByUser
    ? getItemList(taskAssignedBy, "In Progress")
    : getItemList(task, "In Progress");
  let onHoldList = assignedByUser
    ? getItemList(taskAssignedBy, "On Hold")
    : getItemList(task, "On Hold");
  let inReviewList = assignedByUser
    ? getItemList(taskAssignedBy, "In Review")
    : getItemList(task, "In Review");
  let completedList = assignedByUser
    ? getItemList(taskAssignedBy, "Done")
    : getItemList(task, "Done");
  let todayList: any = getItemList(
    task.filter(
      (t: any) =>
        moment(t.dueDate).format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")
    ),
    "To Do"
  );
  let pendingList: any = getItemList(
    task.filter((t: any) => moment(t.dueDate).isBefore(moment())),
    "To Do"
  );
  let upcomingList: any = getItemList(
    task.filter((t: any) => moment(t.dueDate).isAfter(moment())),
    "To Do"
  );

  // console.log(todo);
  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Space direction="vertical">
            <Segmented
              options={[
                { value: "For Me", label: "For Me" },

                { value: "By Me", label: "By Me" },
              ]}
              onChange={(value) =>
                setAssignedByUser(value === "By Me" ? true : false)
              }
            />
            <CreateTaskModal />
          </Space>
        }
        defaultActiveKey="To Do"
        tabPosition="left"
        items={[
          {
            key: "To Do",
            label: (
              <Badge count={todoList?.length} offset={[12, 0]} color="orange">
                To Do
              </Badge>
            ),
            children: (
              <div style={{ height: "85vh", overflow: "auto" }}>
                {assignedByUser ? (
                  <Collapse
                    accordion
                    items={todoList}
                    expandIconPosition="end"
                  />
                ) : (
                  <>
                    <Segmented
                      size="large"
                      options={[
                        {
                          label: (
                            <Badge
                              count={todayList?.length}
                              // offset={[12, 0]}
                              color="orange"
                            >
                              Today
                            </Badge>
                          ),
                          value: "Today",
                          icon: <AntIcons.BarsOutlined />,
                        },
                        {
                          label: (
                            <Badge
                              count={pendingList?.length}
                              // offset={[12, 0]}
                              color="yellow"
                            >
                              Pending
                            </Badge>
                          ),
                          value: "Pending",
                          icon: <AntIcons.ClockCircleOutlined />,
                        },
                        {
                          label: (
                            <Badge
                              count={upcomingList?.length}
                              // offset={[12, 0]}
                              color="blue"
                            >
                              Upcoming
                            </Badge>
                          ),
                          value: "Upcoming",
                          icon: <AntIcons.AppstoreOutlined />,
                        },
                      ]}
                      onChange={(value) => {
                        setTodo(value);
                      }}
                    />
                    {todo === "Today" ? (
                      <Collapse
                        accordion
                        items={todayList}
                        expandIconPosition="end"
                      />
                    ) : todo === "Pending" ? (
                      <Collapse
                        accordion
                        items={pendingList}
                        expandIconPosition="end"
                      />
                    ) : (
                      <Collapse
                        accordion
                        items={upcomingList}
                        expandIconPosition="end"
                      />
                    )}
                  </>
                )}
              </div>
            ),
          },
          {
            key: "In Progress",
            label: (
              <Badge
                count={inProcessList?.length}
                offset={[12, 0]}
                color="blue"
              >
                In Progress
              </Badge>
            ),
            children: (
              <div style={{ height: "85vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={inProcessList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "On Hold",
            label: (
              <Badge
                count={onHoldList?.length}
                offset={[12, 0]}
                color="volcano"
              >
                On Hold
              </Badge>
            ),
            children: (
              <div style={{ height: "85vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={onHoldList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "In Review",
            label: (
              <Badge count={inReviewList?.length} offset={[12, 0]} color="cyan">
                In Review
              </Badge>
            ),
            children: (
              <div style={{ height: "85vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={inReviewList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "Completed",
            label: (
              <Badge
                count={completedList?.length}
                offset={[12, 0]}
                color="lime"
              >
                Completed
              </Badge>
            ),
            children: (
              <div style={{ height: "85vh", overflow: "auto" }}>
                <Collapse
                  accordion
                  items={completedList}
                  expandIconPosition="end"
                />
              </div>
            ),
          },
          {
            key: "All Tasks",
            label: (
              <Badge count={allList?.length} offset={[12, 0]} color="purple">
                All Tasks
              </Badge>
            ),
            children: (
              <div style={{ height: "85vh", overflow: "auto" }}>
                <Collapse accordion items={allList} expandIconPosition="end" />
              </div>
            ),
          },
        ]}
      />
      {/* <Table size="small" columns={column} dataSource={task} /> */}
    </>
  );
};

export default TodoList;
