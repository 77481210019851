import * as React from "react";
import { Form, Input, Button, message, Select, Row, Col, Checkbox } from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  BankOutlined,
  HomeOutlined,
  MobileOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../../";
import { ManageAccessModalContext } from "../Modal/ManageAccess";

import { useQuery } from "react-query";
import { getAccessById, getAccessByRoleId } from "../Requests";
import LocalStorageService from "../../../utils/local-storage";
import { getAllRoles } from "../../Roles/Requests";
import { getAllCollection } from "../../Collection/Requests";

const ManageAccessForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(ManageAccessModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  const manageAccessMutation = useMutation(
    (values: any) => request("admin.access.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("access");
      },
    }
  );
  const {
    isLoading,
    isError,
    data: roles,
  } = useQuery(["roles"], () => getAllRoles(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const {
    //isLoading,
    //isError,
    data: collections,
  } = useQuery(["collections"], () => getAllCollection(), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });
  const {
    //isLoading,
    //isError,
    data: access,
  } = useQuery(["accessRole"], () => getAccessByRoleId(props.id), {
    refetchOnWindowFocus: false,

    keepPreviousData: true,
    staleTime: 5000,
  });

  const onFinish = async (values: any) => {
    console.log(values);

    setFormLoading(true);
    values.createdBy = LocalStorageService.getAccount().id;

    manageAccessMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The access has been successfully updated.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const options = [
    { label: "Create", value: "Create" },
    { label: "Edit", value: "Edit" },
    { label: "View", value: "View" },
    { label: "No Access", value: "NoAccess" },
  ];
  const [form] = Form.useForm();
  React.useEffect(() => {
    form.setFieldsValue({
      roleId: props.id,
      collection: access,
    });
    // access.roleId = props.id;
    // access.collection = access;
  }, []);
  var data: any = {};
  data.roleId = props.id;
  data.collection = access;
  // console.log(collections);

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      // initialValues={data}
    >
      <Form.Item
        label="Role"
        name="roleId"
        style={{ fontWeight: "bold" }}
        tooltip={{
          title: "Select Role",
          icon: <InfoCircleOutlined />,
        }}
        hasFeedback
      >
        <Select
          showSearch
          placeholder="Select Role"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            (option!.children as unknown as string).includes(input)
          }
          filterSort={(optionA: any, optionB: any) =>
            (optionA!.children as unknown as string)
              .toLowerCase()
              .localeCompare(
                (optionB!.children as unknown as string).toLowerCase()
              )
          }
          disabled
        >
          {roles &&
            roles.map((item: any, i: number) => {
              return (
                <Select.Option value={item.id} key={i}>
                  {item.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
      <h3>Collections Access </h3>
      <Form.List name="collection">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field: any, index: number) => (
              <Row
                gutter={16}
                key={field.key}
                justify="space-around"
                align="top"
              >
                <Col flex={1} span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "collectionId"]}
                        //  initialValue={collections[index].id}
                      >
                        <Select disabled>
                          {collections &&
                            collections.map((item: any, i: number) => {
                              return (
                                <Select.Option value={item.id} key={i}>
                                  {item.collectionTitle}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>
                <Col flex={1} span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item {...field} name={[field.name, "accessType"]}>
                        <Checkbox.Group
                          options={options}
                          // defaultValue={["View"]}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </Col>

                {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
              </Row>
            ))}
            {/* <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  for (let i = 0; i < collections.length; i++) {
                    // const element = array[i];
                    add();
                  }
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Access
              </Button>
            </Form.Item> */}
          </>
        )}
      </Form.List>

      {access.collection &&
        access.collection.map((item: any, i: number) => {
          return <>{item.collectionId}</>;
          // <Select.Option value={item.id} key={i}>

          // </Select.Option>
        })}

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Update Access
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ManageAccessForm;
