import { path } from "./path";

export const submenuRoutes = [
  {
    method: "post",
    path: path + "/api/admin/saveSubMenu",
    module: "submenu",
    alias: "admin.submenu.create",
    isSubDomain: true,
  },

  {
    method: "put",
    path: path + "/api/admin/updateSubMenu/:id",
    module: "submenu",
    alias: "admin.submenu.update",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllActiveSubMenu",
    module: "submenu",
    alias: "admin.submenu.list.active",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findAllSubMenu",
    module: "submenu",
    alias: "admin.submenu.list",
    isSubDomain: true,
  },

  {
    method: "get",
    path: path + "/api/admin/findOneSubMenu/:id",
    module: "submenu",
    alias: "admin.submenu.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllSubMenuByCategory/:id",
    module: "submenu",
    alias: "admin.submenu.category.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllActiveSubMenuByCategory/:id",
    module: "submenu",
    alias: "admin.active.submenu.category.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllSubMenuByService/:id",
    module: "submenu",
    alias: "admin.submenu.service.id",
    isSubDomain: true,
  },
  {
    method: "get",
    path: path + "/api/admin/findAllActiveSubMenuByService/:id",
    module: "submenu",
    alias: "admin.active.submenu.service.id",
    isSubDomain: true,
  },
];
