import * as React from "react";
import {
  Form,
  Button,
  message,
  Row,
  Typography,
  Col,
  Switch,
  Input,
  Divider,
  Upload,
  Select,
  Space,
} from "antd";
import { request } from "../../../utils/services";
import { useMutation } from "react-query";
import {
  InfoCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { queryClient } from "../../..";
import { ManageServiceModalContext } from "../Modal/ManageService";

import { useQuery } from "react-query";

import TextArea from "antd/lib/input/TextArea";

import moment from "moment";
import { getServiceById } from "../Requests";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from "antd/es/upload";

import { getCategoryByType } from "../../Category/Requests";
import { AddSopsModalContext } from "../Modal/AddSops";

const AddSopsForm: React.FC<{ id: string }> = (props) => {
  const modalContext = React.useContext(AddSopsModalContext);
  const [formLoading, setFormLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const manageServiceMutation = useMutation(
    (values: any) => request("admin.service.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("service");
      },
    }
  );
  const {
    isLoading,
    data: service,
    error,
    isError,
  } = useQuery(["service", props.id], async () => getServiceById(props.id), {
    refetchOnWindowFocus: false,
  });

  const onFinish = async (values: any) => {
    setFormLoading(true);

    manageServiceMutation
      .mutateAsync(values)
      .then(() => {
        setFormLoading(false);
        message.success("The Service has been successfully managed.");
        form.resetFields();
        modalContext.setIsModalVisible(false);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      initialValues={service}
    >
      <Typography.Title level={5}>SOPs for {service.title}</Typography.Title>

      <Form.List name="sops">
        {(fields, { add, remove }) => (
          <>
            <Space.Compact direction="vertical">
              {fields.map((field: any, index: number) => (
                <Row align={"top"}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        initialValue={index + 1}
                        style={{ marginBottom: 0, width: "6%" }}
                        name={[field.name, "stepNo"]}
                      >
                        <Input placeholder="Sno. " disabled />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        name={[field.name, "stepTitle"]}
                        style={{ marginBottom: 0, width: "30vw" }}
                      >
                        <Input placeholder="Enter Title" />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area ||
                      prevValues.sights !== curValues.sights
                    }
                  >
                    <Form.List name={[field.name, "tasks"]}>
                      {(fields, { add, remove }) => (
                        <Space direction="vertical">
                          {fields.map((field: any, index: number) => (
                            <Space direction="horizontal">
                              <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, curValues) =>
                                  prevValues.area !== curValues.area ||
                                  prevValues.sights !== curValues.sights
                                }
                              >
                                {() => (
                                  <Form.Item
                                    {...field}
                                    // initialValue={index + 1}
                                    style={{ marginBottom: 0, width: "30vw" }}
                                    name={[field.name, "title"]}
                                  >
                                    <Input
                                      placeholder="Enter Tasks "
                                      // disabled
                                    />
                                  </Form.Item>
                                )}
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}

                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Tasks
                            </Button>
                          </Form.Item>
                        </Space>
                      )}
                    </Form.List>
                  </Form.Item>

                  <DeleteOutlined onClick={() => remove(field.name)} />
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add More Steps
                </Button>
              </Form.Item>
            </Space.Compact>
          </>
        )}
      </Form.List>

      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddSopsForm;
