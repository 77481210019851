import {
  Avatar,
  Badge,
  Card,
  Col,
  Drawer,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React from "react";
import { useMutation, useQuery } from "react-query";
import ReactTimeAgo from "react-time-ago";
import { queryClient } from "../../../../src";
import CreateFollowupModal from "../../Leads/Modal/CreateFollowup";
import ShowTaskDetailsForm from "../../Task/Form/ShowTaskDetailsForm";
import ShowWorkDetailsForm from "../../Work/Form/ShowWorkForm";
import LocalStorageService from "../../../utils/local-storage";
import { s3Path } from "../../../utils/s3Config/s3upload";
import { request } from "../../../utils/services";
import { getNotificationByPersonnel } from "../Requests";
import { isMobile } from "react-device-detect";
import ShowMobileTaskDetailsForm from "../../Task/Form/ShowMobileTaskDetailsForm";
import CreateFollowupForm from "../../Leads/Form/CreateFollowupForm";
import CreateMobileFollowupForm from "../../Leads/Form/CreateMobileFollowupForm";

const NotificationList: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [taskId, setTaskId] = React.useState(undefined);
  const [workId, setWorkId] = React.useState(undefined);
  const [leadId, setLeadId] = React.useState(undefined);
  const [user, setUser] = React.useState(LocalStorageService.getAccount());
  // console.log(user);
  const {
    isLoading,
    isError,
    data: response,
  } = useQuery(
    ["notification", user.personnel.id],
    () => getNotificationByPersonnel(user.personnel.id),
    {
      refetchOnWindowFocus: true,

      keepPreviousData: true,
      staleTime: 100,
    }
  );

  const manageNotificationMutation = useMutation(
    (values: any) =>
      request("admin.notification.update", { id: values.id }, { isRead: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notification");
      },
    }
  );

  return (
    <>
      {response &&
        response.map((item: any, i: any) => {
          return (
            <>
              <Card
                size="small"
                style={{
                  backgroundColor: item?.isRead ? "ButtonShadow" : "beige",
                  // width: "100%",
                  marginBottom: 2,
                }}
                onClick={() => {
                  item?.isRead === false &&
                    manageNotificationMutation.mutateAsync({ id: item.id });
                  setOpen(true);
                  setTaskId(item?.taskId);
                  setWorkId(item?.workId);
                  setLeadId(item?.leadId);
                }}
              >
                <Row align={"top"} gutter={5}>
                  <Col span={3}>
                    {" "}
                    <Avatar
                      size={"large"}
                      shape="square"
                      src={
                        <img
                          src={s3Path + item?.senderId?.imageUrl}
                          alt={"R"}
                        />
                      }
                    />
                  </Col>
                  <Col span={16}>
                    {" "}
                    <Space.Compact direction="vertical">
                      <Badge
                        dot={
                          moment(item.createdAt).format("DD-MM-YYYY") ===
                          moment().format("DD-MM-YYYY")
                        }
                      >
                        <Typography.Text strong>
                          {item?.notificationTitle}
                        </Typography.Text>
                      </Badge>
                      <Typography.Text>{item.notificationBody}</Typography.Text>{" "}
                    </Space.Compact>
                  </Col>
                  {/* <Col span={1}></Col> */}
                  <Col span={5} style={{ paddingLeft: 5 }}>
                    {" "}
                    <Tag>
                      <ReactTimeAgo
                        date={item.createdAt}
                        locale="en-IN"
                        // timeStyle="twitter"
                      />
                    </Tag>
                  </Col>

                  {/* <Divider type="vertical" />

              <Divider type="vertical" /> */}
                </Row>
              </Card>

              {/* <Divider /> */}
            </>
          );
        })}
      {/* {workId && (
        <Modal
          open={open}
          width={"100%"}
          onCancel={() => setOpen(!open)}
          onOk={() => setOpen(!open)}
          //   onClose={() => setOpen(!open)}
        >
          <ShowWorkDetailsForm id={workId} />{" "}
        </Modal>
      )} */}
      {leadId && (
        <Modal
          open={open}
          width={"100%"}
          onCancel={() => setOpen(!open)}
          onOk={() => setOpen(!open)}
          footer={null}

          // onClose={() => setOpen(!open)}
        >
          <CreateMobileFollowupForm leadId={leadId} />{" "}
        </Modal>
      )}
      {taskId && (
        <Modal
          open={open}
          width={"100%"}
          onCancel={() => setOpen(!open)}
          onOk={() => setOpen(!open)}
          //   onClose={() => setOpen(!open)}
        >
          <ShowMobileTaskDetailsForm id={taskId} />
        </Modal>
      )}
    </>
  );
};
export default NotificationList;
