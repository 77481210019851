import * as React from "react";
import {
  Form,
  Button,
  Select,
  Col,
  Row,
  message,
  Typography,
  Card,
  Tag,
} from "antd";

import { InfoCircleOutlined } from "@ant-design/icons";

import { ManageAssignedPersonModalContext } from "../Modal/assignedPerson";
import LocalStorageService from "../../../utils/local-storage";
import { useMutation, useQuery } from "react-query";
import { request } from "../../../utils/services";
import { queryClient } from "../../..";

// import {
//   getAllPaymentTerms,
//   getPaymentTermsById,
// } from "../../PaymentTerms/Requests";

import { getLeadById } from "../Requests";
import { log } from "console";
import { getAllPersonnel, getPersonnelById } from "../../HR/Personnel/Requests";
// import { getAllActiveUnit, getAllUnit } from "src/modules/Unit/Requests";
// import { getAllPersonnel } from "src/modules/HR/Personnel/Requests";
const { Text, Title } = Typography;
const CreateAssignPersonForm: React.FC<{ id?: string }> = (props) => {
  const modalContext = React.useContext(ManageAssignedPersonModalContext);
  const [formLoading, setFormLoading] = React.useState(false);
  // const [isAssigned, setIsAssigned] = React.useState(true);
  // const [isPaymentTermsSelected, setIsPaymentTermsSelected] =
  //   React.useState(false);
  // const [paymentTermsData, setPaymentTermsData] = React.useState<Object | any>(
  //   {}
  // );
  const [isPersonSelected, setIsPersonSelected] = React.useState(false);
  const [personData, setPersonData] = React.useState<Object | any>({});
  const assignPersonMutation = useMutation(
    (values: any) => request("admin.lead.update", { id: props.id }, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("lead");
      },
    }
  );
  // const unAssignPersonMutation = useMutation(
  //   (values: any) =>
  //     request("admin.person.unassign", { id: values.personId }, values),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries("occupant");
  //     },
  //   }
  // );
  const {
    isLoading,
    data: lead,
    error,
    isError,
  } = useQuery(
    ["lead", props.id],
    async () => getLeadById(props.id ? props.id : "0"),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [isAssigned, setIsAssigned] = React.useState(
    lead.assignedPersonId ? true : false
  );

  const { data: person } = useQuery(
    ["peronnnel"],
    async () => getAllPersonnel(),
    {
      refetchOnWindowFocus: false,
    }
  );
  // const { data: paymentTerms } = useQuery(
  //   ["paymentTerms"],
  //   async () => getAllPaymentTerms(),
  //   {
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const [form] = Form.useForm();
  const onFinish = async (values: any) => {
    values.updatedBy = LocalStorageService.getAccount().id;
    values.leadId = props.id;
    values.leadStatus = "Assigned";

    // values.availability = personData.availability - 1;

    // values.orderStatus = "AssignedToUnit"; // Timeline
    // values.content = "Order Assigned by"; // Timeline
    // values.description = "Assigned to"; // Timeline
    //values.partyId = party[values.partyId].id;
    setFormLoading(true);
    console.log(values);

    assignPersonMutation
      .mutateAsync(values)
      .then((payload: any) => {
        setFormLoading(false);
        message.success(payload.message);
        form.resetFields();
        modalContext.setIsModalVisible(false);
        setIsAssigned(true);
      })
      .catch((e: any) => {
        setFormLoading(false);
        message.error(e.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  // const UnassignedPerson = async (values: any) => {
  //   values.updatedBy = LocalStorageService.getAccount().id;
  //   values.occupantId = props.id;
  //   //values.personId = occupant.personId;
  //   values.availability = personData.availability + 1;
  //   //console.log(values, "v");
  //   unAssignPersonMutation
  //     .mutateAsync(values)
  //     .then((payload: any) => {
  //       form.setFieldsValue({
  //         personId: null,
  //         paymentTermsId: null,
  //       });
  //       setIsAssigned(false);
  //       setIsPersonSelected(false);
  //       setIsPaymentTermsSelected(false);

  //       message.success("Person Un-Assigned");
  //     })
  //     .catch((e: any) => {
  //       //setFormLoading(false);
  //       console.log(e);

  //       message.error(e);
  //     });
  // };
  const mutatePerson = useMutation((id: string) => getPersonnelById(id));
  const OnPersonSelect = (i: any) => {
    setIsPersonSelected(true);

    mutatePerson
      .mutateAsync(i)
      .then((payload) => {
        setPersonData(payload);
      })
      .catch((e) => {
        console.log(e, "error");
      });
  };
  // const mutatePayment = useMutation((id: string) => getPaymentTermsById(id));
  // const OnPaymentTermsSelect = (i: any) => {
  //   setIsPaymentTermsSelected(true);
  //   mutatePayment
  //     .mutateAsync(i)
  //     .then((payload) => {
  //       setPaymentTermsData(payload);
  //     })
  //     .catch((e) => {
  //       console.log(e, "error");
  //     });
  // };
  // console.log(occupant);
  React.useEffect(() => {
    if (lead.assignedPersonId) {
      OnPersonSelect(lead.assignedPersonId);
      // OnPaymentTermsSelect(occupant.paymentTermsId);
    }
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={lead}
    >
      <Form.Item
        label="Assigned Person"
        style={{ fontWeight: "bold" }}
        name="assignedPersonId"
        tooltip={{
          title: "Person ",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isPersonSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Title level={3}>{personData?.partyId?.personName}</Title>
              <Col>
                <Row>
                  <Text code>{personData.partyId?.phoneNo}</Text>
                  <Text code>{personData.partyId?.address}</Text>
                </Row>
              </Col>
              {!isAssigned && (
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      personId: null,
                    });
                    setIsPersonSelected(false);
                  }}
                  type="dashed"
                >
                  Change Person
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnPersonSelect}
            showSearch
            size="large"
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Person"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
          >
            {person &&
              person?.map((item: any, i: number) => {
                return (
                  <Select.Option
                    value={item?.id}
                    key={i}
                    // disabled={item.availability === 0 ? true : false}
                  >
                    <Row justify={"space-between"} align={"top"}>
                      <h4>{item?.partyId?.personName}</h4>
                      <Col>
                        <Row>
                          <Text code>{item.partyId?.phoneNo}</Text>
                          <Text code> {item.partyId?.address}</Text>
                        </Row>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item>

      {/* <Form.Item
        label="Payment Terms"
        style={{ fontWeight: "bold" }}
        name="paymentTermsId"
        tooltip={{
          title: "Payment Terms",
          icon: <InfoCircleOutlined />,
        }}
      >
        {isPaymentTermsSelected ? (
          <Card>
            <Row justify={"space-between"} align={"middle"}>
              <Col>
                <Text
                  style={{
                    margin: 2,
                    fontWeight: "bold",
                    textDecorationLine: "underline",
                  }}
                >
                  {paymentTermsData.title}
                </Text>
                {paymentTermsData?.description?.map((item: any, i: number) => {
                  return (
                    <Col>
                      <Text style={{ margin: 2, fontWeight: "normal" }}>
                        {i + 1 + "." + item}
                      </Text>
                    </Col>
                  );
                })}
              </Col>
              <Col>
                <Row>
                  <Text code>Price: ₹{paymentTermsData?.price}</Text>
                </Row>
                <Row>
                  <Text type="success" code>
                    Frequency:{paymentTermsData.frequency}
                  </Text>
                </Row>
                <Row>
                  <Text type="danger" code>
                    Food:{paymentTermsData.food}
                  </Text>
                </Row>
              </Col>

              {!isAssigned && (
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      paymentTermsId: null,
                    });
                    setIsPaymentTermsSelected(false);
                  }}
                  type="dashed"
                >
                  Change Payment Terms
                </Button>
              )}
            </Row>
          </Card>
        ) : (
          <Select
            onSelect={OnPaymentTermsSelect}
            showSearch
            popupMatchSelectWidth
            // style={{ minHeight: 150 }}
            placeholder="Select Payment Terms"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string).includes(input)
            }
            dropdownRender={(menu) => (
              <>
                <CreatePaymentTermsModal />

                {menu}
              </>
            )}
          >
            {paymentTerms &&
              paymentTerms?.map((item: any, i: number) => {
                return (
                  <Select.Option value={item?.id} key={i}>
                    <Row justify={"space-between"} align={"middle"}>
                      <Col>
                        <h4>{item.title}</h4>
                        {item?.description?.map((item: any, i: number) => {
                          return (
                            <Col>
                              <Text style={{ margin: 2 }}>
                                {i + 1 + "." + item}
                              </Text>
                            </Col>
                          );
                        })}
                      </Col>

                      <Col>
                        <Row>
                          <Text code>Price: ₹{paymentTermsData?.price}</Text>
                        </Row>
                        <Row>
                          <Text type="success" code>
                            Frequency:{paymentTermsData.frequency}
                          </Text>
                        </Row>
                        <Row>
                          <Text type="danger" code>
                            Food:{paymentTermsData.food}
                          </Text>
                        </Row>
                      </Col>
                    </Row>
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </Form.Item> */}
      {/* {isAssigned ? (
        <Button onClick={() => UnassignedPerson({ personId: occupant.personId })}>
          Unassigned Person
        </Button>
      ) : ( */}
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          size="large"
          loading={formLoading}
          type="primary"
          htmlType="submit"
          shape="round"
        >
          Assign Person
        </Button>
      </Form.Item>
      {/* )} */}
    </Form>
  );
};
export default CreateAssignPersonForm;
